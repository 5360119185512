<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="526px"
        height="512px"
        viewBox="0 0 526 512"
        enable-background="new 0 0 526 512"
        xml:space="preserve"
        opacity="0"
    >
    <animate
        id="start"
        attributeName="opacity"
        values="0;1.0"
        fill="freeze"
        dur="0.7s"
        repeatCount="1"
    />
        <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" style="stop-color: #4fe9ed"></stop>
                <stop offset="1%" style="stop-color: #78a2ff">
                    <animate
                        attributeName="offset"
                        values="-1;0.0;2.0"
                        dur="1.5s"
                        repeatCount="indefinite"
                    />
                    <animate
                        attributeName="stop-color"
                        values="#4fe9ed;#78a2ff;#4fe9ed;"
                        dur="1.5s"
                        repeatCount="indefinite"
                    />
                </stop>
                <stop offset="200%" style="stop-color: #4fe9ed"></stop>
            </linearGradient>
        </defs>
        <g opacity="0.1">
            <path
                d="M68.432,435.475l23.122,13.438l310.661-179.334l-23.122-13.435L68.432,435.475z"
            />
        </g>
        <path
            fill="#DBDBDB"
            d="M88.045,240.836l-15.646-9.092L374.803,57.169l15.651,9.092L88.045,240.836z"
        />
        <path
            fill="#D8E0F5"
            d="M390.455,66.261l0.559,198.397L88.609,439.233l-0.564-198.397L390.455,66.261z"
        />
        <path
            fill="#ABBFF4"
            d="M72.399,231.744l0.564,198.392l15.646,9.102L88.045,240.84L72.399,231.744z"
        />
        <path
            fill="#0732A0"
            d="M370.133,36.352c-0.023-0.017-0.055-0.033-0.089-0.05c-0.033-0.017-0.072-0.033-0.104-0.05
	c-0.188-0.105-0.392-0.199-0.591-0.288c-0.034-0.017-0.067-0.039-0.106-0.055c-0.033-0.011-0.067-0.022-0.094-0.033
	c-0.233-0.094-0.476-0.183-0.719-0.254c-0.011,0-0.021-0.006-0.023-0.011c0,0,0,0-0.005,0c-0.265-0.078-0.526-0.133-0.802-0.183
	c-0.029-0.005-0.061-0.017-0.094-0.021c-0.039-0.005-0.083-0.011-0.128-0.017c-0.229-0.039-0.476-0.066-0.719-0.083
	c-0.039,0-0.078-0.011-0.116-0.011c-0.011,0-0.028,0-0.045,0c-0.298-0.017-0.608-0.017-0.918-0.005c-0.028,0-0.063,0-0.089,0
	c-0.039,0-0.083,0.011-0.122,0.011c-0.348,0.022-0.702,0.05-1.063,0.105c-0.432,0.066-0.864,0.16-1.313,0.282
	c-0.028,0.005-0.05,0.011-0.078,0.017c-0.016,0-0.028,0.011-0.044,0.011c-0.282,0.078-0.57,0.166-0.858,0.266
	c-0.055,0.017-0.111,0.044-0.172,0.061c-0.229,0.083-0.47,0.171-0.708,0.271c-0.032,0.011-0.061,0.022-0.094,0.033
	c-0.055,0.022-0.116,0.056-0.172,0.078c-0.249,0.105-0.503,0.221-0.758,0.349c-0.099,0.044-0.193,0.094-0.288,0.138
	c-0.332,0.166-0.669,0.349-1.007,0.542L88.228,193.667c-0.515,0.293-1.019,0.62-1.517,0.963c-0.161,0.11-0.321,0.232-0.481,0.348
	c-0.338,0.244-0.669,0.493-1.002,0.753c-0.183,0.144-0.365,0.299-0.542,0.448c-0.305,0.255-0.604,0.515-0.902,0.786
	c-0.183,0.166-0.36,0.332-0.537,0.498c-0.111,0.105-0.227,0.205-0.338,0.315c-0.293,0.288-0.575,0.581-0.858,0.88
	c0,0.006-0.011,0.011-0.017,0.017c-0.293,0.31-0.581,0.631-0.863,0.952c-0.094,0.105-0.177,0.221-0.266,0.326
	c-0.21,0.249-0.415,0.498-0.62,0.753c-0.133,0.166-0.277,0.326-0.404,0.492c-0.105,0.139-0.205,0.282-0.31,0.426
	c-0.149,0.2-0.293,0.399-0.443,0.598c-0.1,0.138-0.205,0.271-0.304,0.415c-0.166,0.238-0.321,0.481-0.481,0.725
	c-0.105,0.155-0.21,0.304-0.31,0.459c-0.044,0.067-0.088,0.127-0.127,0.194c-0.282,0.443-0.559,0.891-0.825,1.345
	c-0.017,0.027-0.033,0.06-0.05,0.094c-0.055,0.088-0.1,0.182-0.155,0.276c-0.183,0.321-0.371,0.648-0.542,0.974
	c-0.083,0.15-0.155,0.305-0.232,0.459c-0.083,0.161-0.16,0.316-0.243,0.476c-0.066,0.139-0.144,0.277-0.21,0.415
	c-0.177,0.371-0.349,0.747-0.515,1.124c-0.028,0.066-0.061,0.133-0.094,0.204c-0.005,0.017-0.017,0.039-0.028,0.056
	c-0.205,0.476-0.398,0.952-0.581,1.427c-0.033,0.089-0.061,0.178-0.094,0.266c-0.066,0.177-0.127,0.349-0.188,0.526
	c-0.083,0.238-0.177,0.476-0.254,0.714c-0.061,0.188-0.111,0.376-0.172,0.559c-0.066,0.221-0.138,0.437-0.199,0.658
	c-0.039,0.133-0.089,0.271-0.122,0.404c-0.066,0.243-0.116,0.487-0.177,0.73c-0.066,0.272-0.138,0.543-0.199,0.814
	c-0.022,0.094-0.05,0.188-0.072,0.282c-0.094,0.448-0.177,0.897-0.249,1.339c-0.017,0.094-0.028,0.183-0.039,0.277
	c-0.028,0.199-0.066,0.398-0.089,0.592c-0.033,0.266-0.05,0.531-0.077,0.791c-0.017,0.188-0.039,0.377-0.056,0.565
	c-0.044,0.592-0.072,1.178-0.072,1.759l0.028,10.608l15.645,9.092l-0.027-10.608c0-0.581,0.027-1.167,0.072-1.76
	c0.017-0.188,0.039-0.376,0.056-0.564c0.044-0.459,0.094-0.919,0.166-1.389c0.017-0.094,0.027-0.183,0.039-0.277
	c0.088-0.536,0.193-1.079,0.315-1.621c0.061-0.271,0.133-0.542,0.199-0.813c0.094-0.377,0.194-0.758,0.304-1.135
	c0.066-0.221,0.133-0.437,0.199-0.658c0.133-0.426,0.277-0.852,0.426-1.273c0.061-0.172,0.122-0.349,0.188-0.52
	c0.222-0.587,0.454-1.168,0.703-1.749c0.028-0.066,0.061-0.133,0.088-0.199c0.227-0.52,0.476-1.035,0.725-1.544
	c0.078-0.16,0.161-0.315,0.244-0.476c0.249-0.481,0.503-0.957,0.774-1.433c0.05-0.094,0.1-0.183,0.155-0.277
	c0.321-0.553,0.653-1.095,1.001-1.627c0.1-0.155,0.205-0.304,0.305-0.459c0.254-0.382,0.52-0.763,0.792-1.134
	c0.144-0.199,0.293-0.404,0.442-0.598c0.232-0.31,0.476-0.614,0.714-0.913c0.205-0.254,0.41-0.509,0.62-0.753
	c0.371-0.437,0.742-0.868,1.129-1.278c0.005-0.005,0.017-0.016,0.022-0.022c0.388-0.415,0.792-0.808,1.19-1.188
	c0.177-0.171,0.36-0.337,0.537-0.503c0.293-0.271,0.597-0.531,0.896-0.786c0.183-0.149,0.36-0.304,0.543-0.448
	c0.326-0.26,0.664-0.509,1.001-0.753c0.161-0.116,0.321-0.238,0.482-0.348c0.498-0.343,1.007-0.67,1.516-0.963L374.444,46.566
	c0.338-0.193,0.675-0.371,1.007-0.542c0.094-0.05,0.189-0.094,0.288-0.139c0.255-0.122,0.509-0.238,0.758-0.349
	c0.089-0.039,0.177-0.077,0.266-0.116c0.238-0.094,0.475-0.183,0.703-0.266c0.063-0.022,0.116-0.044,0.177-0.066
	c0.288-0.1,0.575-0.188,0.858-0.266c0.038-0.011,0.083-0.022,0.121-0.027c0.449-0.116,0.886-0.216,1.318-0.282c0,0,0,0,0.005,0
	c0.355-0.056,0.714-0.089,1.063-0.105c0.072,0,0.139-0.011,0.21-0.011c0.313-0.011,0.62-0.011,0.919,0.005
	c0.06,0,0.105,0.011,0.16,0.017c0.244,0.017,0.482,0.044,0.72,0.083c0.072,0.011,0.149,0.022,0.226,0.039
	c0.271,0.05,0.537,0.111,0.797,0.183c0.011,0,0.021,0.011,0.039,0.011c0.243,0.072,0.479,0.161,0.714,0.254
	c0.063,0.028,0.138,0.056,0.204,0.089c0.2,0.088,0.393,0.183,0.587,0.288c0.061,0.033,0.127,0.066,0.194,0.1l-15.646-9.092
	L370.133,36.352z"
        />
        <path
            fill="#2255DA"
            d="M374.461,46.556c8.788-5.075,15.933-1.002,15.961,9.097l0.027,10.608L88.045,240.836l-0.028-10.608
	c-0.027-10.099,7.073-22.395,15.861-27.464L374.461,46.561V46.556z"
        />
        <g id="green">
            <path
                fill="#00FB26"
                d="M100.985,214.49c2.701-1.56,4.898-0.31,4.909,2.783c0.011,3.105-2.175,6.884-4.876,8.445
		c-2.701,1.56-4.887,0.304-4.898-2.8C96.109,219.824,98.284,216.051,100.985,214.49z"
            />
        </g>
        <g id="yellow">
            <path
                fill="#F8D723"
                d="M114.638,206.605c2.701-1.561,4.898-0.31,4.909,2.783c0.011,3.104-2.175,6.884-4.876,8.444
		c-2.695,1.555-4.886,0.305-4.898-2.8C109.762,211.939,111.948,208.16,114.638,206.605z"
            />
        </g>
        <g id="red">
            <path
                fill="#F15944"
                d="M128.29,198.725c2.701-1.561,4.898-0.31,4.909,2.783c0.011,3.104-2.175,6.884-4.875,8.444
		c-2.696,1.555-4.887,0.305-4.898-2.8C123.415,204.059,125.601,200.28,128.29,198.725z"
            />
        </g>
        <path
            fill="#7B96DB"
            d="M208.222,186.324l0.027,10.038L109.8,253.198l-0.028-10.038L208.222,186.324z"
        />
        <path
            fill="#FFFFFF"
            d="M109.772,243.16l0.028,10.038l98.449-56.836l0.233,80.293l-98.455,56.836L109.772,243.16z"
        />
        <path
            fill="#A6CCFD"
            d="M116.225,242.518c1.046-0.603,1.898-0.122,1.904,1.079c0,1.206-0.841,2.673-1.893,3.276
	c-1.051,0.603-1.898,0.116-1.898-1.085S115.179,243.121,116.225,242.518z"
        />
        <path
            fill="#A6CCFD"
            d="M121.521,239.458c1.046-0.603,1.898-0.122,1.904,1.079c0,1.206-0.841,2.673-1.893,3.276
	c-1.046,0.603-1.898,0.116-1.898-1.085C119.634,241.527,120.475,240.061,121.521,239.458z"
        />
        <path
            fill="#A6CCFD"
            d="M126.818,236.403c1.046-0.603,1.898-0.122,1.904,1.079c0,1.207-0.842,2.673-1.893,3.276
	c-1.046,0.603-1.898,0.116-1.898-1.084C124.931,238.473,125.772,237.006,126.818,236.403z"
        />
        <g>
            <path
                fill="#CBDFE5"
                d="M182.139,235.634l0.005,2.756l-44.916,25.93l-0.011-2.756L182.139,235.634z"
            />
            <path
                fill="#CBDFE5"
                d="M182.155,241.937l0.011,2.756l-36.952,21.333l-0.006-2.762L182.155,241.937z"
            />
            <path
                fill="#CBDFE5"
                d="M182.122,229.331l0.005,2.756l-62.293,35.963l-0.006-2.761L182.122,229.331z"
            />
            <path
                fill="#CBDFE5"
                d="M197.496,220.455c1.423-0.819,2.574-0.16,2.579,1.472l0.028,9.983c0,1.632-1.146,3.619-2.562,4.438
	l-8.689,5.013c-1.423,0.819-2.574,0.161-2.579-1.472l-0.028-9.982c0-1.633,1.14-3.619,2.562-4.438L197.496,220.455z"
            />
        </g>
        />
        <g>
            <g>
                <path
                    fill="url(#gradient)"
                    d="M182.139,265.51l0.005,2.756l-44.916,25.938l-0.011-2.763L182.139,265.51z"
                />
                <path
                    fill="url(#gradient)"
                    d="M182.155,271.813l0.011,2.756L145.214,295.9l-0.006-2.762L182.155,271.813z"
                />
                <path
                    id="line"
                    fill="url(#gradient)"
                    d="M182.122,259.207l0.005,2.756l-62.293,35.963l-0.006-2.761L182.122,259.207z"
                />
                <path
                    fill="#78DDF4"
                    d="M197.496,250.331c1.423-0.819,2.574-0.16,2.579,1.472l0.028,9.983c0,1.632-1.146,3.618-2.562,4.438
	l-8.689,5.012c-1.423,0.82-2.574,0.162-2.579-1.471l-0.028-9.982c0-1.633,1.14-3.619,2.562-4.438L197.496,250.331z"
                />
            </g>
        </g>
        <path
            fill="#7B96DB"
            d="M213.108,285.652l0.028,10.037l-98.449,56.836l-0.028-10.037L213.108,285.652z"
        />
        <path
            fill="#FFFFFF"
            d="M114.659,342.488l0.028,10.037l98.449-56.836l0.1,56.836l-98.45,56.836L114.659,342.488z"
        />
        <path
            fill="#A6CCFD"
            d="M131.833,335.494c1.045-0.604,1.898-0.122,1.903,1.079c0,1.206-0.841,2.673-1.892,3.276
	c-1.046,0.603-1.899,0.111-1.899-1.086C129.945,337.563,130.787,336.098,131.833,335.494z"
        />
        <path
            fill="#A6CCFD"
            d="M137.134,332.434c1.046-0.603,1.899-0.121,1.904,1.08c0,1.205-0.841,2.672-1.893,3.275
	c-1.046,0.604-1.898,0.115-1.898-1.085C135.247,334.503,136.088,333.037,137.134,332.434z"
        />
        <path
            fill="#A6CCFD"
            d="M142.43,329.374c1.046-0.603,1.898-0.122,1.904,1.079c0,1.206-0.841,2.673-1.893,3.275
	c-1.046,0.604-1.898,0.117-1.898-1.084S141.384,329.977,142.43,329.374z"
        />
        <path
            fill="#CBDFE5"
            d="M182.139,326.789l0.005,2.756l-44.916,25.936l-0.011-2.76L182.139,326.789z"
        />
        <path
            fill="#CBDFE5"
            d="M182.155,333.092l0.011,2.762l-36.952,21.326l-0.006-2.756L182.155,333.092z"
        />
        <path
            fill="#CBDFE5"
            d="M182.122,320.486l0.005,2.756l-62.293,35.963l-0.006-2.755L182.122,320.486z"
        />
        <path
            fill="#7BA1DB"
            d="M197.496,311.611c1.423-0.819,2.574-0.161,2.579,1.472l0.028,9.981c0,1.633-1.146,3.619-2.562,4.438
	l-8.689,5.016c-1.423,0.817-2.574,0.158-2.579-1.474l-0.028-9.981c0-1.633,1.14-3.619,2.562-4.438L197.496,311.611z"
        />
        <path
            fill="#CBDFE5"
            d="M185.575,353.279l0.011,2.756l-44.922,25.936l-0.011-2.761L185.575,353.279z"
        />
        <path
            fill="#CBDFE5"
            d="M185.591,359.582l0.011,2.755l-36.946,21.333l-0.011-2.756L185.591,359.582z"
        />
        <path
            fill="#CBDFE5"
            d="M185.558,346.977l0.006,2.756l-62.294,35.963l-0.005-2.756L185.558,346.977z"
        />
        <path
            fill="#545CC6"
            d="M200.933,338.1c1.422-0.814,2.573-0.16,2.579,1.477l0.027,9.979c0,1.633-1.145,3.619-2.562,4.438
	l-8.689,5.013c-1.422,0.819-2.573,0.161-2.579-1.472l-0.027-9.981c0-1.634,1.14-3.619,2.562-4.438L200.933,338.1z"
        />
        <g opacity="0.2">
            <path
                fill="#7B7FDB"
                d="M282.249,255.671l0.017,6.226l-61.093,35.271l-0.017-6.23L282.249,255.671z"
            />
        </g>
        <path
            fill="#FFFFFF"
            d="M221.156,290.938l0.017,6.23l61.093-35.271l0.066,35.271l-61.098,35.266L221.156,290.938z"
        />
        <path
            fill="#CBDFE5"
            d="M263.033,281.198l0.006,1.71L235.163,299l-0.006-1.71L263.033,281.198z"
        />
        <path
            fill="#CBDFE5"
            d="M263.045,285.109l0.005,1.711l-22.928,13.236l-0.006-1.72L263.045,285.109z"
        />
        <path
            fill="#CBDFE5"
            d="M263.022,277.286l0.006,1.71l-38.657,22.316l-0.006-1.709L263.022,277.286z"
        />
        <path
            opacity="0.5"
            fill="#7B7FDB"
            enable-background="new    "
            d="M272.563,271.78c0.881-0.509,1.604-0.104,1.604,0.913
	l0.021,6.188c0,1.016-0.709,2.247-1.598,2.76l-5.395,3.106c-0.881,0.509-1.6,0.103-1.6-0.914l-0.017-6.188
	c0-1.016,0.708-2.246,1.594-2.754L272.563,271.78z"
        />
        <path
            fill="#CBDFE5"
            d="M265.164,297.633l0.006,1.715l-27.876,16.092l-0.006-1.715L265.164,297.633z"
        />
        <path
            fill="#CBDFE5"
            d="M265.176,301.545l0.006,1.71l-22.929,13.236l-0.006-1.71L265.176,301.545z"
        />
        <path
            fill="#CBDFE5"
            d="M265.152,293.72l0.007,1.716l-38.657,22.313l-0.006-1.71L265.152,293.72z"
        />
        <g opacity="0.5">
            <path
                fill="#545CC6"
                d="M274.693,288.215c0.881-0.51,1.601-0.1,1.601,0.913l0.017,6.192c0,1.012-0.709,2.246-1.595,2.755
		l-5.39,3.11c-0.881,0.508-1.6,0.1-1.6-0.914l-0.021-6.191c0-1.012,0.708-2.246,1.597-2.756L274.693,288.215z"
            />
        </g>
        <path
            fill="#F9F9FD"
            d="M371.411,88.384l0.498,176.849l-87.292,50.396l-0.504-176.849l0.504,176.849l8.357-4.824l-0.504-176.85
	L371.411,88.384z"
        />
        <path
            fill="#BDCDF5"
            d="M292.471,133.954l0.504,176.849l-8.354,4.825l-0.504-176.849L292.471,133.954z"
        />
        <path
            fill="#DFEEF2"
            d="M344.254,116.877c3.802-2.197,6.891-0.432,6.907,3.934l0.063,23.723c0.016,4.366-3.061,9.684-6.858,11.881
	l-20.648,11.919c-3.797,2.191-6.89,0.432-6.897-3.934l-0.066-23.723c-0.011-4.366,3.062-9.688,6.857-11.881L344.254,116.877z"
        />
        <path
            fill="#DFEEF2"
            d="M362.8,155.662l0.013,3.896l-47.303,27.303l-0.011-3.896L362.8,155.662z"
        />
        <path
            fill="#DFEEF2"
            d="M362.845,171.947l0.016,3.896l-47.301,27.303l-0.011-3.896L362.845,171.947z"
        />
        <path
            fill="#DFEEF2"
            d="M362.845,204.502l0.016,3.895L315.56,235.7l-0.011-3.895L362.845,204.502z"
        />
        <path
            fill="#DFEEF2"
            d="M362.845,221.545l0.016,3.896l-47.301,27.303l-0.011-3.896L362.845,221.545z"
        />
        <path
            fill="#DFEEF2"
            d="M362.845,240.387l0.016,3.896l-47.301,27.309l-0.011-3.896L362.845,240.387z"
        />
        <path
            fill="#DFEEF2"
            d="M362.845,259.207l0.016,3.896l-47.301,27.303l-0.011-3.896L362.845,259.207z"
        />
        <path
            fill="#DFEEF2"
            d="M362.822,163.802l0.011,3.901l-47.296,27.303l-0.011-3.896L362.822,163.802z"
        />
        <path
            fill="#DFEEF2"
            d="M307.856,232.463c0.697-0.404,1.273-0.077,1.273,0.731l0.021,5.733c0,0.813-0.57,1.792-1.273,2.202
	l-4.987,2.877c-0.708,0.41-1.273,0.083-1.279-0.73l-0.021-5.733c0-0.813,0.57-1.798,1.273-2.208l4.981-2.877L307.856,232.463z"
        />
        <path
            fill="#DFEEF2"
            d="M307.856,249.064c0.697-0.404,1.273-0.077,1.273,0.731l0.021,5.732c0,0.814-0.57,1.793-1.273,2.204
	l-4.987,2.877c-0.708,0.41-1.273,0.082-1.279-0.729l-0.021-5.733c0-0.813,0.57-1.798,1.273-2.208l4.981-2.877L307.856,249.064z"
        />
        <path
            fill="#DFEEF2"
            d="M307.856,267.906c0.697-0.404,1.273-0.077,1.273,0.729l0.021,5.732c0,0.813-0.57,1.793-1.273,2.203
	l-4.987,2.877c-0.708,0.408-1.273,0.083-1.279-0.73l-0.021-5.73c0-0.813,0.57-1.799,1.273-2.209l4.981-2.877L307.856,267.906z"
        />
        <path
            fill="#DFEEF2"
            d="M307.856,286.063c0.697-0.4,1.273-0.078,1.273,0.729l0.021,5.733c0,0.813-0.57,1.793-1.273,2.203
	l-4.987,2.877c-0.708,0.41-1.273,0.082-1.279-0.73l-0.021-5.732c0-0.813,0.57-1.8,1.273-2.209l4.981-2.877L307.856,286.063z"
        />
        <g id="cloud">
            <animateTransform
                restart="always"
                dur="3s"
                values="0,0;5,0;0,0"
                fill="remove"
                type="translate"
                calcMode="linear"
                attributeType="XML"
                attributeName="transform"
                additive="replace"
                accumulate="none"
                repeatCount="indefinite"
            ></animateTransform>
            <path
                fill="#57A3FF"
                d="M71.614,361.979c3.586,0,6.387,1.631,7.886,4.691c3.73-1.936,7.2-2.865,10.222-2.865
		c6.824,0.004,11.34,4.758,11.279,13.357c-0.094,13.12-10.819,30.214-23.947,38.188l-47.307,28.729
		c-3.647,2.213-7.051,3.27-9.94,3.265c-5.501,0-9.148-3.841-9.098-10.776c0.061-8.47,5.612-18.985,13.254-26.151
		c-0.094-0.581-0.138-1.201-0.133-1.87c0.056-7.267,5.994-16.733,13.266-21.155c2.502-1.521,4.837-2.24,6.818-2.24
		c0.327,0,0.642,0.021,0.946,0.063c3.326-7.85,9.619-15.604,16.813-19.972c3.647-2.217,7.05-3.271,9.939-3.268v0.01h0.002V361.979z"
            />

            <linearGradient
                id="SVGID_1_"
                gradientUnits="userSpaceOnUse"
                x1="41.6118"
                y1="-527.5054"
                x2="2.1511"
                y2="-563.7885"
                gradientTransform="matrix(1 0 0 -1 41.6992 -169.7998)"
            >
                <stop offset="0" style="stop-color: #ffffff" />
                <stop offset="1" style="stop-color: #cbe3ff" />
            </linearGradient>
            <path
                fill="url(#SVGID_1_)"
                d="M15.779,405.622c-0.094-0.581-0.138-1.201-0.133-1.871c0.055-7.265,5.994-16.733,13.266-21.155
		c0.349-0.209,0.692-0.404,1.029-0.586c0.974-0.515,1.92-0.908,2.823-1.185c0.642-0.193,1.267-0.326,1.865-0.404
		c0.382-0.044,0.747-0.071,1.107-0.071c0.122,0,0.244,0,0.36,0.012c0.199,0.015,0.393,0.027,0.586,0.053
		c3.326-7.85,9.619-15.604,16.813-19.975c0.503-0.305,1.007-0.592,1.5-0.853c1.422-0.752,2.8-1.328,4.118-1.726
		c0.935-0.282,1.843-0.479,2.712-0.588c0.553-0.065,1.09-0.103,1.61-0.103c0.177,0,0.354,0,0.526,0.015
		c0.681,0.029,1.328,0.121,1.942,0.272c0.802,0.2,1.55,0.498,2.23,0.896l4.721,2.77c1.14-0.521,2.258-0.946,3.337-1.271
		c1.162-0.353,2.286-0.596,3.365-0.729c0.687-0.083,1.351-0.127,1.993-0.127c0.221,0,0.437,0,0.653,0.02
		c0.847,0.039,1.655,0.151,2.418,0.342c0.996,0.244,1.915,0.621,2.756,1.104l8.185,4.802c-0.841-0.494-1.76-0.863-2.756-1.106
		c-0.764-0.188-1.571-0.306-2.418-0.345c-0.216-0.013-0.432-0.02-0.653-0.02c-0.642,0-1.312,0.039-1.992,0.128
		c-1.079,0.136-2.203,0.376-3.365,0.728c-1.561,0.472-3.188,1.141-4.87,2.016l-7.997-4.688c-0.487,0-0.985,0.029-1.494,0.1
		c-0.869,0.109-1.776,0.305-2.711,0.587c-1.317,0.397-2.695,0.968-4.118,1.728c-0.493,0.26-0.996,0.547-1.5,0.854
		c-7.189,4.363-13.487,12.124-16.813,19.971c-0.194-0.021-0.387-0.039-0.586-0.05c-0.122-0.006-0.238-0.015-0.36-0.015
		c-0.36,0-0.725,0.021-1.107,0.072c-0.598,0.071-1.218,0.205-1.865,0.403c-0.902,0.271-1.848,0.664-2.822,1.185
		c-0.337,0.186-0.681,0.377-1.029,0.586c-7.272,4.416-13.216,13.894-13.266,21.156c0,0.67,0.039,1.288,0.133,1.869
		c-7.643,7.16-13.194,17.688-13.255,26.152c-0.033,4.688,1.622,7.968,4.4,9.595l-8.185-4.798c-2.778-1.627-4.433-4.902-4.4-9.595
		c0.061-8.47,5.612-18.986,13.254-26.152L15.779,405.622z"
            />
        </g>
        <g id="cloud2">
            <animateTransform
                restart="always"
                dur="3s"
                values="0,0;2,0;0,0"
                fill="remove"
                type="translate"
                calcMode="linear"
                attributeType="XML"
                attributeName="transform"
                additive="replace"
                accumulate="none"
                repeatCount="indefinite"
            ></animateTransform>

            <linearGradient
                id="SVGID_2_"
                gradientUnits="userSpaceOnUse"
                x1="257.3555"
                y1="-170.252"
                x2="223.4481"
                y2="-201.4334"
                gradientTransform="matrix(1 0 0 -1 41.6992 -169.7998)"
            >
                <stop offset="0" style="stop-color: #ffffff" />
                <stop offset="1" style="stop-color: #cbe3ff" />
            </linearGradient>
            <path
                fill="url(#SVGID_2_)"
                d="M241.021,41.635c-0.081-0.5-0.119-1.032-0.114-1.607c0.048-6.244,5.151-14.381,11.4-18.181
		c0.3-0.181,0.595-0.347,0.885-0.504c0.837-0.442,1.65-0.78,2.426-1.018c0.551-0.167,1.089-0.281,1.602-0.347
		c0.329-0.043,0.643-0.062,0.952-0.062c0.104,0,0.209,0,0.309,0.01c0.171,0.009,0.338,0.023,0.504,0.043
		c2.858-6.744,8.266-13.411,14.449-17.163c0.438-0.262,0.866-0.509,1.289-0.732c1.223-0.646,2.406-1.141,3.538-1.483
		c0.804-0.243,1.585-0.409,2.331-0.504C281.068,0.028,281.529,0,281.977,0c0.151,0,0.303,0,0.451,0.01
		c0.586,0.028,1.141,0.104,1.67,0.237c0.689,0.171,1.332,0.428,1.916,0.771l4.057,2.378c0.98-0.447,1.94-0.813,2.869-1.094
		c0.998-0.3,1.963-0.509,2.895-0.623c0.59-0.071,1.161-0.109,1.713-0.109c0.19,0,0.375,0,0.563,0.014
		c0.729,0.033,1.423,0.133,2.074,0.295c0.856,0.209,1.646,0.533,2.369,0.951l7.034,4.123c-0.723-0.423-1.512-0.742-2.369-0.951
		c-0.656-0.162-1.35-0.262-2.078-0.295c-0.185-0.009-0.371-0.014-0.561-0.014c-0.552,0-1.127,0.033-1.712,0.109
		c-0.932,0.114-1.896,0.323-2.896,0.623c-1.341,0.404-2.739,0.979-4.185,1.731l-6.873-4.028c-0.418,0-0.848,0.028-1.284,0.085
		c-0.747,0.09-1.526,0.262-2.331,0.504c-1.132,0.343-2.314,0.833-3.537,1.484c-0.424,0.224-0.854,0.471-1.289,0.732
		c-6.179,3.752-11.592,10.419-14.449,17.163c-0.166-0.019-0.332-0.033-0.504-0.042c-0.104-0.005-0.205-0.01-0.31-0.01
		c-0.31,0-0.623,0.019-0.952,0.062c-0.513,0.062-1.046,0.176-1.602,0.347c-0.776,0.233-1.589,0.57-2.426,1.018
		c-0.29,0.157-0.585,0.323-0.885,0.504c-6.249,3.795-11.357,11.937-11.4,18.181c0,0.575,0.033,1.108,0.114,1.607
		c-6.568,6.154-11.338,15.199-11.391,22.475c-0.028,4.032,1.394,6.848,3.782,8.246l-7.035-4.123
		c-2.387-1.398-3.809-4.214-3.781-8.246c0.053-7.276,4.823-16.316,11.391-22.475L241.021,41.635z"
            />
            <path
                fill="#57A3FF"
                d="M289.01,4.128c3.082,0,5.489,1.403,6.778,4.033c3.205-1.665,6.187-2.463,8.784-2.463
		c5.859,0.004,9.745,4.09,9.688,11.479c-0.081,11.275-9.298,25.965-20.575,32.818L253.03,74.681
		c-3.134,1.902-6.059,2.811-8.542,2.806c-4.727,0-7.861-3.3-7.819-9.264c0.053-7.276,4.823-16.316,11.391-22.475
		c-0.081-0.499-0.119-1.032-0.114-1.607c0.048-6.244,5.151-14.38,11.4-18.18c2.15-1.308,4.157-1.926,5.86-1.926
		c0.28,0,0.552,0.019,0.813,0.053c2.854-6.744,8.266-13.411,14.444-17.163c3.138-1.902,6.063-2.811,8.542-2.806L289.01,4.128
		L289.01,4.128z"
            />
        </g>
        <g id="gear">
            <path
                fill="#61799E"
                d="M77.713,124.469l-4.654-2.706l4.543,2.584l4.655,2.706L77.713,124.469z"
            />
            <path
                fill="#5599FF"
                d="M55.36,155.419l-4.66-2.701l-0.017-7.332l4.654,2.706L55.36,155.419z"
            />
            <path fill="#5599FF" d="M81,103.591l-4.66-2.701l6.431-3.713l4.654,2.706L81,103.591z" />
            <path
                fill="#4098FF"
                d="M70.851,117.076c0.027-0.155,0.05-0.304,0.072-0.459c0.011-0.072,0.022-0.144,0.033-0.216
		c0-0.017,0-0.039,0.006-0.055c0.044-0.327,0.072-0.653,0.094-0.98c0-0.072,0.011-0.138,0.011-0.205
		c0.022-0.332,0.033-0.664,0.033-0.99c-0.011-3.143-0.979-5.473-2.596-6.801c-0.232-0.188-0.476-0.36-0.73-0.509l-4.654-2.706
		c2.048,1.189,3.315,3.719,3.326,7.31c0,0.326-0.011,0.658-0.033,0.99c0,0.067-0.006,0.139-0.011,0.205
		c-0.022,0.327-0.055,0.648-0.094,0.98c-0.011,0.088-0.027,0.182-0.039,0.271c-0.022,0.155-0.044,0.304-0.072,0.459
		c-0.017,0.105-0.039,0.205-0.056,0.31c-0.027,0.149-0.055,0.299-0.088,0.448c-0.017,0.083-0.033,0.166-0.055,0.249
		c-0.083,0.382-0.177,0.758-0.277,1.14c-0.017,0.061-0.039,0.127-0.055,0.188c-0.094,0.327-0.194,0.659-0.299,0.985
		c-0.006,0.022-0.017,0.05-0.022,0.072c-0.105,0.316-0.221,0.637-0.343,0.952c-0.027,0.077-0.061,0.16-0.094,0.238
		c-0.105,0.266-0.21,0.531-0.327,0.797c-0.017,0.038-0.033,0.083-0.05,0.122c-0.127,0.293-0.26,0.586-0.398,0.874
		c-0.039,0.077-0.072,0.149-0.111,0.227c-0.11,0.232-0.227,0.459-0.349,0.686c-0.039,0.072-0.072,0.138-0.11,0.21
		c-0.155,0.282-0.31,0.57-0.471,0.847c-0.033,0.055-0.061,0.105-0.094,0.16c-0.144,0.244-0.293,0.482-0.442,0.725
		c-0.044,0.072-0.089,0.139-0.133,0.21c-0.188,0.294-0.382,0.581-0.576,0.864c-0.028,0.044-0.061,0.083-0.088,0.127
		c-0.205,0.293-0.421,0.581-0.637,0.858c-0.028,0.038-0.055,0.077-0.083,0.11c-0.271,0.349-0.553,0.692-0.835,1.019
		c-0.039,0.044-0.078,0.088-0.111,0.132c-0.177,0.2-0.354,0.399-0.537,0.592c-0.011,0.012-0.017,0.023-0.028,0.034
		c-0.194,0.204-0.387,0.404-0.581,0.597c-0.044,0.039-0.083,0.083-0.127,0.122c-0.254,0.249-0.515,0.493-0.78,0.725
		c-0.022,0.021-0.05,0.044-0.072,0.066c-0.271,0.238-0.554,0.465-0.83,0.686c-0.056,0.045-0.105,0.084-0.161,0.128
		c-0.277,0.21-0.554,0.415-0.841,0.608c-0.05,0.034-0.1,0.067-0.149,0.1c-0.299,0.199-0.598,0.387-0.896,0.564
		c-0.244,0.139-0.481,0.266-0.725,0.388c-0.066,0.033-0.138,0.066-0.205,0.099c-0.183,0.089-0.365,0.172-0.542,0.249
		c-0.066,0.028-0.127,0.056-0.194,0.083c-0.171,0.072-0.337,0.133-0.503,0.194c-0.039,0.017-0.083,0.033-0.122,0.044
		c-0.205,0.072-0.409,0.133-0.614,0.188c-0.116,0.033-0.238,0.063-0.354,0.088c-0.055,0.011-0.111,0.028-0.166,0.039
		c-0.171,0.039-0.343,0.072-0.515,0.094c-0.005,0-0.011,0-0.017,0c-0.155,0.022-0.31,0.044-0.459,0.055
		c-0.05,0-0.1,0.006-0.155,0.011c-0.094,0.006-0.188,0.011-0.282,0.017c-0.222,0.005-0.443,0.005-0.659,0
		c-0.039,0-0.072-0.006-0.111-0.011c-0.177-0.011-0.349-0.033-0.515-0.061c-0.055-0.011-0.105-0.017-0.16-0.028
		c-0.194-0.033-0.382-0.077-0.57-0.132c-0.011,0-0.017-0.006-0.028-0.012c-0.177-0.049-0.343-0.116-0.515-0.182
		c-0.05-0.022-0.1-0.039-0.144-0.061c-0.144-0.061-0.282-0.133-0.421-0.205c-0.044-0.022-0.094-0.044-0.138-0.072l4.654,2.706
		c0,0,0.044,0.022,0.061,0.033c0.022,0.012,0.05,0.023,0.072,0.039c0.138,0.072,0.277,0.144,0.42,0.205
		c0.028,0.011,0.05,0.028,0.078,0.039c0.022,0.011,0.044,0.016,0.066,0.021c0.166,0.066,0.338,0.127,0.515,0.182
		c0.006,0,0.017,0,0.022,0.006c0.183,0.055,0.376,0.094,0.57,0.133c0.022,0,0.044,0.011,0.066,0.016
		c0.028,0,0.061,0.006,0.094,0.011c0.166,0.028,0.337,0.05,0.515,0.061c0.027,0,0.055,0.006,0.083,0.011c0.011,0,0.022,0,0.028,0
		c0.216,0.011,0.437,0.011,0.658,0c0.022,0,0.044,0,0.066,0c0.072,0,0.144-0.011,0.216-0.016c0.05,0,0.1-0.006,0.155-0.011
		c0.149-0.017,0.304-0.034,0.459-0.056c0.006,0,0.011,0,0.017,0c0.172-0.027,0.343-0.061,0.515-0.094
		c0.055-0.011,0.11-0.027,0.171-0.038c0.105-0.028,0.21-0.05,0.321-0.078c0.011,0,0.022-0.005,0.033-0.011
		c0.205-0.055,0.41-0.116,0.614-0.188c0.039-0.017,0.083-0.033,0.122-0.044c0.166-0.061,0.337-0.122,0.503-0.194
		c0.022-0.011,0.044-0.017,0.066-0.028c0.039-0.016,0.083-0.039,0.122-0.055c0.177-0.078,0.36-0.161,0.542-0.249
		c0.066-0.033,0.139-0.067,0.205-0.1c0.238-0.121,0.481-0.249,0.719-0.387c0.304-0.177,0.603-0.365,0.896-0.565
		c0.05-0.033,0.1-0.066,0.149-0.099c0.282-0.194,0.564-0.399,0.841-0.609c0.055-0.039,0.105-0.083,0.161-0.127
		c0.282-0.221,0.559-0.448,0.83-0.686c0.022-0.022,0.044-0.045,0.072-0.061c0.266-0.233,0.526-0.476,0.78-0.725
		c0.028-0.028,0.056-0.05,0.078-0.072c0.017-0.017,0.033-0.033,0.05-0.05c0.194-0.193,0.393-0.393,0.581-0.597
		c0.011-0.011,0.017-0.023,0.028-0.034c0.183-0.193,0.36-0.392,0.537-0.592c0.017-0.016,0.033-0.038,0.05-0.055
		c0.022-0.022,0.039-0.05,0.061-0.078c0.288-0.332,0.564-0.669,0.835-1.018c0.006-0.011,0.017-0.022,0.022-0.027
		c0.022-0.028,0.039-0.056,0.061-0.083c0.216-0.283,0.426-0.57,0.637-0.858c0.022-0.028,0.044-0.055,0.061-0.083
		c0.011-0.011,0.017-0.028,0.028-0.039c0.199-0.282,0.387-0.57,0.576-0.863c0.017-0.028,0.039-0.055,0.055-0.083
		c0.028-0.044,0.05-0.083,0.078-0.127c0.149-0.238,0.299-0.476,0.442-0.72c0.022-0.038,0.05-0.077,0.072-0.116
		c0.011-0.017,0.017-0.028,0.022-0.044c0.161-0.282,0.321-0.563,0.471-0.847c0.011-0.022,0.027-0.044,0.039-0.066
		c0.028-0.045,0.05-0.094,0.072-0.144c0.122-0.227,0.232-0.454,0.349-0.686c0.022-0.05,0.05-0.094,0.072-0.144
		c0.011-0.028,0.027-0.055,0.039-0.089c0.138-0.287,0.271-0.581,0.398-0.874c0-0.011,0.011-0.017,0.011-0.028
		c0.011-0.033,0.028-0.066,0.039-0.094c0.116-0.265,0.221-0.531,0.327-0.797c0.017-0.044,0.039-0.083,0.055-0.127
		c0.011-0.033,0.028-0.072,0.039-0.111c0.122-0.315,0.232-0.63,0.343-0.951c0-0.006,0-0.011,0.006-0.017
		c0.005-0.017,0.011-0.039,0.017-0.055c0.11-0.327,0.205-0.653,0.299-0.985c0.011-0.039,0.027-0.083,0.039-0.122
		c0.005-0.022,0.011-0.044,0.017-0.066c0.105-0.382,0.199-0.759,0.277-1.14c0.005-0.034,0.017-0.067,0.022-0.1
		c0.011-0.05,0.017-0.1,0.028-0.149c0.033-0.15,0.061-0.299,0.088-0.449c0.017-0.105,0.039-0.204,0.055-0.309L70.851,117.076z"
            />
            <path
                fill="#5599FF"
                d="M36.754,153.321l-4.654-2.706l4.51-7.808l4.654,2.701L36.754,153.321z"
            />
            <path
                fill="#5599FF"
                d="M71.658,92.872l-4.654-2.706l4.555-7.885l4.654,2.706L71.658,92.872z"
            />
            <path
                fill="#61799E"
                d="M76.213,84.986l-4.654-2.706l5.933,3.381l4.654,2.706L76.213,84.986z"
            />
            <path
                fill="#8AC0FF"
                d="M37.889,138.109l-4.654-2.706c0.576,3.077,1.743,5.573,3.376,7.404l4.654,2.706
		c-1.638-1.831-2.806-4.327-3.381-7.404H37.889z"
            />
            <path
                fill="#8AC0FF"
                d="M33.218,125.78l4.654,2.706c0.066-0.426,0.139-0.857,0.221-1.289c0.028-0.138,0.056-0.277,0.083-0.415
		c0.056-0.293,0.116-0.581,0.183-0.874c0.039-0.183,0.083-0.371,0.127-0.554c0.061-0.265,0.127-0.531,0.194-0.796
		c0.05-0.189,0.094-0.371,0.144-0.559c0.077-0.294,0.16-0.587,0.249-0.88c0.039-0.133,0.077-0.266,0.116-0.393
		c0.127-0.432,0.266-0.858,0.409-1.284c0-0.005,0-0.017,0.006-0.022c0.138-0.415,0.288-0.83,0.437-1.24
		c0.044-0.121,0.088-0.243,0.138-0.365c0.089-0.232,0.177-0.465,0.271-0.697c0.194-0.498,0.398-0.996,0.608-1.494
		c0.056-0.127,0.105-0.26,0.161-0.387l-4.654-2.706c-0.022,0.055-0.05,0.11-0.072,0.166c-0.033,0.072-0.061,0.149-0.089,0.221
		c-0.21,0.498-0.415,0.99-0.608,1.494c-0.033,0.089-0.072,0.172-0.105,0.26c-0.055,0.144-0.11,0.293-0.16,0.437
		c-0.044,0.122-0.094,0.244-0.139,0.365c-0.149,0.415-0.299,0.825-0.437,1.24c0,0.011-0.005,0.017-0.011,0.028
		c-0.144,0.426-0.276,0.852-0.404,1.283c-0.039,0.133-0.078,0.266-0.116,0.393c-0.055,0.189-0.111,0.371-0.161,0.559
		c-0.027,0.105-0.055,0.216-0.083,0.321c-0.05,0.188-0.1,0.371-0.144,0.554c-0.066,0.265-0.133,0.531-0.193,0.796
		c-0.044,0.183-0.089,0.371-0.127,0.554c-0.022,0.094-0.044,0.188-0.066,0.282c-0.044,0.199-0.078,0.398-0.116,0.592
		c-0.028,0.138-0.055,0.277-0.083,0.415c-0.083,0.432-0.155,0.863-0.221,1.289L33.218,125.78L33.218,125.78z"
            />
            <path
                fill="#8AC0FF"
                d="M64.796,93.304c0.216-0.061,0.432-0.116,0.647-0.172c0.144-0.039,0.288-0.072,0.432-0.105
		c0.205-0.044,0.404-0.083,0.604-0.122c0.138-0.028,0.271-0.056,0.404-0.072c0.299-0.05,0.592-0.088,0.886-0.116
		c0.017,0,0.033,0,0.05-0.005c0.31-0.033,0.62-0.05,0.924-0.061c0.077,0,0.155,0,0.232,0c0.221,0,0.448,0,0.664,0
		c0.088,0,0.171,0,0.26,0.011c0.277,0.017,0.554,0.033,0.819,0.066c0.005,0,0.011,0,0.017,0c0.31,0.039,0.614,0.083,0.913,0.144
		l-4.654-2.706c-0.083-0.017-0.171-0.033-0.254-0.05c-0.216-0.039-0.438-0.072-0.659-0.094c-0.006,0-0.011,0-0.017,0
		c-0.21-0.022-0.426-0.044-0.647-0.061c-0.056,0-0.116,0-0.177-0.005c-0.088,0-0.171-0.005-0.26-0.011
		c-0.221-0.005-0.442-0.005-0.664,0c-0.077,0-0.155,0-0.238,0c-0.039,0-0.072,0-0.111,0c-0.266,0.011-0.537,0.033-0.813,0.061
		c-0.017,0-0.033,0-0.05,0.005c-0.254,0.028-0.509,0.056-0.769,0.1c-0.039,0.006-0.078,0.017-0.116,0.022
		c-0.133,0.022-0.271,0.05-0.404,0.072c-0.199,0.039-0.398,0.078-0.603,0.122c-0.144,0.033-0.288,0.066-0.432,0.105
		c-0.122,0.033-0.249,0.055-0.371,0.088c-0.094,0.022-0.183,0.056-0.277,0.083c-0.088,0.022-0.171,0.05-0.26,0.078
		c-0.321,0.1-0.647,0.199-0.974,0.315l4.654,2.706c0.327-0.116,0.653-0.222,0.974-0.315c0.088-0.028,0.171-0.05,0.26-0.078
		L64.796,93.304z"
            />
            <path
                fill="#8AC0FF"
                d="M48.857,104.819c0.133-0.16,0.271-0.321,0.404-0.476c0.166-0.194,0.332-0.393,0.503-0.581
		c0.249-0.282,0.498-0.559,0.753-0.835c0.116-0.128,0.232-0.255,0.349-0.377c0.371-0.393,0.747-0.78,1.124-1.156
		c0.31-0.305,0.625-0.603,0.94-0.902c0.094-0.089,0.188-0.172,0.277-0.255c0.238-0.216,0.476-0.431,0.719-0.642
		c0.094-0.083,0.188-0.166,0.288-0.249c0.327-0.277,0.653-0.553,0.985-0.819l-4.654-2.706c-0.332,0.266-0.658,0.537-0.985,0.819
		c-0.094,0.083-0.194,0.166-0.288,0.249c-0.243,0.21-0.481,0.426-0.719,0.642c-0.094,0.083-0.188,0.166-0.277,0.254
		c-0.315,0.293-0.625,0.587-0.935,0.896c0,0,0,0-0.005,0.006c-0.382,0.376-0.753,0.763-1.124,1.156
		c-0.116,0.122-0.232,0.255-0.349,0.377c-0.254,0.276-0.503,0.553-0.752,0.835c-0.05,0.055-0.1,0.111-0.149,0.166
		c-0.116,0.139-0.232,0.277-0.349,0.415c-0.133,0.161-0.271,0.316-0.404,0.482c-0.177,0.215-0.354,0.437-0.531,0.653
		c-0.127,0.16-0.255,0.315-0.376,0.475c-0.033,0.045-0.066,0.083-0.1,0.122c-0.244,0.316-0.487,0.637-0.725,0.958l4.654,2.705
		c0.271-0.365,0.548-0.724,0.825-1.084c0.127-0.161,0.254-0.316,0.376-0.476c0.177-0.221,0.354-0.437,0.531-0.653L48.857,104.819
		L48.857,104.819z"
            />
            <path
                fill="#5599FF"
                d="M31.446,132.199l-4.654-2.705l6.425-3.714l4.66,2.706L31.446,132.199z"
            />
            <path
                fill="#4098FF"
                d="M31.474,141.811l-4.66-2.706l-0.022-9.612l4.654,2.706L31.474,141.811z"
            />
            <path
                fill="#5599FF"
                d="M55.194,98.527l-4.654-2.706l-0.022-7.426l4.66,2.701L55.194,98.527z"
            />
            <path
                fill="#4098FF"
                d="M55.177,91.096l-4.66-2.701l8.368-4.831l4.654,2.706L55.177,91.096z"
            />
            <path
                fill="#61799E"
                d="M42.537,104.426l-4.654-2.706l4.583,2.612l4.654,2.706L42.537,104.426z"
            />
            <path
                fill="#4098FF"
                d="M36.643,114.636l-4.654-2.706l5.894-10.21l4.654,2.706L36.643,114.636z"
            />
            <path
                fill="#B3DBFF"
                d="M87.454,109.489l-6.415,3.702c-0.553,3.73-1.705,7.565-3.326,11.272l4.538,2.585l-5.894,10.209
		l-4.533-2.584c-2.407,3.27-5.158,6.203-8.13,8.577l0.022,7.332l-8.362,4.831l-0.022-7.332c-2.972,1.057-5.728,1.306-8.146,0.819
		l-4.505,7.797l-5.933-3.381l4.51-7.814c-1.638-1.831-2.806-4.327-3.381-7.404l-6.414,3.702l-0.028-9.612l6.431-3.713
		c0.564-3.724,1.721-7.548,3.343-11.25l-4.577-2.606l5.894-10.209l4.582,2.611c2.396-3.242,5.13-6.153,8.075-8.505l-0.022-7.432
		l8.362-4.831l0.022,7.432c2.95-1.052,5.695-1.301,8.097-0.83l4.555-7.886l5.933,3.381l-4.543,7.869
		c1.638,1.82,2.812,4.299,3.398,7.354l6.431-3.713l0.027,9.612L87.454,109.489z M59.738,133.843
		c6.298-3.636,11.384-12.44,11.362-19.678c-0.022-7.232-5.141-10.148-11.434-6.518c-6.292,3.635-11.384,12.445-11.362,19.677
		c0.022,7.233,5.141,10.149,11.434,6.513"
            />
        </g>
        <path
            fill="#4C70F2"
            d="M87.973,151.755l-3.232-1.876l2.762-4.786l3.232,1.876L87.973,151.755z"
        />
        <path
            fill="#627DF4"
            d="M89.257,143.875l-3.232-1.876c0.327,1.218,0.83,2.258,1.478,3.094l3.232,1.876
	C90.087,146.133,89.584,145.098,89.257,143.875z"
        />
        <path
            fill="#627DF4"
            d="M87.337,129.992c-0.027,0.066-0.05,0.127-0.078,0.193c-0.005,0.017-0.017,0.039-0.022,0.056
	c-0.017,0.049-0.039,0.099-0.055,0.149c-0.022,0.05-0.039,0.105-0.056,0.155s-0.039,0.099-0.055,0.149
	c-0.017,0.05-0.039,0.105-0.055,0.155c-0.017,0.05-0.033,0.1-0.055,0.15c-0.017,0.049-0.039,0.105-0.056,0.155
	c-0.017,0.049-0.033,0.099-0.05,0.149c-0.011,0.033-0.022,0.063-0.033,0.094c-0.005,0.022-0.011,0.039-0.022,0.061
	c-0.017,0.055-0.039,0.116-0.055,0.171c-0.017,0.05-0.033,0.095-0.044,0.144c-0.017,0.061-0.039,0.117-0.056,0.177
	c-0.017,0.05-0.027,0.094-0.044,0.144c-0.017,0.063-0.033,0.116-0.056,0.177c-0.011,0.045-0.027,0.094-0.039,0.139
	c-0.017,0.061-0.033,0.121-0.05,0.182c-0.011,0.039-0.022,0.072-0.033,0.111c0,0.006,0,0.017-0.005,0.022
	c-0.033,0.111-0.061,0.227-0.094,0.338c0,0.005,0,0.016-0.005,0.022c-0.028,0.105-0.056,0.21-0.083,0.315
	c-0.011,0.039-0.017,0.072-0.028,0.111c-0.017,0.077-0.039,0.155-0.055,0.229c-0.011,0.044-0.022,0.083-0.028,0.127
	c-0.017,0.072-0.033,0.144-0.05,0.216c0,0.022-0.011,0.05-0.017,0.072c0,0.016-0.005,0.033-0.011,0.055
	c-0.017,0.078-0.033,0.155-0.05,0.233c-0.005,0.038-0.017,0.071-0.022,0.11c-0.022,0.116-0.044,0.229-0.066,0.343l3.232,1.876
	c0.022-0.116,0.044-0.227,0.066-0.343c0.005-0.039,0.017-0.072,0.022-0.111c0.017-0.077,0.034-0.154,0.05-0.232
	c0.011-0.044,0.017-0.083,0.027-0.127c0.017-0.072,0.033-0.144,0.05-0.216c0.011-0.044,0.017-0.083,0.028-0.127
	c0.017-0.078,0.039-0.155,0.055-0.228c0.011-0.039,0.017-0.072,0.028-0.111c0.028-0.105,0.055-0.21,0.083-0.315
	c0-0.006,0-0.017,0.005-0.022c0.028-0.111,0.061-0.227,0.094-0.338c0.011-0.044,0.028-0.089,0.039-0.133
	c0.017-0.061,0.033-0.122,0.05-0.182c0.011-0.045,0.028-0.094,0.039-0.139c0.017-0.061,0.033-0.116,0.055-0.177
	c0.017-0.05,0.028-0.094,0.044-0.144c0.017-0.061,0.039-0.116,0.055-0.177c0.017-0.05,0.033-0.094,0.044-0.144
	c0.017-0.055,0.039-0.116,0.055-0.171c0.017-0.05,0.033-0.1,0.05-0.15c0.017-0.049,0.033-0.099,0.05-0.149
	c0.017-0.05,0.033-0.105,0.055-0.155c0.017-0.05,0.033-0.1,0.056-0.149c0.017-0.05,0.039-0.105,0.055-0.155
	c0.017-0.05,0.039-0.1,0.055-0.15c0.017-0.049,0.039-0.104,0.056-0.155c0.017-0.049,0.039-0.099,0.055-0.149
	c0.033-0.083,0.066-0.166,0.094-0.249l-3.232-1.876L87.337,129.992z"
        />
        <path
            fill="#4C70F2"
            d="M85.361,138.962l-3.232-1.876l3.791-2.186l3.232,1.876L85.361,138.962z"
        />
        <path
            fill="#3667F2"
            d="M85.383,146.117l-3.232-1.882l-0.022-7.149l3.232,1.876L85.383,146.117z"
        />
        <path
            fill="#3667F2"
            d="M87.913,130.357l-3.232-1.882l4.389-7.597l3.227,1.881L87.913,130.357z"
        />
        <g id="gear2">
            <path
                fill="#9E8282"
                d="M112.617,134.335l-3.226-1.875l2.778,1.582l3.232,1.882L112.617,134.335z"
            />
            <path
                fill="#3667F2"
                d="M114.139,122.35l-3.232-1.876l3.791-2.192l3.232,1.882L114.139,122.35z"
            />
            <path
                fill="#4C70F2"
                d="M98.599,153.642l-3.232-1.876l-0.017-4.593l3.232,1.876L98.599,153.642z"
            />
            <path
                fill="#627DF4"
                d="M108.318,131.17c0.011-0.05,0.016-0.094,0.027-0.144c0-0.033,0.011-0.061,0.017-0.094
		c0.005-0.044,0.016-0.088,0.022-0.133c0-0.033,0.011-0.06,0.017-0.094c0.005-0.044,0.011-0.088,0.016-0.132
		c0-0.017,0-0.034,0.006-0.05c0-0.011,0-0.022,0-0.033c0.011-0.1,0.022-0.2,0.033-0.299c0-0.017,0-0.028,0-0.045
		c0.011-0.088,0.016-0.182,0.022-0.271c0-0.016,0-0.033,0-0.049c0.006-0.1,0.011-0.194,0.017-0.288c0-0.022,0-0.039,0-0.061
		c0-0.1,0-0.199,0-0.293c-0.006-2.159-0.77-3.68-1.998-4.394l-3.232-1.876c1.228,0.714,1.992,2.236,1.998,4.394
		c0,0.099,0,0.199,0,0.293c0,0.022,0,0.039,0,0.061c0,0.094-0.006,0.194-0.017,0.288c0,0.016,0,0.033,0,0.049
		c-0.006,0.089-0.011,0.183-0.022,0.272c0,0.016,0,0.027,0,0.044c-0.011,0.099-0.022,0.199-0.033,0.299
		c0,0.027-0.006,0.055-0.011,0.083c-0.006,0.044-0.011,0.088-0.017,0.132c0,0.034-0.011,0.061-0.017,0.095
		c-0.005,0.044-0.016,0.088-0.022,0.132c0,0.034-0.011,0.061-0.016,0.089c-0.006,0.05-0.017,0.094-0.028,0.144
		c0,0.027-0.011,0.055-0.017,0.083c-0.011,0.055-0.022,0.11-0.033,0.166c0,0.022-0.005,0.038-0.011,0.061
		c0,0.022-0.011,0.044-0.016,0.06c-0.012,0.05-0.023,0.095-0.034,0.144c-0.005,0.028-0.011,0.056-0.016,0.078
		c-0.011,0.044-0.022,0.083-0.028,0.127c-0.006,0.028-0.017,0.055-0.022,0.083c-0.011,0.039-0.022,0.078-0.033,0.122
		c-0.006,0.028-0.017,0.061-0.022,0.088c-0.012,0.034-0.017,0.067-0.028,0.1c-0.028,0.1-0.055,0.205-0.089,0.304
		c0,0.017-0.011,0.034-0.016,0.056c-0.028,0.094-0.061,0.193-0.094,0.287c0,0.012-0.006,0.028-0.011,0.039
		c-0.034,0.094-0.067,0.188-0.1,0.282c-0.006,0.023-0.017,0.045-0.022,0.067c-0.033,0.088-0.061,0.171-0.094,0.26
		c0,0.011-0.011,0.021-0.011,0.033c-0.034,0.089-0.072,0.183-0.105,0.271c-0.012,0.022-0.017,0.044-0.028,0.072
		c-0.033,0.078-0.061,0.15-0.094,0.227c-0.006,0.017-0.017,0.039-0.022,0.055c-0.039,0.089-0.078,0.177-0.117,0.266
		c-0.011,0.022-0.016,0.039-0.027,0.061c-0.033,0.072-0.067,0.144-0.1,0.21c-0.011,0.022-0.022,0.044-0.033,0.072
		c-0.044,0.089-0.083,0.172-0.127,0.26c0,0.011-0.011,0.017-0.017,0.028c-0.039,0.077-0.077,0.155-0.122,0.232
		c-0.011,0.022-0.027,0.05-0.038,0.072c-0.039,0.078-0.083,0.15-0.122,0.227c-0.006,0.011-0.011,0.022-0.022,0.039
		c-0.05,0.083-0.094,0.171-0.144,0.254c-0.011,0.022-0.028,0.045-0.039,0.067c-0.044,0.072-0.083,0.144-0.127,0.216
		c-0.011,0.016-0.022,0.033-0.033,0.055c-0.05,0.083-0.106,0.171-0.161,0.254c-0.011,0.017-0.022,0.039-0.033,0.056
		c-0.05,0.077-0.1,0.155-0.15,0.232c-0.011,0.017-0.022,0.033-0.033,0.05c-0.055,0.089-0.116,0.172-0.177,0.255
		c-0.011,0.016-0.028,0.038-0.039,0.055c-0.066,0.094-0.132,0.188-0.199,0.282c0,0,0,0,0,0.006
		c-0.066,0.088-0.138,0.182-0.205,0.271c-0.011,0.016-0.022,0.033-0.038,0.05c-0.078,0.099-0.161,0.204-0.238,0.304
		c-0.011,0.017-0.023,0.028-0.039,0.044c-0.083,0.1-0.161,0.194-0.244,0.293c-0.016,0.017-0.033,0.039-0.049,0.056
		c-0.039,0.044-0.078,0.088-0.117,0.127c-0.016,0.022-0.038,0.039-0.055,0.061c-0.05,0.055-0.105,0.111-0.155,0.171
		c0,0-0.011,0.012-0.017,0.017c-0.055,0.061-0.116,0.122-0.171,0.183c-0.017,0.016-0.039,0.038-0.055,0.055
		c-0.039,0.044-0.083,0.083-0.122,0.122c-0.028,0.027-0.056,0.055-0.083,0.083c-0.017,0.016-0.039,0.033-0.056,0.055
		c-0.033,0.033-0.072,0.067-0.105,0.1c-0.016,0.011-0.027,0.027-0.044,0.038c-0.05,0.045-0.1,0.095-0.149,0.139
		c-0.006,0.005-0.017,0.016-0.023,0.022c-0.044,0.039-0.083,0.077-0.127,0.111c-0.016,0.016-0.033,0.033-0.055,0.044
		c-0.033,0.028-0.067,0.061-0.1,0.088c-0.022,0.017-0.039,0.034-0.061,0.05c-0.033,0.028-0.066,0.056-0.1,0.083
		c-0.022,0.017-0.039,0.033-0.061,0.05c-0.033,0.028-0.066,0.055-0.1,0.083c-0.017,0.017-0.039,0.028-0.055,0.044
		c-0.039,0.034-0.083,0.067-0.127,0.094c-0.011,0.011-0.022,0.017-0.033,0.028c-0.055,0.039-0.105,0.078-0.16,0.116
		c-0.017,0.011-0.028,0.022-0.044,0.033c-0.039,0.028-0.078,0.056-0.116,0.083c-0.022,0.012-0.039,0.028-0.061,0.039
		c-0.033,0.021-0.066,0.05-0.105,0.072c-0.022,0.017-0.044,0.028-0.061,0.044c-0.033,0.023-0.066,0.045-0.105,0.067
		c-0.022,0.011-0.039,0.027-0.061,0.039c-0.039,0.022-0.072,0.044-0.11,0.071c-0.017,0.012-0.034,0.023-0.056,0.034
		c-0.055,0.033-0.111,0.066-0.166,0.099c-0.072,0.045-0.144,0.083-0.216,0.122c-0.022,0.011-0.044,0.022-0.061,0.033
		c-0.055,0.028-0.11,0.056-0.166,0.083c-0.017,0.011-0.039,0.017-0.055,0.028c-0.072,0.033-0.144,0.066-0.21,0.099
		c-0.011,0-0.022,0.012-0.033,0.017c-0.061,0.028-0.122,0.055-0.177,0.078c-0.022,0.011-0.044,0.016-0.066,0.027
		c-0.039,0.017-0.083,0.033-0.122,0.05c-0.022,0.011-0.044,0.017-0.066,0.028c-0.044,0.016-0.088,0.033-0.138,0.05
		c-0.017,0.005-0.033,0.011-0.055,0.016c-0.061,0.022-0.122,0.044-0.188,0.061c-0.011,0-0.022,0.006-0.033,0.011
		c-0.05,0.017-0.1,0.028-0.149,0.044c-0.027,0.006-0.061,0.017-0.088,0.022c-0.022,0.006-0.044,0.012-0.066,0.017
		c-0.033,0.006-0.066,0.017-0.1,0.022c-0.022,0-0.039,0.011-0.061,0.011c-0.039,0.006-0.072,0.017-0.111,0.022
		c-0.017,0-0.033,0.006-0.05,0.011c-0.05,0.012-0.105,0.017-0.155,0.028c0,0-0.005,0-0.011,0c-0.044,0.006-0.088,0.011-0.133,0.017
		c-0.017,0-0.033,0-0.044,0c-0.033,0-0.066,0.005-0.1,0.011c-0.017,0-0.033,0-0.05,0c-0.033,0-0.061,0-0.094,0.005
		c-0.017,0-0.033,0-0.05,0c-0.017,0-0.033,0-0.05,0c-0.061,0-0.116,0-0.177,0c-0.011,0-0.027,0-0.039,0
		c-0.066,0-0.133,0-0.194-0.005c-0.017,0-0.027,0-0.044,0c-0.05,0-0.1-0.006-0.155-0.011c-0.011,0-0.027,0-0.039,0
		c-0.061-0.006-0.122-0.017-0.183-0.028c-0.011,0-0.028,0-0.039-0.005c-0.044-0.012-0.094-0.017-0.138-0.028
		c-0.017,0-0.033-0.006-0.044-0.011c-0.055-0.011-0.11-0.028-0.171-0.044h-0.011c-0.05-0.017-0.1-0.034-0.149-0.05
		c-0.017,0-0.028-0.011-0.044-0.017c-0.044-0.016-0.089-0.033-0.133-0.05c-0.011,0-0.022-0.005-0.033-0.011
		c-0.05-0.022-0.105-0.044-0.155-0.072c-0.011-0.005-0.027-0.011-0.039-0.016c-0.039-0.022-0.083-0.039-0.122-0.061
		c-0.022-0.011-0.044-0.022-0.061-0.033l3.232,1.876c0,0,0.028,0.016,0.039,0.022c0.005,0,0.017,0.011,0.022,0.011
		c0.039,0.022,0.078,0.044,0.122,0.061c0.011,0.005,0.022,0.011,0.039,0.016c0.05,0.022,0.099,0.05,0.155,0.072c0,0,0,0,0.005,0
		s0.017,0.006,0.022,0.011c0.044,0.017,0.089,0.033,0.133,0.05c0.017,0,0.033,0.011,0.044,0.017c0.05,0.016,0.1,0.033,0.149,0.049
		c0,0,0.006,0,0.011,0c0.055,0.017,0.111,0.034,0.171,0.045c0.017,0,0.033,0.005,0.044,0.011c0.044,0.011,0.094,0.022,0.138,0.027
		c0.011,0,0.017,0,0.028,0.006c0,0,0.005,0,0.011,0c0.061,0.011,0.122,0.017,0.183,0.028c0.011,0,0.027,0,0.039,0
		c0.05,0.005,0.1,0.011,0.155,0.011c0.011,0,0.017,0,0.028,0c0,0,0.011,0,0.017,0c0.066,0,0.127,0.005,0.194,0.005
		c0.011,0,0.027,0,0.039,0c0.055,0,0.116,0,0.177,0c0.011,0,0.017,0,0.028,0c0.005,0,0.017,0,0.022,0c0.017,0,0.033,0,0.05,0
		c0.033,0,0.061,0,0.094-0.005c0.017,0,0.033,0,0.049,0c0.033,0,0.066-0.006,0.1-0.011c0.017,0,0.033,0,0.044,0
		c0.044-0.006,0.089-0.011,0.133-0.017c0,0,0.006,0,0.011,0c0.05-0.006,0.1-0.017,0.155-0.028c0.017,0,0.033-0.005,0.05-0.011
		c0.039-0.005,0.072-0.016,0.111-0.022c0.022,0,0.039-0.011,0.061-0.011c0.033-0.005,0.066-0.017,0.1-0.022
		c0.022,0,0.044-0.011,0.066-0.017c0.022-0.005,0.044-0.011,0.072-0.016c0.005,0,0.011,0,0.017,0c0.05-0.011,0.1-0.028,0.15-0.045
		c0.011,0,0.022-0.005,0.033-0.011c0.061-0.016,0.121-0.038,0.188-0.06c0.017-0.006,0.033-0.012,0.055-0.017
		c0.045-0.017,0.089-0.033,0.139-0.05c0.022-0.011,0.044-0.016,0.066-0.028c0.039-0.016,0.083-0.033,0.122-0.049
		c0.011,0,0.027-0.011,0.039-0.017c0.011,0,0.022-0.011,0.027-0.011c0.061-0.028,0.116-0.05,0.177-0.077
		c0.011,0,0.022-0.012,0.033-0.017c0.072-0.033,0.144-0.066,0.211-0.1c0.016-0.011,0.039-0.016,0.055-0.027
		c0.055-0.028,0.111-0.056,0.166-0.083c0.022-0.011,0.044-0.022,0.061-0.034c0.072-0.038,0.144-0.077,0.216-0.121
		c0.055-0.033,0.111-0.067,0.166-0.1c0.016-0.011,0.033-0.022,0.055-0.033c0.039-0.022,0.072-0.044,0.111-0.072
		c0.022-0.011,0.039-0.028,0.061-0.039c0.033-0.022,0.066-0.044,0.105-0.066c0.022-0.011,0.044-0.028,0.061-0.044
		c0.033-0.023,0.072-0.045,0.105-0.072c0.022-0.011,0.039-0.028,0.061-0.039c0.039-0.028,0.077-0.055,0.116-0.083
		c0.017-0.011,0.028-0.022,0.044-0.033c0.056-0.039,0.106-0.078,0.161-0.117c0.011-0.011,0.022-0.016,0.033-0.027
		c0.044-0.033,0.083-0.063,0.127-0.094c0.017-0.017,0.039-0.028,0.056-0.045c0.033-0.027,0.066-0.055,0.099-0.083
		c0.022-0.016,0.039-0.033,0.061-0.049c0.033-0.028,0.067-0.056,0.1-0.083c0.022-0.017,0.039-0.034,0.061-0.05
		c0.033-0.028,0.066-0.061,0.099-0.089c0.017-0.016,0.033-0.027,0.05-0.044c0.044-0.039,0.083-0.077,0.127-0.116
		c0.006-0.006,0.017-0.017,0.023-0.022c0.049-0.045,0.099-0.094,0.149-0.139c0.017-0.011,0.028-0.027,0.044-0.038
		c0.033-0.034,0.072-0.067,0.105-0.1c0.017-0.017,0.039-0.033,0.056-0.055c0.016-0.017,0.038-0.039,0.055-0.056
		c0.011-0.011,0.017-0.016,0.028-0.027c0.038-0.039,0.083-0.083,0.121-0.122c0.017-0.017,0.034-0.039,0.056-0.055
		c0.061-0.061,0.116-0.122,0.171-0.183c0,0,0.011-0.011,0.017-0.017c0.05-0.055,0.105-0.11,0.155-0.171
		c0.017-0.022,0.039-0.039,0.055-0.061c0.039-0.044,0.078-0.089,0.117-0.127c0.011-0.011,0.016-0.022,0.027-0.033
		c0.006-0.006,0.011-0.017,0.022-0.023c0.083-0.094,0.166-0.193,0.244-0.293c0.011-0.017,0.022-0.028,0.039-0.044
		c0.083-0.1,0.16-0.199,0.238-0.305c0-0.005,0.011-0.011,0.011-0.016c0.005-0.011,0.016-0.022,0.022-0.033
		c0.066-0.089,0.138-0.177,0.205-0.271c0.066-0.1,0.132-0.194,0.199-0.288c0.005-0.011,0.016-0.022,0.022-0.033
		c0-0.006,0.011-0.017,0.016-0.023c0.061-0.083,0.117-0.171,0.178-0.254c0.011-0.017,0.022-0.033,0.033-0.05
		c0.05-0.077,0.099-0.155,0.149-0.232c0.006-0.011,0.017-0.022,0.022-0.034c0-0.005,0.006-0.011,0.011-0.022
		c0.056-0.083,0.106-0.166,0.161-0.254c0.011-0.017,0.022-0.033,0.033-0.056c0.044-0.071,0.089-0.143,0.127-0.215
		c0.006-0.011,0.017-0.028,0.023-0.039c0-0.011,0.011-0.017,0.016-0.028c0.05-0.083,0.1-0.171,0.144-0.254
		c0.006-0.011,0.011-0.022,0.022-0.039c0.039-0.072,0.083-0.149,0.122-0.227c0.005-0.011,0.011-0.022,0.022-0.039
		c0.006-0.011,0.011-0.022,0.017-0.033c0.038-0.077,0.083-0.155,0.121-0.232c0-0.011,0.012-0.017,0.017-0.028
		c0.044-0.088,0.089-0.171,0.127-0.26c0-0.011,0.011-0.017,0.011-0.028c0.006-0.016,0.011-0.027,0.023-0.044
		c0.033-0.072,0.066-0.138,0.099-0.21c0.011-0.022,0.017-0.039,0.028-0.061c0.039-0.089,0.077-0.177,0.116-0.266
		c0,0,0-0.011,0.006-0.016c0.005-0.011,0.011-0.028,0.016-0.039c0.033-0.078,0.067-0.149,0.094-0.227
		c0.011-0.022,0.017-0.044,0.028-0.072c0.039-0.088,0.072-0.182,0.105-0.271c0,0,0,0,0-0.006c0-0.011,0.006-0.016,0.011-0.027
		c0.033-0.083,0.067-0.172,0.094-0.26c0.006-0.022,0.017-0.045,0.022-0.067c0.034-0.094,0.067-0.188,0.1-0.282c0,0,0-0.005,0-0.011
		c0-0.011,0.006-0.017,0.011-0.028c0.033-0.094,0.061-0.193,0.094-0.287c0-0.017,0.011-0.034,0.017-0.056
		c0.033-0.099,0.061-0.204,0.088-0.304c0-0.011,0.006-0.017,0.006-0.028c0.005-0.022,0.011-0.05,0.022-0.072
		c0.005-0.027,0.017-0.061,0.022-0.088c0.011-0.039,0.022-0.078,0.033-0.122c0.006-0.028,0.017-0.055,0.022-0.089
		c0.012-0.044,0.023-0.083,0.028-0.127c0.006-0.027,0.011-0.055,0.017-0.083c0.011-0.05,0.022-0.094,0.033-0.144
		c0-0.022,0.011-0.044,0.017-0.066c0,0,0,0,0-0.006c0-0.016,0.005-0.033,0.011-0.055c0.011-0.055,0.022-0.111,0.033-0.166
		c0-0.028,0.011-0.055,0.016-0.083L108.318,131.17z"
            />
            <path
                fill="#4C70F2"
                d="M108.328,116.501l-3.232-1.882l2.601-4.493l3.226,1.876L108.328,116.501z"
            />
            <path
                fill="#9E8282"
                d="M110.924,112.002l-3.227-1.876l4.411,2.518l3.232,1.875L110.924,112.002z"
            />
            <path
                fill="#627DF4"
                d="M105.268,116.694c0.072-0.016,0.139-0.033,0.21-0.05c0.034-0.005,0.061-0.011,0.089-0.016
		c0.055-0.011,0.116-0.022,0.172-0.033c0.033-0.006,0.066-0.011,0.099-0.017c0.056-0.011,0.111-0.017,0.166-0.028
		c0.033,0,0.067-0.011,0.1-0.016c0.044-0.006,0.088-0.011,0.127-0.017c0.033,0,0.072-0.011,0.105-0.016
		c0.045,0,0.089-0.012,0.128-0.017c0.033,0,0.072-0.006,0.105-0.011c0.044,0,0.088-0.006,0.133-0.011
		c0.033,0,0.066-0.006,0.099-0.006c0.044,0,0.089,0,0.133-0.005c0.033,0,0.061,0,0.094,0c0.033,0,0.067,0,0.1,0
		c0.038,0,0.077,0,0.121,0c0.034,0,0.067,0,0.1,0c0.039,0,0.078,0,0.116,0c0.034,0,0.067,0,0.1,0c0.039,0,0.077,0,0.116,0
		c0.033,0,0.067,0,0.1,0c0.038,0,0.072,0,0.11,0.005c0.05,0,0.1,0.006,0.15,0.011c0.022,0,0.05,0,0.072,0.006
		c0.072,0.005,0.144,0.016,0.221,0.022l-3.232-1.876c-0.072-0.011-0.149-0.017-0.221-0.022c-0.022,0-0.05,0-0.072-0.006
		c-0.05,0-0.1-0.011-0.15-0.011c-0.005,0-0.016,0-0.022,0c-0.027,0-0.055,0-0.088,0c-0.033,0-0.067,0-0.1,0
		c-0.039,0-0.077,0-0.116,0c-0.033,0-0.067,0-0.1,0c-0.038,0-0.077,0-0.116,0c-0.033,0-0.066,0-0.1,0c-0.038,0-0.077,0-0.121,0
		c-0.034,0-0.067,0-0.1,0c0,0-0.011,0-0.017,0c-0.027,0-0.055,0-0.083,0c-0.044,0-0.088,0-0.132,0.006c-0.034,0-0.067,0-0.1,0.005
		c-0.044,0-0.089,0.006-0.133,0.011c-0.033,0-0.066,0.006-0.105,0.012c-0.044,0-0.089,0.011-0.127,0.016
		c-0.033,0-0.072,0.011-0.105,0.017c-0.045,0.005-0.089,0.011-0.128,0.016c-0.011,0-0.022,0-0.038,0
		c-0.023,0-0.039,0.006-0.061,0.011c-0.056,0.011-0.111,0.017-0.166,0.028c-0.034,0.006-0.067,0.011-0.1,0.017
		c-0.055,0.011-0.116,0.022-0.172,0.033c-0.027,0.005-0.06,0.011-0.088,0.016c-0.072,0.017-0.138,0.034-0.21,0.05
		c-0.017,0-0.034,0.006-0.056,0.011c-0.088,0.023-0.177,0.045-0.265,0.067h-0.011c-0.072,0.016-0.139,0.039-0.211,0.061l3.232,1.876
		c0.078-0.023,0.15-0.045,0.222-0.061c0.088-0.022,0.177-0.045,0.265-0.067c0.017,0,0.034-0.005,0.056-0.011H105.268z"
            />
            <path
                fill="#627DF4"
                d="M95.273,118.553c-0.094,0.084-0.188,0.161-0.277,0.244c-0.027,0.028-0.055,0.05-0.083,0.077
		c-0.066,0.061-0.133,0.117-0.193,0.178c-0.034,0.027-0.061,0.055-0.094,0.083c-0.066,0.06-0.133,0.127-0.199,0.188
		c-0.022,0.021-0.05,0.044-0.072,0.072c-0.088,0.088-0.177,0.171-0.271,0.26c0,0-0.005,0-0.011,0.005
		c-0.011,0.006-0.017,0.017-0.022,0.028c-0.1,0.094-0.194,0.194-0.288,0.293c-0.022,0.022-0.044,0.05-0.072,0.072
		c-0.105,0.105-0.205,0.21-0.304,0.321c-0.017,0.017-0.033,0.039-0.05,0.055c-0.088,0.094-0.177,0.194-0.266,0.294
		c-0.022,0.022-0.044,0.044-0.061,0.066c-0.1,0.111-0.199,0.221-0.293,0.332c-0.005,0.011-0.017,0.017-0.022,0.028
		c-0.022,0.022-0.039,0.044-0.061,0.066c-0.05,0.061-0.105,0.122-0.155,0.177c-0.028,0.033-0.061,0.072-0.088,0.105
		c-0.05,0.061-0.1,0.122-0.149,0.177c-0.028,0.034-0.061,0.072-0.089,0.106c-0.055,0.066-0.105,0.127-0.155,0.193
		c-0.022,0.033-0.05,0.061-0.072,0.094c-0.078,0.094-0.149,0.194-0.227,0.288l3.232,1.876c0.078-0.1,0.149-0.194,0.227-0.288
		c0.022-0.033,0.05-0.061,0.072-0.094c0.05-0.066,0.105-0.133,0.161-0.193c0.028-0.034,0.061-0.072,0.088-0.106
		c0.05-0.061,0.1-0.121,0.149-0.177c0.028-0.033,0.061-0.072,0.089-0.105c0.05-0.061,0.105-0.122,0.155-0.177
		c0.028-0.033,0.055-0.061,0.083-0.094c0.1-0.111,0.199-0.221,0.293-0.332c0.022-0.022,0.044-0.044,0.061-0.066
		c0.089-0.1,0.177-0.194,0.266-0.294c0.017-0.016,0.033-0.038,0.05-0.055c0.1-0.111,0.205-0.216,0.305-0.321
		c0.022-0.022,0.044-0.05,0.072-0.072c0.094-0.1,0.194-0.194,0.288-0.293c0.011-0.011,0.022-0.022,0.033-0.033
		c0.088-0.089,0.177-0.177,0.271-0.26c0.022-0.023,0.05-0.045,0.072-0.072c0.066-0.061,0.133-0.128,0.199-0.189
		c0.033-0.027,0.061-0.055,0.094-0.083c0.066-0.06,0.127-0.116,0.194-0.177c0.027-0.027,0.055-0.049,0.083-0.077
		c0.094-0.083,0.183-0.161,0.276-0.244l-3.232-1.876H95.273z"
            />
            <path
                fill="#4C70F2"
                d="M98.505,120.435l-3.232-1.881l-0.011-4.206l3.232,1.876L98.505,120.435z"
            />
            <path
                fill="#3667F2"
                d="M98.494,116.224l-3.232-1.876l6.22-3.591l3.232,1.876L98.494,116.224z"
            />
            <path
                fill="#9E8282"
                d="M92.296,122.759l-3.227-1.881l2.612,1.488l3.232,1.882L92.296,122.759z"
            />
            <path
                fill="#8CA1F9"
                d="M117.953,127.313l-3.874,2.236c-0.321,1.594-0.814,3.204-1.456,4.786l2.784,1.583l-4.389,7.598
		l-2.822-1.611c-1.041,1.306-2.175,2.507-3.382,3.547l0.011,4.593l-6.226,3.592l-0.011-4.593c-1.207,0.348-2.347,0.465-3.393,0.365
		l-2.806,4.859l-4.417-2.518l2.761-4.787c-0.647-0.835-1.151-1.87-1.478-3.093l-3.874,2.235l-0.022-7.149l3.791-2.186
		c0.293-1.627,0.775-3.281,1.417-4.908l-2.657-1.511l4.389-7.597l2.612,1.488c1.096-1.411,2.308-2.7,3.592-3.813l-0.011-4.205
		l6.226-3.591l0.011,4.205c1.29-0.376,2.502-0.481,3.603-0.337l2.596-4.494l4.416,2.518l-2.64,4.571
		c0.648,0.885,1.135,1.975,1.439,3.265l3.791-2.186l0.022,7.149L117.953,127.313z M101.682,141.009
		c3.785-2.186,6.84-7.481,6.829-11.825c-0.011-4.35-3.088-6.104-6.873-3.918c-3.786,2.186-6.841,7.481-6.83,11.825
		C94.819,141.441,97.896,143.195,101.682,141.009z"
            />
        </g>
        <path
            fill="#00F9F9"
            d="M493.869,227.477l-0.006,3.144l-0.973-3.514l0.01-3.138L493.869,227.477z"
        />
        <path
            fill="#00F9F9"
            d="M521.273,228.346l-0.01,3.16l-3.835,2.783l0.006-3.154L521.273,228.346z"
        />
        <path
            fill="#11ECF7"
            d="M484.277,236.265l-0.005,3.182l-5.745-1.295l0.006-3.182L484.277,236.265z"
        />
        <path
            fill="#30E9FC"
            d="M509.27,232.519c-0.444-0.261-0.93-0.487-1.434-0.687c-0.028-0.011-0.055-0.016-0.077-0.027
	c-0.083-0.033-0.166-0.056-0.249-0.083c-0.288-0.105-0.576-0.211-0.88-0.299c-0.161-0.044-0.327-0.078-0.493-0.122
	c-0.094-0.021-0.189-0.05-0.288-0.072c-0.088-0.022-0.166-0.044-0.254-0.061c-0.327-0.066-0.648-0.121-0.98-0.171
	c-0.117-0.017-0.238-0.028-0.354-0.039c-0.084-0.011-0.167-0.017-0.25-0.028c-0.121-0.011-0.243-0.033-0.365-0.038
	c-0.321-0.028-0.648-0.045-0.979-0.05c-0.102,0-0.205,0-0.305,0c-0.093,0-0.182,0-0.27,0c-0.146,0-0.294,0-0.438,0
	c-0.371,0.011-0.736,0.039-1.104,0.072c-0.045,0-0.084,0.011-0.128,0.016c-0.099,0.011-0.192,0.028-0.293,0.039
	c-0.282,0.033-0.57,0.067-0.847,0.116c-0.122,0.023-0.238,0.067-0.36,0.089c-0.343,0.072-0.669,0.155-0.996,0.249
	c-0.171,0.05-0.354,0.077-0.521,0.133c-0.078,0.027-0.146,0.072-0.227,0.099c-0.688,0.238-1.356,0.515-1.959,0.864
	c-0.039,0.022-0.083,0.049-0.122,0.072c-0.188,0.116-0.316,0.243-0.488,0.365c-0.243,0.171-0.497,0.337-0.702,0.52
	c-0.021,0.017-0.054,0.033-0.067,0.05c-0.229,0.216-0.437,0.437-0.614,0.664c-0.039,0.05-0.063,0.105-0.104,0.155
	c-0.039,0.055-0.072,0.11-0.111,0.166c-0.061,0.088-0.133,0.182-0.182,0.271c-0.105,0.188-0.194,0.376-0.271,0.564
	c-0.011,0.028-0.017,0.056-0.021,0.089c-0.011,0.039-0.022,0.072-0.033,0.11c-0.039,0.122-0.078,0.244-0.104,0.36
	c-0.039,0.188-0.063,0.382-0.067,0.57c0,0.028,0,0.061,0,0.089v3.287c0-0.343,0.063-0.681,0.166-1.019
	c0.011-0.038,0.017-0.071,0.033-0.11c0.106-0.316,0.271-0.62,0.476-0.924c0.039-0.056,0.072-0.111,0.111-0.166
	c0.216-0.299,0.479-0.592,0.786-0.869c0.205-0.183,0.459-0.349,0.702-0.52c0.201-0.144,0.377-0.299,0.61-0.437
	c0.603-0.349,1.271-0.62,1.959-0.864c0.244-0.083,0.493-0.155,0.747-0.227c0.327-0.094,0.659-0.177,0.996-0.249
	c0.399-0.083,0.797-0.155,1.207-0.21c0.104-0.011,0.194-0.028,0.293-0.039c0.555-0.06,1.113-0.088,1.672-0.094
	c0.088,0,0.181,0,0.27,0c0.554,0,1.102,0.034,1.646,0.094c0.083,0.011,0.166,0.017,0.25,0.028c0.542,0.066,1.073,0.155,1.597,0.271
	c0.095,0.022,0.188,0.044,0.279,0.072c0.473,0.116,0.933,0.26,1.378,0.421c0.083,0.027,0.166,0.055,0.249,0.083
	c0.531,0.204,1.04,0.442,1.521,0.714c1.959,1.134,2.938,2.617,2.933,4.1v-3.287c0.011-1.483-0.968-2.966-2.927-4.1H509.27
	L509.27,232.519z"
        />
        <path
            fill="#00F9F9"
            d="M524.34,242.047l-0.005,3.21l-6.071-0.548l0.016-3.209L524.34,242.047z"
        />
        <path fill="#30E9FC" d="M526,238.384l-0.006,3.21l-1.66,3.663l0.006-3.21L526,238.384z" />
        <path
            fill="#02D1D1"
            d="M484.266,236.663v3.182c-0.011,1.948,0.918,3.912,2.811,5.617v-3.182
	C485.19,240.576,484.26,238.611,484.266,236.663z"
        />
        <path
            fill="#02D1D1"
            d="M517.035,242.656c-0.105,0.089-0.205,0.177-0.315,0.26c-0.504,0.393-1.052,0.775-1.666,1.129
	c-0.642,0.376-1.318,0.708-2.021,1.007c-0.232,0.1-0.487,0.177-0.729,0.271c-0.537,0.211-1.072,0.421-1.638,0.593v3.209
	c0.122-0.039,0.249-0.077,0.376-0.116c0.432-0.139,0.842-0.31,1.257-0.471c0.243-0.094,0.492-0.171,0.73-0.271
	c0.7-0.304,1.384-0.636,2.021-1.007c0.088-0.05,0.171-0.099,0.254-0.155c0.514-0.31,0.979-0.636,1.406-0.974
	c0.111-0.088,0.21-0.171,0.315-0.26c0.178-0.144,0.361-0.288,0.521-0.431c0.255-0.238,0.493-0.476,0.714-0.725v-3.21
	c-0.349,0.399-0.771,0.78-1.228,1.157v-0.006H517.035z"
        />
        <path
            fill="#02D1D1"
            d="M500.697,247.072c-0.187-0.011-0.371-0.017-0.558-0.028c-0.781-0.055-1.563-0.132-2.336-0.249
	c-0.132-0.022-0.26-0.049-0.393-0.066c-0.647-0.105-1.284-0.232-1.909-0.382c-0.227-0.055-0.448-0.105-0.67-0.166
	c-0.741-0.194-1.472-0.409-2.169-0.669v3.176c0.542,0.205,1.104,0.387,1.682,0.548c0.155,0.044,0.321,0.077,0.482,0.116
	c0.221,0.061,0.448,0.111,0.675,0.166c0.233,0.055,0.465,0.122,0.703,0.171c0.396,0.083,0.802,0.144,1.206,0.211
	c0.133,0.022,0.261,0.05,0.396,0.066c0.059,0.006,0.11,0.022,0.166,0.028c0.574,0.083,1.161,0.149,1.748,0.193
	c0.143,0.012,0.276,0.012,0.415,0.023c0.188,0.011,0.376,0.022,0.564,0.027c0.266,0.011,0.532,0.033,0.797,0.045
	c0.521,0.011,1.046,0.011,1.564,0v-3.177c-0.778,0.022-1.569,0-2.355-0.044L500.697,247.072z"
        />
        <path
            fill="#30E9FC"
            d="M487.88,247.731l-0.006,3.176l-4.615-2.667l0.005-3.177L487.88,247.731z"
        />
        <path
            fill="#00F9F9"
            d="M492.661,245.512l-0.005,3.176l-4.782,2.219l0.006-3.176L492.661,245.512z"
        />
        <path
            fill="#30E9FC"
            d="M511.633,249.463l-0.006,3.176l-6.314,0.974l0.01-3.177L511.633,249.463z"
        />
        <path
            fill="#00F9F9"
            d="M505.319,250.436l-0.007,3.177l-2.256-3.326l0.01-3.171L505.319,250.436z"
        />
        <path
            fill="#1CB3C8"
            d="M515.059,244.078c-1.334,0.775-2.811,1.395-4.389,1.871l0.963,3.514l-6.313,0.973l-2.253-3.325
	c-3.596,0.088-7.25-0.437-10.404-1.599l-4.782,2.219l-4.621-2.668l3.813-2.778c-2.021-1.82-2.944-3.94-2.8-6.02l-5.745-1.295
	l1.66-3.663l6.063,0.548c0.819-0.914,1.882-1.771,3.216-2.546c1.317-0.764,2.813-1.394,4.393-1.87l-0.964-3.514l6.313-0.974
	l2.236,3.331c3.604-0.099,7.25,0.437,10.399,1.599l4.801-2.23l4.619,2.668l-3.825,2.783c2.02,1.821,2.961,3.929,2.8,6.021
	l5.761,1.283l-1.66,3.664l-6.063-0.548c-0.82,0.913-1.899,1.782-3.221,2.545L515.059,244.078L515.059,244.078z M495.269,240.764
	c3.896,2.246,10.188,2.246,14.056,0c3.869-2.247,3.852-5.888-0.044-8.14c-3.896-2.247-10.188-2.247-14.057,0
	C491.356,234.87,491.372,238.512,495.269,240.764z"
        />
        <path
            fill="#00F9F9"
            d="M434.032,237.814l-0.011,4.012l-1.765-6.402l0.016-4.012L434.032,237.814z"
        />
        <path
            fill="#00F9F9"
            d="M484.012,239.43l-0.011,4.012l-6.99,5.08l0.016-4.012L484.012,239.43z"
        />
        <path
            fill="#11ECF7"
            d="M416.549,253.912l-0.011,4.011l-10.482-2.357l0.016-4.012L416.549,253.912z"
        />
        <path
            fill="#30E9FC"
            d="M462.123,246.319c-0.819-0.47-1.693-0.89-2.618-1.256c-0.044-0.016-0.099-0.027-0.143-0.049
	c-0.15-0.056-0.31-0.1-0.454-0.155c-0.526-0.194-1.056-0.382-1.605-0.543c-0.294-0.083-0.598-0.144-0.896-0.221
	c-0.182-0.044-0.353-0.089-0.526-0.127c-0.155-0.034-0.309-0.083-0.464-0.117c-0.593-0.121-1.19-0.226-1.794-0.31
	c-0.215-0.028-0.431-0.045-0.647-0.072c-0.146-0.017-0.299-0.034-0.443-0.05c-0.227-0.022-0.443-0.056-0.664-0.072
	c-0.598-0.05-1.19-0.083-1.782-0.094c-0.189,0-0.371,0-0.559,0c-0.166,0-0.327,0-0.487,0c-0.272,0-0.537-0.006-0.813,0
	c-0.676,0.022-1.345,0.066-2.009,0.133c-0.078,0.005-0.146,0.022-0.227,0.033c-0.188,0.016-0.355,0.05-0.532,0.072
	c-0.521,0.066-1.04,0.121-1.549,0.216c-0.229,0.038-0.437,0.116-0.653,0.16c-0.62,0.127-1.218,0.282-1.821,0.454
	c-0.313,0.088-0.648,0.138-0.957,0.238c-0.144,0.044-0.261,0.127-0.399,0.177c-1.271,0.437-2.485,0.941-3.57,1.571
	c-0.072,0.045-0.146,0.089-0.221,0.133c-0.348,0.205-0.587,0.448-0.897,0.664c-0.438,0.31-0.907,0.614-1.273,0.946
	c-0.034,0.034-0.093,0.061-0.122,0.089c-0.431,0.393-0.797,0.797-1.124,1.206c-0.072,0.094-0.121,0.194-0.193,0.288
	c-0.072,0.1-0.133,0.199-0.2,0.299c-0.105,0.166-0.233,0.332-0.337,0.498c-0.189,0.337-0.354,0.681-0.479,1.029
	c-0.022,0.05-0.027,0.105-0.044,0.155c-0.022,0.067-0.039,0.133-0.06,0.199c-0.063,0.222-0.144,0.437-0.188,0.659
	c-0.067,0.343-0.111,0.692-0.117,1.04c0,0.056,0,0.105,0,0.161l-0.011,4.958c0-0.625,0.112-1.244,0.31-1.859
	c0.022-0.063,0.033-0.131,0.057-0.195c0.198-0.57,0.497-1.134,0.862-1.682c0.066-0.1,0.127-0.199,0.199-0.299
	c0.396-0.548,0.875-1.073,1.438-1.582c0.37-0.332,0.845-0.637,1.283-0.947c0.376-0.265,0.692-0.547,1.118-0.791
	c1.085-0.631,2.31-1.134,3.57-1.572c0.438-0.154,0.897-0.287,1.356-0.415c0.603-0.166,1.201-0.326,1.815-0.453
	c0.725-0.15,1.456-0.288,2.203-0.377c0.177-0.022,0.354-0.055,0.531-0.072c1.007-0.11,2.021-0.16,3.05-0.171c0.16,0,0.317,0,0.479,0
	c1.009,0.005,2.014,0.061,3.006,0.166c0.154,0.017,0.309,0.033,0.46,0.05c0.984,0.116,1.962,0.282,2.904,0.498
	c0.172,0.039,0.343,0.083,0.52,0.127c0.858,0.216,1.694,0.47,2.507,0.764c0.146,0.055,0.304,0.094,0.454,0.155
	c0.974,0.376,1.898,0.808,2.761,1.3c3.575,2.064,5.354,4.77,5.352,7.47l0.016-4.963c0.005-2.701-1.777-5.412-5.354-7.471
	L462.123,246.319z"
        />
        <linearGradient
            id="SVGID_3_"
            gradientUnits="userSpaceOnUse"
            x1="440.4199"
            y1="-426.8936"
            x2="440.4589"
            y2="-426.8936"
            gradientTransform="matrix(1 0 0 -1 41.6992 -169.7998)"
        >
            <stop offset="0" style="stop-color: #efa6ff" />
            <stop offset="0.4" style="stop-color: #cc83f6" />
            <stop offset="1" style="stop-color: #934ae8" />
        </linearGradient>
        <path
            fill="url(#SVGID_3_)"
            d="M482.158,254.686l-0.012,4.012c0,0.266-0.016,0.536-0.033,0.803l0.011-4.012
	C482.146,255.223,482.158,254.952,482.158,254.686z"
        />
        <defs>
            <filter
                id="Adobe_OpacityMaskFilter"
                filterUnits="userSpaceOnUse"
                x="482.113"
                y="254.686"
                width="0.045"
                height="4.815"
            >
                <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
            </filter>
        </defs>
        <mask
            maskUnits="userSpaceOnUse"
            x="482.113"
            y="254.686"
            width="0.045"
            height="4.815"
            id="mask0_95_3388"
        >
            <path
                fill="#FFFFFF"
                filter="url(#Adobe_OpacityMaskFilter)"
                d="M482.158,254.686l-0.012,4.012c0,0.266-0.016,0.536-0.033,0.803
		l0.011-4.012C482.146,255.223,482.158,254.952,482.158,254.686z"
            />
        </mask>
        <g mask="url(#mask0_95_3388)">
            <linearGradient
                id="SVGID_4_"
                gradientUnits="userSpaceOnUse"
                x1="440.4414"
                y1="-426.6353"
                x2="346.6914"
                y2="-426.6353"
                gradientTransform="matrix(1 0 0 -1 41.6992 -169.7998)"
            >
                <stop offset="0" style="stop-color: #efa6ff" />
                <stop offset="0.4" style="stop-color: #cc83f6" />
                <stop offset="1" style="stop-color: #934ae8" />
            </linearGradient>
            <path
                fill="url(#SVGID_4_)"
                d="M482.158,254.686l-0.012,4.012c0,0.094,0,0.194,0,0.288l0.012-4.012
		C482.158,254.88,482.158,254.78,482.158,254.686z"
            />

            <linearGradient
                id="SVGID_5_"
                gradientUnits="userSpaceOnUse"
                x1="284.1641"
                y1="-427.0371"
                x2="596.6641"
                y2="-427.0371"
                gradientTransform="matrix(1 0 0 -1 41.6992 -169.7998)"
            >
                <stop offset="0" style="stop-color: #efa6ff" />
                <stop offset="0.4" style="stop-color: #cc83f6" />
                <stop offset="1" style="stop-color: #934ae8" />
            </linearGradient>
            <path
                fill="url(#SVGID_5_)"
                d="M482.152,254.974l-0.016,4.012c0,0.171-0.017,0.343-0.023,0.515l0.011-4.012
		C482.135,255.317,482.146,255.146,482.152,254.974z"
            />
        </g>
        <path
            fill="#00F9F9"
            d="M489.601,264.508l-0.011,4.012l-11.063-1l0.011-4.013L489.601,264.508z"
        />
        <path
            fill="#30E9FC"
            d="M492.634,257.829l-0.011,4.012l-3.033,6.679l0.011-4.012L492.634,257.829z"
        />
        <path
            fill="#02D1D1"
            d="M416.521,254.642l-0.016,4.012c-0.011,3.552,1.687,7.138,5.13,10.248l0.016-4.012
	C418.203,261.786,416.51,258.2,416.521,254.642z"
        />
        <path
            fill="#02D1D1"
            d="M476.28,265.621c-0.188,0.16-0.371,0.32-0.57,0.477c-0.924,0.719-1.92,1.41-3.038,2.062
	c-1.173,0.681-2.408,1.284-3.688,1.837c-0.431,0.184-0.89,0.322-1.322,0.498c-0.98,0.382-1.954,0.764-2.989,1.074l-0.011,4.017
	c0.229-0.071,0.465-0.146,0.688-0.216c0.786-0.258,1.527-0.564,2.286-0.857c0.438-0.172,0.902-0.313,1.328-0.496
	c1.284-0.556,2.521-1.154,3.691-1.839c0.161-0.095,0.313-0.183,0.471-0.272c0.935-0.565,1.771-1.168,2.568-1.782
	c0.194-0.155,0.381-0.315,0.57-0.476c0.321-0.27,0.664-0.521,0.958-0.791c0.469-0.437,0.896-0.869,1.305-1.317l0.011-4.013
	c-0.653,0.726-1.428,1.429-2.258,2.104V265.621L476.28,265.621z"
        />
        <path
            fill="#02D1D1"
            d="M446.489,273.611c-0.338-0.021-0.675-0.033-1.018-0.055c-1.428-0.104-2.854-0.244-4.256-0.449
	c-0.243-0.032-0.479-0.088-0.719-0.127c-1.179-0.193-2.341-0.431-3.487-0.697c-0.404-0.094-0.814-0.193-1.223-0.299
	c-1.35-0.354-2.684-0.748-3.957-1.217l-0.011,4.012c1.002,0.371,2.026,0.703,3.083,1c0.288,0.084,0.586,0.14,0.874,0.217
	c0.409,0.105,0.814,0.205,1.229,0.299c0.426,0.1,0.852,0.221,1.278,0.316c0.725,0.146,1.461,0.26,2.197,0.381
	c0.239,0.039,0.476,0.089,0.72,0.128c0.099,0.016,0.199,0.038,0.304,0.049c1.062,0.149,2.12,0.267,3.188,0.354
	c0.255,0.021,0.509,0.021,0.764,0.039c0.343,0.021,0.688,0.039,1.029,0.056c0.479,0.022,0.963,0.065,1.448,0.077
	c0.949,0.021,1.906,0.021,2.854,0l0.011-4.012c-1.434,0.028-2.878,0-4.313-0.078L446.489,273.611L446.489,273.611z"
        />
        <path
            fill="#30E9FC"
            d="M423.118,274.813l-0.011,4.016l-8.428-4.859l0.016-4.017L423.118,274.813z"
        />
        <path
            fill="#00F9F9"
            d="M431.834,270.768l-0.011,4.016l-8.717,4.045l0.011-4.016L431.834,270.768z"
        />
        <path
            fill="#30E9FC"
            d="M466.435,277.973l-0.011,4.012l-11.521,1.775l0.011-4.012L466.435,277.973z"
        />
        <path
            fill="#00F9F9"
            d="M454.912,279.748l-0.011,4.012l-4.106-6.059l0.011-4.012L454.912,279.748z"
        />
        <path
            fill="#1CB3C8"
            d="M472.677,268.155c-2.435,1.417-5.124,2.54-8.002,3.409l1.76,6.397l-11.517,1.777l-4.107-6.063
	c-6.547,0.166-13.227-0.798-18.971-2.923l-8.717,4.046l-8.423-4.863l6.957-5.063c-3.688-3.318-5.368-7.184-5.108-10.978
	l-10.477-2.358l3.033-6.679l11.063,0.996c1.494-1.671,3.426-3.232,5.866-4.643c2.397-1.394,5.125-2.539,8.003-3.408l-1.76-6.408
	l11.517-1.777l4.073,6.076c6.58-0.182,13.227,0.797,18.972,2.922l8.749-4.067l8.424,4.864l-6.989,5.08
	c3.688,3.32,5.396,7.166,5.107,10.979l10.509,2.338l-3.031,6.681l-11.063-0.996c-1.495,1.672-3.459,3.249-5.867,4.646
	L472.677,268.155L472.677,268.155z M436.583,262.111c7.106,4.102,18.567,4.102,25.629,0c7.056-4.104,7.017-10.739-0.083-14.84
	c-7.104-4.1-18.568-4.1-25.629,0C429.444,251.372,429.482,258.012,436.583,262.111z"
        />
        <g opacity="0.1">
            <path
                d="M467.924,336.656c0.21,0.121,0.293,0.304,0.194,0.476c-0.146,0.243-0.603,0.371-1.013,0.288l-8.813-1.76l3.946,4.67
		c0.189,0.227,0.034,0.504-0.365,0.62c-0.31,0.089-0.681,0.05-0.913-0.089c-0.061-0.033-0.111-0.077-0.155-0.127l-4.798-5.672
		c-0.144-0.166-0.094-0.371,0.116-0.514c0.216-0.138,0.553-0.189,0.858-0.127l10.631,2.125c0.116,0.021,0.221,0.063,0.304,0.11
		L467.924,336.656z"
            />
            <path
                d="M478.024,342.488c0.083,0.05,0.146,0.104,0.188,0.177l3.708,6.153c0.105,0.178,0.018,0.371-0.221,0.498
		c-0.239,0.127-0.587,0.156-0.88,0.072l-9.813-2.765c-0.083-0.021-0.155-0.06-0.216-0.089c-0.233-0.137-0.305-0.354-0.155-0.531
		c0.193-0.229,0.669-0.322,1.063-0.216l8.08,2.271l-3.071-5.1c-0.15-0.234,0.072-0.503,0.492-0.586
		C477.498,342.316,477.819,342.367,478.024,342.488z"
            />
            <path
                d="M473.492,321.438c0.376,0.217,0.376,0.57,0,0.787c-0.371,0.215-0.98,0.215-1.356,0c-0.377-0.217-0.377-0.57,0-0.787
		C472.507,321.223,473.115,321.223,473.492,321.438z"
            />
            <path
                d="M510.19,339.08c1.206,0.697,1.212,1.82,0.016,2.518l-5.23,3.037l-40.848-23.584l5.158-2.999
		c1.267-0.735,3.215-0.735,4.422-0.044l36.487,21.061L510.19,339.08z M474.267,323.453c0.376,0.216,0.985,0.216,1.361,0
		c0.371-0.217,0.371-0.57-0.005-0.786c-0.377-0.216-0.985-0.216-1.356,0C473.896,322.883,473.896,323.236,474.267,323.453z
		 M470.016,320.996c0.377,0.216,0.985,0.216,1.356,0c0.371-0.217,0.371-0.57,0-0.786c-0.376-0.216-0.985-0.216-1.356,0
		C469.646,320.426,469.646,320.779,470.016,320.996z M472.142,322.225c0.376,0.215,0.985,0.215,1.355,0
		c0.371-0.217,0.371-0.57,0-0.787c-0.376-0.215-0.985-0.215-1.355,0C471.771,321.654,471.771,322.008,472.142,322.225z"
            />
            <path
                d="M471.361,320.216c0.376,0.216,0.376,0.57,0,0.785c-0.371,0.216-0.98,0.216-1.356,0c-0.376-0.215-0.376-0.569,0-0.785
		C470.376,320,470.984,320,471.361,320.216z"
            />
            <path
                d="M475.611,322.667c0.376,0.216,0.382,0.569,0.005,0.786c-0.37,0.216-0.979,0.216-1.361,0c-0.376-0.217-0.376-0.57,0-0.786
		C474.626,322.451,475.234,322.451,475.611,322.667z"
            />
            <path
                d="M478.672,338.797c0.066,0.039,0.116,0.083,0.155,0.133c0.166,0.217,0,0.471-0.376,0.565l-18.147,4.653
		c-0.288,0.072-0.61,0.033-0.825-0.088c-0.061-0.039-0.111-0.078-0.15-0.127c-0.166-0.221,0-0.476,0.376-0.57l18.146-4.654
		C478.135,338.637,478.462,338.676,478.672,338.797z"
            />
            <path
                d="M464.122,321.057l40.849,23.584l-30.938,17.979c-1.234,0.719-3.248,0.719-4.493,0l-36.344-20.978
		c-1.245-0.725-1.251-1.887-0.021-2.604l30.943-17.979L464.122,321.057z M478.45,339.495c0.371-0.095,0.543-0.349,0.377-0.565
		c-0.039-0.05-0.089-0.094-0.155-0.133c-0.211-0.121-0.537-0.16-0.825-0.088l-18.146,4.654c-0.376,0.099-0.542,0.348-0.376,0.57
		c0.039,0.049,0.094,0.094,0.153,0.127c0.212,0.121,0.534,0.16,0.821,0.088L478.45,339.495z M480.819,349.378
		c0.288,0.083,0.636,0.056,0.88-0.071c0.238-0.127,0.326-0.322,0.221-0.498l-3.708-6.154c-0.038-0.063-0.104-0.127-0.188-0.178
		c-0.21-0.121-0.526-0.17-0.824-0.105c-0.415,0.083-0.638,0.348-0.493,0.586l3.072,5.098l-8.08-2.271
		c-0.399-0.11-0.875-0.021-1.063,0.217c-0.146,0.187-0.078,0.396,0.155,0.526c0.061,0.033,0.133,0.066,0.216,0.093L480.819,349.378z
		 M461.87,340.943c0.396-0.109,0.559-0.387,0.365-0.618l-3.946-4.671l8.811,1.76c0.415,0.083,0.868-0.044,1.017-0.288
		c0.1-0.171,0.017-0.358-0.193-0.476c-0.083-0.049-0.183-0.088-0.305-0.11l-10.631-2.125c-0.31-0.062-0.646-0.011-0.858,0.127
		s-0.26,0.343-0.116,0.509l4.798,5.672c0.039,0.051,0.099,0.09,0.155,0.127c0.238,0.144,0.604,0.178,0.913,0.09"
            />
        </g>
        <path
            fill="#FFFFFF"
            d="M473.52,317.886c0.381,0.216,0.381,0.57,0,0.786c-0.366,0.215-0.979,0.215-1.354,0
	c-0.376-0.216-0.376-0.57,0-0.786S473.144,317.67,473.52,317.886z"
        />
        <path
            fill="#5294F7"
            d="M510.217,335.521c1.207,0.693,1.212,1.816,0.021,2.518l-5.23,3.038l-40.849-23.584l5.157-2.999
	c1.269-0.736,3.217-0.736,4.423-0.045l36.483,21.061L510.217,335.521z M474.294,319.895c0.376,0.215,0.985,0.215,1.361,0
	c0.371-0.215,0.371-0.565-0.005-0.785c-0.376-0.217-0.985-0.217-1.356,0C473.923,319.325,473.923,319.68,474.294,319.895z
	 M470.044,317.438c0.376,0.215,0.985,0.215,1.356,0c0.365-0.215,0.365-0.565,0-0.785c-0.377-0.217-0.985-0.217-1.356,0
	C469.673,316.868,469.673,317.223,470.044,317.438z M472.169,318.666c0.376,0.216,0.985,0.216,1.356,0s0.371-0.57,0-0.786
	c-0.377-0.215-0.985-0.215-1.356,0C471.798,318.096,471.798,318.45,472.169,318.666z"
        />
        <path
            fill="#FFFFFF"
            d="M471.394,316.657c0.376,0.216,0.376,0.569,0,0.786c-0.366,0.216-0.979,0.216-1.354,0
	c-0.376-0.217-0.376-0.57,0-0.786C470.409,316.441,471.018,316.441,471.394,316.657z"
        />
        <path
            fill="#FFFFFF"
            d="M475.644,319.109c0.376,0.216,0.387,0.57,0.011,0.785c-0.371,0.215-0.98,0.215-1.362,0
	c-0.376-0.215-0.376-0.569,0-0.785C474.659,318.893,475.268,318.893,475.644,319.109z"
        />
        <path
            fill="#7AB2F3"
            d="M464.149,317.499l40.849,23.584l-30.942,17.979c-1.229,0.719-3.249,0.719-4.493,0l-36.345-20.979
	c-1.245-0.72-1.25-1.882-0.016-2.603l30.938-17.979L464.149,317.499z"
        />
        <path
            fill="#FFFFFF"
            d="M478.478,335.938c0.371-0.095,0.542-0.35,0.376-0.563c-0.034-0.05-0.088-0.098-0.15-0.133
	c-0.21-0.121-0.537-0.16-0.824-0.092l-18.146,4.654c-0.38,0.1-0.546,0.348-0.38,0.569c0.039,0.049,0.099,0.094,0.155,0.127
	c0.211,0.122,0.537,0.16,0.825,0.088L478.478,335.938z"
        />
        <path
            fill="#FFFFFF"
            d="M480.852,345.825c0.288,0.083,0.642,0.056,0.885-0.072c0.239-0.127,0.322-0.321,0.221-0.498l-3.708-6.153
	c-0.038-0.063-0.105-0.127-0.188-0.177c-0.21-0.122-0.521-0.172-0.819-0.11c-0.415,0.082-0.642,0.353-0.493,0.586l3.072,5.101
	l-8.08-2.271c-0.399-0.107-0.875-0.018-1.063,0.215c-0.146,0.187-0.078,0.396,0.155,0.531c0.061,0.035,0.133,0.066,0.216,0.09
	L480.852,345.825z"
        />
        <path
            fill="#FFFFFF"
            d="M461.903,337.386c0.396-0.11,0.559-0.387,0.361-0.618l-3.941-4.672l8.81,1.76
	c0.415,0.084,0.869-0.043,1.018-0.287c0.1-0.172,0.017-0.356-0.194-0.477c-0.083-0.05-0.184-0.088-0.304-0.109l-10.637-2.125
	c-0.304-0.062-0.641-0.012-0.854,0.127c-0.216,0.142-0.26,0.347-0.117,0.513l4.799,5.672c0.038,0.05,0.097,0.089,0.155,0.127
	c0.238,0.144,0.603,0.181,0.913,0.089"
        />
        <g>
            <animate
                begin="x.end"
                attributeName="opacity"
                values="1;1;0;1;0;1;0;1;1"
                fill="freeze"
                dur="2s"
                calcMode="linear"
                keyTimes="0;0.3;0.35;0.4;0.45;0.5;0.6;0.65;1"
                repeatCount="1"
            />
            <g id="group" style="transform-origin: 62% 52%" opacity="0">
                <animateTransform
                    begin="start.end"
                    id="x"
                    attributeName="transform"
                    type="scale"
                    from="0 0"
                    to="1 1"
                    dur="0.5s"
                    repeatCount="1"
                    additive="sum"
                />
                <animate
                    begin="start.end"
                    attributeName="opacity"
                    values="0.7;1.0"
                    fill="freeze"
                    dur="1s"
                    repeatCount="1"
                />
                <path
                    fill="#FFC5B3"
                    d="M312.35,245.628c-3.071,1.776-5.562,6.081-5.551,9.617l0.063,21.792l42.314-24.431l0.039,14.033
		l-0.099-35.825c-0.012-3.536-2.514-4.963-5.59-3.187l-31.188,18.001H312.35L312.35,245.628z"
                />
                <path
                    fill="#FFFFFF"
                    d="M306.859,277.037l0.039,14.536c0.011,3.26,2.313,4.571,5.147,2.938l32.049-18.505
		c2.845-1.638,5.136-5.609,5.119-8.863v-0.505l-0.039-14.033L306.859,277.037z"
                />
                <path
                    fill="#FF8540"
                    d="M329.65,267.928l0.006,2.197l15.645-9.031l-0.005-2.197L329.65,267.928z"
                />
                <path
                    fill="#FF8540"
                    d="M329.65,271.936l0.006,2.195l15.645-9.029l-0.005-2.192L329.65,271.936z"
                />
                <path
                    fill="#FF8540"
                    d="M329.65,275.941l0.006,2.197l15.645-9.031l-0.005-2.197L329.65,275.941z"
                />
                <path
                    fill="#FF8540"
                    d="M321.492,274.253c-1.396,0.808-2.523,2.756-2.523,4.361c0,1.604,1.146,2.245,2.54,1.444
		c1.396-0.808,2.524-2.762,2.521-4.36C324.027,274.093,322.887,273.451,321.492,274.253z"
                />
            </g>
        </g>
        <g id="shadow" opacity="0.1">
            <path
                d="M409.114,404.184c0.024-10.559-14.729-19.16-32.964-19.211c-18.235-0.051-33.042,8.467-33.071,19.026
		c-0.03,10.56,14.729,19.16,32.964,19.211S409.084,414.742,409.114,404.184z"
            />
        </g>
        <g opacity="0.1">
            <path
                d="M205.12,496.464c0.024-8.604-12.003-15.616-26.864-15.658c-14.86-0.041-26.927,6.902-26.951,15.513
		c-0.024,8.604,12.004,15.611,26.864,15.653C193.03,512.013,205.096,505.07,205.12,496.464z"
            />
        </g>
        <path
            fill="#60BBF7"
            d="M378.673,396.686c-7.615-2.558-7.305,1.698-6.313,2.627c4.389,4.112,10.715,1.998,10.803,3.063
	c0.011,0.133,0.017,0.161,0,0.288c-0.072,0.719,3.78,0.352,4.206-0.219c0.681-0.904,0.686-5.461-0.077-5.813
	c-2.873,0.806-5.584,0.947-8.457,0.128l-0.166-0.068h0.005L378.673,396.686L378.673,396.686z"
        />
        <path
            fill="#60BBF7"
            d="M387.555,402.068c0.072,0.184-0.089,0.925-0.332,1.021c-0.924,0.386-3.133,0.697-4.438,0.044
	c-0.243-0.117,0.028-0.494-0.21-0.615c-0.604-0.309-7.776,0.703-10.399-3.199c-0.044-0.066-0.31-0.547-0.233-0.73
	c2.092,2.402,5.563,3.178,8.854,3.178c0.913,0,2.424-0.021,2.596,0.303c0.121,0.229-0.021,0.52,0.046,0.558
	c0.188,0.106,2.178,0.332,4.121-0.563L387.555,402.068L387.555,402.068z"
        />
        <path
            fill="#60BBF7"
            d="M363.288,404.145c-0.172,1.111-6.42,0.592-7.593,1.476c-2.535,1.915,2.999,6.292,12.563,5.651
	c0,0.021,0.021,0,0,0c0-0.056,0.061-0.25,0.138-0.25c0.2-0.111,0.127,0,0.244,0.338c1.201,0.322,4.521-0.437,4.367-0.36
	c0.592-2.146,0.747-4.626-0.344-6.888c-2.733,2.062-6.336,2.107-9.142,0.148L363.288,404.145L363.288,404.145L363.288,404.145z"
        />
        <path
            fill="#60BBF7"
            d="M373.1,410.746c0.063,0.32,0,0.48-0.067,0.763c-1.378,0.3-3.697,0.521-4.455,0.233
	c-0.106-0.039-0.097-0.161-0.117-0.223c-0.044-0.115-4.128,0.472-8.865-0.924c-0.914-0.269-4.146-1.177-4.524-3.822
	c1.109,3.834,9.1,4.658,13.217,4.316c0.061-0.171,0.294-0.316,0.41,0.116c2.208,0.089,3.396-0.171,4.4-0.472L373.1,410.746z"
        />
        <path
            fill="#F6E1CB"
            d="M337.83,276.162c0.021-0.199-0.15-0.477-0.229-0.653c-0.189-0.437-0.396-0.874-0.579-1.311
	c-0.778-1.843-1.525-4.095-0.882-6.165c0.293-0.938,0.896-1.909,1.671-2.523c0.581-0.459,1.317-0.74,2.063-0.686
	c0.924,0.072,1.896,0.785,2.022,1.754c0.188,1.5-0.201,2.771,0.524,4.229c0,0,0.216,0.967,0.487,0.818
	c1.019-0.559,0.771-3.055,1.129-3.664c0.063-0.109,0.144-0.182,0.249-0.199c1.396-0.21,1.804,4.146-0.127,7.57
	c-0.155,0.271-0.288,0.554-0.415,0.841c-0.697,1.572-0.033,2.425-0.316,3.222c-2.103,5.896-6.497,4.084-5.595-3.229L337.83,276.162
	L337.83,276.162z"
        />
        <path
            fill="#2840AC"
            d="M387.832,325.533c2.169,10.072-0.936,28.006-0.548,37.502c0.376,9.324,1.688,31.104-0.063,33.926
	c-0.465,0.747-5.092,1.6-8.447,0.094c-0.442-0.199-0.771-0.586-0.74-1.072c0.188-3.299-0.161-10.239-0.183-16.564
	c-0.044-13.188-4.488-18.768-4.51-34.613c0-2.396-0.753-3.313,0.028-5.582c0.918-2.658,3.171-4.604,5.329-6.404
	c2.922-2.44,6.221-4.832,9.137-7.271L387.832,325.533z"
        />
        <g opacity="0.4">
            <path
                fill="#EF6486"
                d="M387.91,325.4c-2.438,6.453-7.876,11.709-14.439,13.807c0.946,3.663-0.078,7.288,0.083,11.001
		c0.063,1.433,0.249,2.772,0.382,4.205c0.166,1.815,1.195,6.696,1.654,8.915c0.759,3.646,1.827,5.915,2.065,11.947
		c0.282,7.16,0.498,17.729,0.376,20.828c-0.05,0.752,1.188,1.207,1.826,1.334c-0.055-8.726,0.1-15.571,0.073-22.787
		c-0.023-8.174-5.208-20.521-3.646-32.992c0.204-1.643,1.267-1.92,2.579-2.672c1.416-0.819,2.8-1.705,4.056-2.762
		c3.254-2.722,5.413-6.485,4.987-10.83L387.91,325.4L387.91,325.4z"
            />
        </g>
        <path
            fill="#1541B1"
            d="M360.692,331.389c-2.894,12.213-3.033,24.408-1.509,36.288c0.347,2.657,3.413,27.956,3.097,36.048
	c1.235,2.455,8.075,3.043,9.669-0.035c0.227-0.437,0.299-1.537,0.343-2.104c1.179-14.421-1.683-29.173-1.699-34.977
	c-0.023-8.748,5.91-19.529,4.654-26.906c8.778-3.37,12.771-9.51,12.519-14.78c-7.759,6.447-17.104,8.195-27.052,6.479h-0.021
	L360.692,331.389L360.692,331.389z"
        />
        <g opacity="0.2">
            <path
                fill="#EF6486"
                d="M360.36,331.654c0.005,0.309,0.038,0.724,0.038,1.018v0.012c-0.027,0.127-0.083,0.354-0.149,0.696
		c8.633,2.021,19.962-0.177,26.432-5.534c1.068-0.879,1.151-1.57,1.388-2.628C388.147,324.836,360.36,331.654,360.36,331.654z"
            />
        </g>
        <path
            fill="#60BBF7"
            d="M385.165,299.215c0.747-6.279-3.642-12.838-9.049-16.119c0.05,0.033,1.571-1.018,3.514-1.09
	c3.161-0.115,6.005,0.852,7.654,3.426c2.021,3.146,1.782,6.313,1.185,9.893c-0.554,3.303-3.604,8.394-5.717,11.063
	c0.819-3.807,2.501-3.688,2.606-7.58"
        />
        <path
            fill="#60BBF7"
            d="M389.188,330.193c0.68-3.072-3.399-9.547-3.432-18.473c-0.016-5.604,2.491-14.486,1.777-20.046
	c-1.085-8.434-6.436-9.452-11.379-8.583c-2.979,2.988-6.945,4.063-11.021,2.983c-2.369,0.521-5.302,2.53-7.134,5.438
	c2.418,0.685,4.229,4.077,4.505,6.573c0.277,2.496-2.673,21.678-2.103,34.598c6.774,3.547,15.18,5.229,22.486,1.086
	c5.496-3.115,6.066-2.521,6.303-3.58h-0.008v0.002h0.005V330.193L389.188,330.193z"
        />
        <g opacity="0.23">
            <path
                fill="#60BBF7"
                d="M363.475,334.133c-1.184-5.65-1.563-12.517-0.797-18.234c0.438-3.232,0.216-12.506,0.875-13.646
		c3.298-5.749-1.29-10.751-2.684-11.438c1.416-2.147,2.662-3.486,5.335-4.272l-1.068-0.465c-2.369,0.521-5.302,2.53-7.134,5.438
		c2.418,0.68,4.229,4.076,4.505,6.572c0.277,2.495-2.657,21.714-2.087,34.629c0,0,1.041,0.526,1.396,0.692
		c0.405,0.192,1.666,0.719,1.666,0.719L363.475,334.133z"
            />
        </g>
        <path
            fill="#60BBF7"
            d="M362.828,301.158l-0.105,0.216c1.073-1.915,1.09-4.058,0.243-6.087c-0.846-2.031-2.729-3.287-4.853-3.874
	c-1.993,1.024-9.735,6.209-10.709,7.338c0.631-2.099-3.691-12.933-3.16-18.671c-1.738-2.234-6.614-1.014-6.531-0.984
	c-0.404,6.651-0.083,24.297,5.104,29.604c4.815,4.93,17.317-5.285,20.006-7.537L362.828,301.158z"
        />
        <path
            fill="#F6E1CB"
            d="M376.542,283.754c-6.614,5.755-8.971,2.54-11.755,2.966c1.312-1.101,2.092-4.144,1.334-5.677l5.977-2.977
	l4.471,2.396c-0.265,0.963-0.221,2.22,0.133,3.304h-0.155L376.542,283.754L376.542,283.754z"
        />
        <g opacity="0.47">
            <path
                fill="#F6E1CB"
                d="M376.542,283.754c-5.213,4.693-9.204,2.717-11.755,2.966c1.312-1.101,2.103-4.216,1.306-5.749l6.005-2.904
		l4.471,2.396c-0.265,0.963-0.221,2.221,0.133,3.305h-0.155L376.542,283.754L376.542,283.754z"
            />
        </g>
        <path
            fill="#F6E1CB"
            d="M359.264,264.32c1.178,2.246,1.333,3.547,1.271,6.021c-0.293,11.194,8.147,13.828,12.608,7.72l-4.202-8.627
	L359.264,264.32z"
        />
        <path
            fill="#0726A3"
            d="M377.803,279.521c-1.271,1.666-3.951,2.302-5.512,0.725c-0.193-0.194-0.371-0.426-0.537-0.686
	c-1.04-1.688-1.145-2.438-2.412-2.746c-0.178-0.043,1.311-2.904-0.676-4.371c-0.841-0.619-2.346-0.088-2.435,0.951
	c-3.093-1.52-4.725-8.646-7.011-8.935c-0.587-0.067-0.609-2.174-0.571-2.563c0.255-2.545,2.646-4.211,4.86-5.035
	c6.32-2.354,14.522,0.924,16.957,7.349c1.832,4.842,0.437,11.244-2.679,15.306L377.803,279.521z"
        />
        <g opacity="0.44">
            <path
                fill="#252262"
                d="M365.468,285.758c3.896,1.184,8.285-0.05,11.119-2.966c0.802-0.083,1.389-0.283,2.18-0.177
		c-1.223,2.755-3.896,4.984-6.863,5.472c-2.977,0.493-5.796,0.653-9.103-1.201c0.802-0.459,1.477-0.657,2.064-0.95l0.603-0.172
		V285.758L365.468,285.758z"
            />
        </g>
        <path
            fill="#9746D4"
            d="M191.254,386.757c2.624,1.124,5.479,0.255,7.665-1.726c0.404-0.365,0.781-0.786,1.002-1.301
	c0.338-0.774,0.243-1.903-0.493-2.369c-1.184-0.758-3.27,2.07-4.693,0.996c-1.217-0.918,1.71-1.985,0.891-2.627
	c-1.317-1.035-4.682,1.438-5.091,3.215c-0.227,1,0.039,2.39-0.238,3.381L191.254,386.757z"
        />
        <path
            fill="#9746D4"
            d="M182.848,386.409c2.269-2.33,5.069-4.211,8.108-4.87c0.011,1.494,0.011,3.675,1.35,5.788
	c-2.994,1.528-5.573,4.261-8.124,6.569c-0.626,0.563-1.24,1.146-1.971,1.522c-0.73,0.376-1.61,0.504-2.335,0.122
	c1.444-1.965,2.214-8.813,2.601-8.896l0.365-0.239L182.848,386.409L182.848,386.409z"
        />
        <path
            fill="#3E06A2"
            d="M171.602,487.746c-1.251,1.007-1.146,3.996-0.969,4.975c0.543,3.05,0.908,7.78,0.908,7.78l0.725,0.072
	c0,0-0.233-3.895-0.056-5.854c4.229,4.98,5.784,4.422,7.306,3.702c0.52-0.249,0.996-0.597,1.422-1.012
	c0.421-0.416,2.468-1.749,1.788-2.446c-0.476-0.493-1.107-0.559-1.605-0.454c-2.015,0.437-3.232-1.188-4.201-2.922
	C175.138,488.41,172.841,487.525,171.602,487.746z"
        />
        <g opacity="0.25">
            <path
                fill="#3E06A2"
                d="M172.205,494.447c0.741,0.693,1.439,1.373,2.186,2.065c0.669,0.625,1.367,1.234,2.186,1.563
		c1.405,0.558,3.044-0.142,3.204-2.018c0.05-0.555-0.454-1.214-0.62-1.734c0.88,0.332,1.616,1.118,1.915,2.104
		c0.05,0.166,0.089,0.348,0.078,0.52c-0.012,0.177-0.1,0.343-0.216,0.459c-0.426,0.415-0.902,0.769-1.423,1.013
		c-1.522,0.719-3.077,1.283-7.305-3.702c-0.072-0.089-0.066-0.177-0.022-0.288l0.017,0.016V494.447z"
            />
        </g>
        <path
            fill="#EEC2AA"
            d="M186.777,461.506c-0.276,8.82-1.715,24.569-0.774,32.222c0.243,1.97,1.046,3.868,2.341,5.202
	c-2.762-0.338-5.308-2.424-6.448-5.281c-0.592-1.487-0.913-1.924-1.129-3.529c-0.946-6.979-2.789-13.901-2.075-21.98
	c0.21-2.352,0.609-4.446-0.144-6.723c1.068,1.228,2.595,1.938,4.123,1.909c1.527-0.028,3.033-0.78,4.068-2.042l0.038,0.229V461.506z
	"
        />
        <g opacity="0.32">
            <path
                fill="#EEC2AA"
                d="M183.938,461.395c-0.111,0.448-0.316,0.891-0.31,1.354c0.033,6.475-3.83,7.122,0.874,30.895
		c0.57,2.896,2.363,5.362,4.4,5.788c-0.05,0-0.105-0.061-0.155-0.061c-1.466-1.317-2.269-3.104-2.656-5.168
		c-0.366-1.982-0.432-4.974-0.41-7.099c0-0.095,0-0.188,0-0.278c0.127-8.245,0.614-15.068,1.041-23.296
		c0.033-0.653,0.199-1.821-0.183-2.313c-0.288-0.371-0.747-0.515-1.184-0.498c-0.438,0.021-0.853,0.179-1.268,0.332L183.938,461.395
		z"
            />
        </g>
        <path
            fill="#EEC2AA"
            d="M177.33,460.133c0.714,5.021-0.98,14.947-0.93,25.378c0.011,2.197,0.382,4.979,1.074,7.033
	c-1.351-1.804-3.437-5.041-5.905-4.792c0.044-9.518-5.634-15.976-1.279-31.343c2.496,1.472,4.145,1.882,6.641,3.354l0.393,0.376
	L177.33,460.133z"
        />
        <g opacity="0.32">
            <path
                fill="#EEC2AA"
                d="M174.779,457.92c-0.111,0.448-0.316,0.885-0.31,1.354c0.033,6.476-3.83,7.118,0.874,30.895
		c0.57,2.896,2.363,5.363,4.4,5.789c-0.05,0,0.216-0.255,0.171-0.255c-1.931-1.334-2.435-2.822-2.872-4.881
		c-0.658-3.098-0.653-4.553-0.631-6.668c0-0.099,0-0.188,0-0.282c0.1-6.529,0.853-13.579,1.091-19.462
		c0.06-1.543-0.056-3.229-0.083-4.576c-0.017-0.653,0.343-1.605-0.039-2.092c-0.288-0.37-0.747-0.519-1.185-0.498
		c-0.437,0.017-0.852,0.177-1.267,0.332L174.779,457.92z"
            />
        </g>
        <path
            fill="#3E06A2"
            d="M180.667,489.5c-2.015,0.786-1.655,4.106-1.467,5.086c0.581,3.038,1.013,7.764,1.013,7.764l0.725,0.063
	c0,0-0.349-3.547-0.199-5.511c0.492,0.653,1.14,1.483,1.4,1.809c0.537,0.653,1.101,1.284,1.743,1.804
	c1.301,1.068,2.779,1.456,4.289,0.709c0.515-0.255,0.991-0.61,1.412-1.041c0.415-0.42,1.621-1.588,0.935-2.271
	c-0.487-0.481-1.544,0.293-2.042,0.41c-2.009,0.464-3.404-1.313-4.134-3.187c-0.426-1.085-0.725-2.231-1.24-3.279
	c-0.476-0.971-1.107-2.112-2.142-2.327L180.667,489.5L180.667,489.5L180.667,489.5z"
        />
        <g opacity="0.34">
            <path
                fill="#3E06A2"
                d="M180.745,496.915c0.022-0.237-0.072-0.547-0.056-0.785c0.792,1.461,4.107,5.998,7.649,4.969l-0.172,0.127
		c-1.511,0.747-2.988,0.36-4.289-0.708c-0.636-0.521-1.206-1.146-1.743-1.804c-0.26-0.313-0.908-1.146-1.4-1.81l0.011,0.017V496.915
		L180.745,496.915z"
            />
        </g>
        <path
            fill="#EEC2AA"
            d="M185.742,364.451c2.181,3.918,1.124,5.982,1.921,8.345c1.328,3.946,0.99,5.229,0.852,6.834
	c-0.183,2.142-1.539,3.154-4.61,2.905c0.077,1.832,0.609,3.73,1.19,5.439c-1.411-0.176-2.845-0.354-4.151-0.979
	c-1.306-0.625-2.49-1.776-2.889-3.313c-0.404-1.539-0.93-1.512-1.953-2.619c1.566,0.479,3.436,0.123,4.715-1.006
	c-1.174-0.466-2.125-1.727-2.397-3.1c-0.265-1.372,0.133-2.889,1.024-3.868c0.814-0.896,1.932-1.312,2.911-1.964
	c2.048-1.361,3.055-4.023,3.304-6.689l0.089,0.02L185.742,364.451L185.742,364.451z"
        />
        <path
            fill="#9746D4"
            d="M176.433,382.508c-2.014-1.041-4.51-0.404-6.071,2.407c-1.179,2.119-1.217,4.797-1.013,7.293
	c0.526,6.33,3.697,12.347,2.823,18.626c-0.338,2.424-3.719,5.898-4.654,14.626c0,0,10.089,8.964,22.043,4.039
	c0.431-4.688-2.142-9.872-2.336-11.946c-0.199-2.109,0.697-3.869,1.19-5.916c4.444-15.484-3.94-12.18-2.335-18.084
	c0.188-0.686,1.604-0.637,1.654-1.351c0.05-0.713-1.201-2.479-1.643-2.988c-0.664-0.769-1.827-0.47-2.773-0.56
	c-3.193-0.286-5.473-2.655-6.802-5.947l-0.088-0.199H176.433L176.433,382.508z"
        />
        <path
            fill="#3E06A2"
            d="M167.49,425.344c-3.757,4.604-1.046,15.25-0.243,18.229c0.802,2.977,1.621,5.979,1.804,9.087
	c0.127,2.158-0.061,4.343-0.553,6.43c0.348-0.653,1.051-0.991,1.726-1.008c0.676-0.022,1.329,0.233,1.954,0.521
	c1.721,0.813,3.304,1.942,4.959,2.916c1.654,0.979,3.431,1.787,5.296,1.865c1.865,0.077,3.946-0.432,5.058-2.12l0.128-0.067
	c0.575-4.017,1.151-8.029,1.721-12.041c0.321-2.235,2.014-12.539,0.265-19.827c-10.703,3.73-17.505-0.952-21.484-3.63l-0.636-0.353
	L167.49,425.344L167.49,425.344z"
        />
        <path
            fill="#3E06A2"
            d="M185.753,364.291c0.576,1.98,0.166,4.465-0.747,6.402c-0.918,1.947-3.293,2.894-3.802,4.809
	c-2.595-1.98-2.164,3.861,0.709,3.923c-1.168,2.286-3.78,2.961-6.038,2.28c-2.48-0.747-4.201-3.143-4.754-5.91
	c-0.548-2.732-0.166-5.982,0.974-8.466c1.201-2.612,3.293-4.759,5.75-5.75c1.505-0.608,3.309-0.886,4.87-0.42
	c1.666,0.491,2.612,1.677,3.033,3.132H185.753L185.753,364.291z"
        />
        <path
            fill="#3E06A2"
            d="M171.94,368.657c-0.637-0.576-1.533-1.063-2.319-0.726c-1.112,0.479-1.539,2.149-1.533,3.36
	c0.011,2.336,0.819,4.808,2.064,6.657c0.902,1.34,3.858,3.938,4.632,1.109c0.46-1.69,0.122-3.853-0.282-5.521
	c-0.42-1.729-1.156-3.444-2.346-4.67C172.089,368.796,172.017,368.727,171.94,368.657L171.94,368.657z"
        />
        <g opacity="0.4">
            <path
                fill="#A0A0A0"
                d="M181,463.16c3.63,0.847,5.849-0.846,6.514-1.881c0.044-0.067,0.099-0.063,0.105-0.104
		c0.127-0.874,0.188-1.688,0.47-3.146c0.919-4.776,3.592-22.438,1.517-28.493c-0.133-0.382,0.619-1.123-0.858-6.707
		c-0.526-1.997-1.483-3.945-1.472-6.063c0.011-2.475,1.029-4.854,1.715-7.146c0.825-2.75,1.262-6.026-0.271-8.352
		c-0.686-1.041-1.693-1.763-2.407-2.785c-0.714-1.021-1.041-2.609-0.255-3.56c0.376,0.747,0.747,1.494,1.123,2.247
		c1.273-2.286,0.952-5.545-0.736-7.46c-2.202,1.06-3.619,1.821-4.239,6.115c-0.669,4.608,2.939,7.114,3.62,11.305
		c0.63,3.874-0.775,8.279-1.362,12.06c-0.919,5.941,1.743,12.813-0.581,18.546c-0.614,1.516-1.721,2.813-3.116,3.381
		c-1.394,0.563-3.06,0.327-4.178-0.771c2.928,3.376,5.274,1.992,6.148,1.19c-1.101,4.139-0.094,8.646-0.105,12.971
		c-0.011,3.237-1.688,4.548-1.627,8.665v-0.021L181,463.16z"
            />
        </g>
        <g opacity="0.4">
            <path
                fill="#3B4A7C"
                d="M170.136,427.12c-0.797-0.554-1.683-0.969-2.413-1.107c-1.295,3.569-1.118,7.786,0.47,11.2
		c-1.312-0.963-2.086-2.534-2.391-4.266c0.028,1.129,0.044,2.578,0.072,3.707c-0.957-3.938-0.249-8.688,1.644-11.355
		c0.824,0.526,2.612,1.655,2.645,1.821h-0.033H170.136L170.136,427.12z"
            />
        </g>
        <g opacity="0.4">
            <path
                fill="#3B4A7C"
                d="M169.959,395.993c-0.642-4.095,0-9.093,1.987-11.394c0.913-1.063,2.883-2.119,4.106-1.578
		c-1.671-0.696-3.071-0.275-4.322,1.147c-1.251,1.437-1.987,3.575-2.147,5.578C169.422,391.754,169.71,394.006,169.959,395.993z"
            />
        </g>
        <g opacity="0.23">
            <path
                fill="#603230"
                d="M173.534,378.119c0.514-2.086,0.016-4.881-1.046-6.668c-0.294-0.492-0.714-0.896-1.268-0.852
		c-0.553,0.043-0.918,0.543-1.024,1.123c-0.193,1.025-0.11,3.105,0.775,3.771c0.177,0.128,0.46,0.147,0.587-0.071
		c0.072-0.128,0.061-0.299-0.022-0.415c0.133,0.121,0.171,0.282,0.166,0.478c-0.006,0.25-0.15,0.473-0.371,0.498
		c-0.221,0.035-0.443-0.076-0.614-0.236c-1.124-1.02-1.954-4.979-0.15-5.705c0.465-0.188,0.98,0.039,1.384,0.354
		c0.996,0.784,1.439,2.125,1.688,3.42C173.91,375.23,173.877,376.725,173.534,378.119z"
            />
        </g>
        <g opacity="0.23">
            <path
                fill="#603230"
                d="M172.216,374.313c0.448,0.564,0.758,1.316,0.814,2.092c0.055,0.779-0.393,1.688-1.085,1.714
		c-1.245,0.044-3.044-2.784-3.088-6.854c-0.194,1.518-0.033,3.09,0.437,4.521c0.238,0.72,0.553,1.409,0.952,2.034
		c0.315,0.494,0.714,0.971,1.223,1.195c0.382,0.172,0.791,0.148,1.151-0.076c0.686-0.435,0.902-1.519,0.736-2.33
		c-0.05-0.236-0.232-1.403-1.145-2.291h0.005V374.313L172.216,374.313z"
            />
        </g>
        <g opacity="0.32">
            <path
                fill="#EEC2AA"
                d="M188.084,381.098c-1.03,0.734-2.347,1-3.537,0.701s-2.263-1.156-2.911-2.318
		c-1.516,1.422-3.675,1.92-5.562,1.285c1.046,1.775,1.661,0.928,1.827,2.317c0.21,1.771,1.173,3.171,2.551,4.051
		s2.983,0.759,4.449,0.077c-0.453-1.577-0.918-3.038-1.012-4.703c1.699,0.148,3.381-0.066,4.167-1.34L188.084,381.098z"
            />
        </g>
        <path
            fill="#9746D4"
            d="M184.741,386.785c-3.974-0.148-5.911-1.571-6.912-4.809c-1.174-0.039-1.899,0.713-1.744,1.976
	c0.349,2.833,3.077,5.894,10.338,5.661c-0.216-1.533-1.677-2.834-1.677-2.834L184.741,386.785z"
        />
        <linearGradient
            id="SVGID_6_"
            gradientUnits="userSpaceOnUse"
            x1="153.5488"
            y1="-516.2368"
            x2="152.3147"
            y2="-595.9229"
            gradientTransform="matrix(1 0 0 -1 41.6992 -169.7998)"
        >
            <stop offset="0" style="stop-color: #00c7fd" />
            <stop offset="0.29" style="stop-color: #009dfc" />
            <stop offset="0.59" style="stop-color: #0079fc" />
            <stop offset="0.84" style="stop-color: #0063fc" />
            <stop offset="0.99" style="stop-color: #005bfc" />
        </linearGradient>
        <path
            fill="url(#SVGID_6_)"
            d="M197.602,421.426l-5.584-3.242l-0.216-76.199l5.584,3.248L197.602,421.426z"
        />
        <linearGradient
            id="SVGID_7_"
            gradientUnits="userSpaceOnUse"
            x1="150.1025"
            y1="-494.2676"
            x2="222.0039"
            y2="-494.2676"
            gradientTransform="matrix(1 0 0 -1 41.6992 -169.7998)"
        >
            <stop offset="0" style="stop-color: #00c7fd" />
            <stop offset="0.29" style="stop-color: #009dfc" />
            <stop offset="0.59" style="stop-color: #0079fc" />
            <stop offset="0.84" style="stop-color: #0063fc" />
            <stop offset="0.99" style="stop-color: #005bfc" />
        </linearGradient>
        <path
            fill="url(#SVGID_7_)"
            d="M197.386,345.232l-5.584-3.248l66.317-38.281l5.584,3.242L197.386,345.232z"
        />
        <path
            fill="#7AC1F4"
            d="M263.704,306.945l0.216,76.199l-66.317,38.277l-0.216-76.189L263.704,306.945z"
        />
        <path
            fill="#3542A8"
            d="M257.572,315.036l0.166,43.815l-53.976,31.775l-0.166-43.813L257.572,315.036z"
        />
        <path fill="#FFFFFF" d="M227.001,347l0.049,18l13.951-16.833L227.001,347z" />
        <path fill="#FFFFFF" d="M206.374,397.305v2.279l50.301-29.516v-2.59L206.374,397.305z" />
        <path fill="#FFFFFF" d="M206.374,402.02v2.279l50.301-29.516v-2.59L206.374,402.02z" />
        <path fill="#FFFFFF" d="M206.374,406.729v2.279l50.301-29.511v-2.589L206.374,406.729z" />
        <path
            fill="#EEC2AA"
            d="M194.768,416.611c1.771,1.976,4.356,2.43,6.752,1.716c0.443-0.134,0.88-0.321,1.223-0.647
	c0.52-0.492,0.803-1.445,0.36-2.119c-0.72-1.097-3.31,0.342-4.118-1.097c-0.691-1.233,2.015-0.918,1.561-1.771
	c-0.736-1.373-4.25-0.735-5.147,0.543c-0.503,0.719-0.73,1.953-1.267,2.639L194.768,416.611z"
        />
        <path
            fill="#9746D4"
            d="M182.759,394.084c0.747-1.45,1.899-2.595,3.515-2.407c1.676,0.194,2.628,1.776,3.016,3.591
	c0.631,2.95-1.904,11.771-2.076,15.821c0,0.116,0,0.155,0.017,0.149c2.546-0.1,6.281,1.865,8.75,1.354
	c0.276,1.832,0.066,3.09-0.659,4.756c-2.225-0.23-8.501,1.504-10.172,1.154c-2.252-0.465-3.132-2.865-3.398-5.188
	c-0.376-3.254-0.515-6.541-0.443-9.819c0.056-2.496,0.039-5.06,0.698-7.466c0.177-0.652,0.426-1.334,0.747-1.953L182.759,394.084z"
        />
        <g id="js">
            <animateTransform
                restart="always"
                dur="3s"
                values="0,0;0,5;0,0"
                fill="remove"
                type="translate"
                calcMode="linear"
                attributeType="XML"
                attributeName="transform"
                additive="replace"
                accumulate="none"
                repeatCount="indefinite"
            ></animateTransform>
            <path
                fill="#FFCEB3"
                d="M422.703,57.905c-3.525-2.036-6.414-0.415-6.425,3.63l-0.06,20.087c-0.011,4.078,2.855,9.02,6.382,11.051
		l17.481,10.093c3.526,2.036,6.387,0.404,6.398-3.674l0.055-20.087c0.012-4.05-2.833-8.975-6.358-11.012L422.698,57.9
		L422.703,57.905z"
            />
            <path
                fill="#ED8E29"
                d="M421.298,57.285c-0.356-0.116-0.714-0.194-1.051-0.227c-0.034,0-0.063-0.005-0.094-0.011
		c-0.36-0.028-0.709-0.011-1.035,0.05c-0.021,0-0.034,0.005-0.05,0.011c-0.332,0.072-0.646,0.188-0.929,0.349l5.301-3.077
		c0,0,0.021-0.011,0.028-0.022c0.105-0.061,0.21-0.111,0.321-0.155s0.221-0.083,0.338-0.122c0.072-0.022,0.155-0.039,0.232-0.055
		c0.017,0,0.032-0.011,0.055-0.011c0.028,0,0.05-0.011,0.078-0.017c0.127-0.022,0.254-0.039,0.387-0.044
		c0.144-0.011,0.283-0.011,0.426,0c0.05,0,0.1,0.011,0.146,0.017c0.034,0,0.061,0.005,0.094,0.011
		c0.083,0.006,0.161,0.011,0.244,0.022c0.183,0.027,0.371,0.066,0.563,0.122c0.074,0.022,0.166,0.061,0.24,0.083
		c0.072,0.022,0.149,0.05,0.229,0.078c0.094,0.033,0.188,0.055,0.288,0.1c0.293,0.122,0.592,0.271,0.896,0.448l17.483,10.093
		c0.681,0.393,1.322,0.918,1.937,1.5c0.063,0.063,0.138,0.088,0.194,0.149c0.083,0.083,0.16,0.171,0.238,0.254
		c0.067,0.072,0.144,0.149,0.21,0.227c0.083,0.088,0.166,0.171,0.242,0.26c0.179,0.205,0.35,0.415,0.516,0.631
		c0.061,0.078,0.11,0.155,0.172,0.232c0.027,0.039,0.055,0.078,0.088,0.116c0.061,0.078,0.111,0.149,0.166,0.227
		c0.128,0.183,0.249,0.365,0.371,0.548c0.111,0.177,0.221,0.36,0.327,0.537c0.011,0.017,0.016,0.028,0.026,0.044
		c0.012,0.017,0.018,0.033,0.024,0.044c0.083,0.149,0.172,0.293,0.249,0.448c0.094,0.177,0.188,0.359,0.277,0.542
		c0.077,0.166,0.153,0.332,0.227,0.498c0,0.011,0.015,0.022,0.021,0.033c0,0.011,0.006,0.017,0.011,0.028
		c0.083,0.188,0.161,0.382,0.232,0.57c0.073,0.199,0.146,0.398,0.217,0.603c0.031,0.1,0.06,0.199,0.094,0.305
		c0.011,0.044,0.026,0.088,0.038,0.133c0.021,0.072,0.045,0.144,0.064,0.216c0.064,0.243,0.129,0.487,0.18,0.729
		c0.032,0.149,0.05,0.293,0.076,0.443c0.012,0.078,0.024,0.155,0.039,0.232c0.012,0.072,0.024,0.139,0.037,0.21
		c0.058,0.42,0.081,0.841,0.081,1.25l-0.055,20.087c-0.006,2.03-0.72,3.453-1.866,4.123l-5.296,3.076
		c1.146-0.669,1.86-2.091,1.865-4.123l0.056-20.087c0-0.476-0.045-0.968-0.113-1.461c-0.015-0.077-0.026-0.149-0.038-0.227
		c-0.077-0.459-0.187-0.93-0.32-1.395c-0.011-0.044-0.028-0.089-0.039-0.133c-0.155-0.504-0.338-1.007-0.554-1.505
		c0-0.011-0.014-0.017-0.014-0.027c-0.223-0.509-0.478-1.007-0.754-1.489c-0.006-0.011-0.017-0.027-0.021-0.039
		c-0.272-0.471-0.576-0.924-0.893-1.361c-0.026-0.039-0.059-0.078-0.092-0.116c-0.293-0.393-0.605-0.769-0.935-1.124
		c-0.067-0.077-0.141-0.149-0.211-0.221c-0.14-0.144-0.294-0.271-0.438-0.41c-0.61-0.581-1.256-1.106-1.937-1.5l-17.481-10.09
		c-0.4-0.232-0.797-0.404-1.184-0.542c-0.077-0.027-0.149-0.055-0.229-0.077L421.298,57.285z"
            />
            <path
                fill="#FFFFFF"
                d="M429.344,73.056l-1.76-1.019l-0.293,0.166l-0.027,8.904c0,0.62-0.172,1.023-0.543,1.179
		c-0.36,0.155-0.83,0.083-1.422-0.255c-0.563-0.326-1.021-0.791-1.367-1.35c-0.343-0.564-0.509-1.167-0.509-1.787v-0.675
		l-0.288-0.503l-1.782-1.029l-0.293,0.166v0.675c0,0.869,0.188,1.743,0.531,2.612c0.365,0.858,0.854,1.671,1.511,2.418
		c0.641,0.73,1.367,1.323,2.197,1.804c0.858,0.492,1.616,0.764,2.252,0.764c0.659,0.017,1.151-0.238,1.522-0.67
		c0.371-0.465,0.543-1.123,0.543-1.992l0.022-8.904l-0.293-0.509"
            />
            <path
                fill="#FFFFFF"
                d="M434.856,79.696c-1.056-0.609-1.909-0.847-2.551-0.653c-0.661,0.183-0.979,0.758-0.98,1.71
		c0,0.758,0.216,1.477,0.631,2.136c0.415,0.659,1.002,1.339,1.71,2.025c0.271,0.293,0.686,0.675,1.223,1.157
		c0.465,0.382,0.813,0.719,1.002,0.946l0.293,0.675l-0.343,0.476c-0.243,0.027-0.537-0.083-0.93-0.31
		c-0.415-0.238-0.758-0.52-1.023-0.874c-0.265-0.354-0.388-0.675-0.388-0.958v-0.138l-0.293-0.282l-1.688-0.974l-0.294,0.166v0.083
		c0,0.564,0.166,1.167,0.46,1.76c0.296,0.62,0.729,1.212,1.299,1.76c0.559,0.575,1.19,1.057,1.949,1.494
		c1.104,0.637,1.981,0.891,2.644,0.736c0.665-0.155,1.008-0.687,1.008-1.638c0-0.813-0.238-1.571-0.703-2.263
		c-0.437-0.675-1.074-1.378-1.882-2.125c-0.293-0.282-0.609-0.548-0.93-0.819c-0.536-0.454-0.902-0.802-1.146-1.084l-0.343-0.758
		l0.37-0.437c0.244,0,0.537,0.083,0.908,0.299c0.415,0.238,0.758,0.52,1.002,0.858c0.239,0.337,0.36,0.658,0.36,0.94v0.111
		l0.293,0.282l1.644,0.946l0.188,0.027l0.072-0.1c0-0.62-0.144-1.234-0.437-1.881c-0.293-0.647-0.708-1.25-1.245-1.815
		C436.201,80.582,435.564,80.106,434.856,79.696z"
            />
        </g>
        <g id="css">
            <animateTransform
                restart="always"
                dur="3s"
                values="0,0;0,8;0,0"
                fill="remove"
                type="translate"
                calcMode="linear"
                attributeType="XML"
                attributeName="transform"
                additive="replace"
                accumulate="none"
                repeatCount="indefinite"
            ></animateTransform>

            <linearGradient
                id="SVGID_8_"
                gradientUnits="userSpaceOnUse"
                x1="-28.3179"
                y1="-408.5669"
                x2="2.6663"
                y2="-449.1401"
                gradientTransform="matrix(1 0 0 -1 41.6992 -169.7998)"
            >
                <stop offset="0" style="stop-color: #f3e5ff" />
                <stop offset="1" style="stop-color: #deafff" />
            </linearGradient>
            <path
                fill="url(#SVGID_8_)"
                d="M33.682,226.288c4.073-2.352,7.411-0.482,7.427,4.2l0.066,23.219
		c0.011,4.714-3.304,10.425-7.377,12.777l-20.211,11.664c-4.073,2.353-7.383,0.466-7.399-4.25L6.121,250.68
		c-0.011-4.681,3.276-10.376,7.35-12.727L33.682,226.288z"
            />

            <linearGradient
                id="SVGID_9_"
                gradientUnits="userSpaceOnUse"
                x1="-21.5405"
                y1="-403.3789"
                x2="-35.1855"
                y2="-437.3124"
                gradientTransform="matrix(1 0 0 -1 41.6992 -169.7998)"
            >
                <stop offset="0" style="stop-color: #d58cff" />
                <stop offset="1" style="stop-color: #9948ff" />
            </linearGradient>
            <path
                fill="url(#SVGID_9_)"
                d="M35.31,225.568c0.42-0.132,0.824-0.227,1.217-0.265c0.033,0,0.072-0.006,0.105-0.011
		c0.421-0.034,0.819-0.011,1.196,0.061c0.022,0,0.039,0.011,0.061,0.011c0.382,0.083,0.742,0.215,1.074,0.404l-6.121-3.559
		c0,0-0.028-0.016-0.039-0.022c-0.122-0.066-0.244-0.127-0.371-0.182c-0.127-0.056-0.26-0.1-0.393-0.139
		c-0.088-0.027-0.177-0.044-0.266-0.061c-0.022,0-0.039-0.011-0.061-0.016c-0.027-0.006-0.061-0.017-0.088-0.022
		c-0.144-0.028-0.293-0.045-0.448-0.05c-0.161-0.011-0.327-0.011-0.493,0c-0.055,0-0.111,0.011-0.166,0.017
		c-0.039,0-0.072,0.005-0.111,0.011c-0.094,0.011-0.182,0.011-0.282,0.027c-0.216,0.033-0.432,0.078-0.653,0.139
		c-0.094,0.027-0.188,0.066-0.282,0.099c-0.088,0.028-0.171,0.056-0.26,0.089c-0.111,0.038-0.216,0.066-0.332,0.116
		c-0.337,0.138-0.686,0.315-1.035,0.515L7.35,234.394c-0.786,0.454-1.527,1.063-2.241,1.738c-0.072,0.066-0.155,0.105-0.227,0.177
		c-0.094,0.094-0.183,0.199-0.277,0.293c-0.083,0.089-0.166,0.172-0.243,0.26c-0.094,0.1-0.194,0.2-0.282,0.299
		c-0.205,0.238-0.404,0.482-0.598,0.731c-0.066,0.088-0.133,0.177-0.199,0.265c-0.033,0.045-0.066,0.089-0.1,0.139
		c-0.066,0.088-0.133,0.171-0.193,0.26c-0.144,0.21-0.288,0.42-0.426,0.636c-0.133,0.205-0.26,0.415-0.382,0.625
		c-0.011,0.017-0.017,0.034-0.028,0.05c-0.011,0.017-0.017,0.033-0.027,0.056c-0.1,0.171-0.194,0.343-0.288,0.514
		c-0.11,0.205-0.216,0.415-0.315,0.625c-0.094,0.189-0.177,0.382-0.26,0.576c0,0.011-0.011,0.022-0.017,0.033
		c0,0.011-0.011,0.022-0.011,0.028c-0.094,0.221-0.183,0.437-0.271,0.658c-0.088,0.233-0.171,0.465-0.249,0.698
		c-0.039,0.116-0.072,0.232-0.105,0.348c-0.017,0.05-0.033,0.1-0.044,0.155c-0.022,0.083-0.055,0.166-0.077,0.249
		c-0.078,0.282-0.149,0.565-0.205,0.847c-0.039,0.171-0.061,0.343-0.088,0.509c-0.017,0.088-0.033,0.177-0.044,0.271
		c-0.011,0.083-0.033,0.161-0.044,0.244c-0.061,0.487-0.094,0.973-0.094,1.444l0.066,23.219c0.005,2.347,0.83,3.99,2.158,4.765
		l6.121,3.563c-1.328-0.771-2.153-2.419-2.158-4.77L6.133,250.68c0-0.553,0.05-1.118,0.138-1.693
		c0.011-0.089,0.028-0.177,0.044-0.266c0.094-0.531,0.21-1.073,0.371-1.61c0.017-0.05,0.028-0.1,0.044-0.15
		c0.177-0.581,0.393-1.167,0.642-1.737c0-0.011,0.011-0.022,0.017-0.033c0.255-0.587,0.548-1.162,0.869-1.721
		c0.011-0.017,0.017-0.034,0.028-0.045c0.315-0.542,0.664-1.073,1.029-1.577c0.033-0.044,0.066-0.088,0.1-0.138
		c0.337-0.459,0.703-0.885,1.079-1.3c0.078-0.089,0.161-0.172,0.244-0.261c0.16-0.171,0.337-0.315,0.503-0.47
		c0.708-0.669,1.456-1.278,2.242-1.732l20.211-11.665c0.47-0.271,0.924-0.47,1.367-0.631c0.089-0.033,0.177-0.061,0.26-0.088
		L35.31,225.568z"
            />

            <linearGradient
                id="SVGID_10_"
                gradientUnits="userSpaceOnUse"
                x1="-5.062"
                y1="-397.5571"
                x2="-33.6384"
                y2="-437.015"
                gradientTransform="matrix(1 0 0 -1 41.6992 -169.7998)"
            >
                <stop offset="0" style="stop-color: #d58cff" />
                <stop offset="1" style="stop-color: #9948ff" />
            </linearGradient>
            <path
                fill="url(#SVGID_10_)"
                d="M14.605,251.648c-0.819,0.471-1.527,1.046-2.147,1.793c-0.62,0.714-1.101,1.511-1.439,2.358
		c-0.337,0.879-0.503,1.721-0.503,2.6l0.011,4.189c0,0.875,0.171,1.561,0.515,2.042c0.343,0.454,0.825,0.697,1.444,0.692
		c0.625,0.033,1.328-0.217,2.147-0.688c0.819-0.471,1.527-1.046,2.147-1.76c0.62-0.714,1.102-1.479,1.439-2.324
		c0.338-0.813,0.504-1.653,0.504-2.501v-0.1L18.468,258l-1.693,0.913l-0.199,0.146l-0.083,0.209c0,0.613-0.166,1.233-0.504,1.82
		c-0.337,0.586-0.791,1.04-1.355,1.366c-0.565,0.327-1.019,0.394-1.356,0.2c-0.343-0.194-0.509-0.615-0.515-1.234l-0.011-4.383
		c0-0.647,0.166-1.267,0.503-1.854c0.338-0.586,0.792-1.04,1.356-1.367c0.564-0.326,1.019-0.392,1.356-0.199
		c0.338,0.194,0.509,0.615,0.515,1.267v0.1l0.227-0.033l0.083-0.05l1.666-1.09l0.254-0.343c0-0.847-0.171-1.461-0.515-1.915
		c-0.343-0.454-0.825-0.664-1.444-0.631c-0.625,0-1.329,0.249-2.147,0.72"
            />

            <linearGradient
                id="SVGID_11_"
                gradientUnits="userSpaceOnUse"
                x1="-1.4531"
                y1="-400.1626"
                x2="-30.0339"
                y2="-439.6262"
                gradientTransform="matrix(1 0 0 -1 41.6992 -169.7998)"
            >
                <stop offset="0" style="stop-color: #d58cff" />
                <stop offset="1" style="stop-color: #9948ff" />
            </linearGradient>
            <path
                fill="url(#SVGID_11_)"
                d="M23.903,246.281c-0.736,0.426-1.412,0.946-1.976,1.566c-0.592,0.636-1.046,1.317-1.383,2.064
		c-0.31,0.73-0.476,1.444-0.476,2.191c0,0.714,0.171,1.234,0.515,1.555c0.343,0.293,0.736,0.487,1.218,0.498
		c0.509-0.033,1.101-0.083,1.81-0.199c0.653-0.116,1.101-0.183,1.389-0.183c0.282,0,0.509,0.033,0.681,0.128l0.254,0.597
		c0,0.387-0.138,0.83-0.393,1.234c-0.283,0.454-0.675,0.814-1.24,1.14c-0.62,0.36-1.129,0.493-1.527,0.36
		c-0.371-0.111-0.565-0.393-0.57-0.875v-0.26l-0.282-0.094l-1.638,0.947l-0.282,0.487v0.293c0,0.775,0.177,1.356,0.515,1.721
		c0.343,0.387,0.825,0.531,1.444,0.498c0.62-0.033,1.356-0.327,2.175-0.803c0.819-0.475,1.527-1.017,2.12-1.681
		c0.62-0.649,1.101-1.35,1.411-2.081c0.337-0.747,0.503-1.494,0.503-2.241c0-0.78-0.171-1.3-0.515-1.588
		c-0.343-0.294-0.736-0.421-1.162-0.404c-0.454,0.033-1.018,0.066-1.782,0.182c-0.653,0.117-1.162,0.216-1.472,0.2
		c-0.337,0.033-0.564-0.034-0.736-0.128l-0.227-0.647c0-0.421,0.139-0.83,0.393-1.201c0.282-0.42,0.675-0.78,1.185-1.073
		c0.62-0.36,1.129-0.46,1.5-0.31c0.338,0.16,0.537,0.47,0.542,0.99v0.194l0.254,0.017l1.666-0.963l0.282-0.454v-0.194
		c0-0.78-0.205-1.344-0.542-1.737c-0.371-0.371-0.852-0.548-1.472-0.515c-0.653,0.05-1.355,0.293-2.175,0.77"
            />

            <linearGradient
                id="SVGID_12_"
                gradientUnits="userSpaceOnUse"
                x1="2.1206"
                y1="-402.7544"
                x2="-26.4609"
                y2="-442.2194"
                gradientTransform="matrix(1 0 0 -1 41.6992 -169.7998)"
            >
                <stop offset="0" style="stop-color: #d58cff" />
                <stop offset="1" style="stop-color: #9948ff" />
            </linearGradient>
            <path
                fill="url(#SVGID_12_)"
                d="M33.289,240.863c-0.764,0.443-1.411,0.947-2.003,1.583c-0.592,0.636-1.046,1.317-1.35,2.047
		c-0.338,0.747-0.504,1.461-0.504,2.208c0,0.714,0.172,1.234,0.515,1.555c0.338,0.293,0.764,0.471,1.246,0.482
		c0.481-0.017,1.074-0.067,1.81-0.2c0.62-0.099,1.074-0.166,1.356-0.166c0.282,0,0.509,0.034,0.681,0.128l0.254,0.597
		c0,0.388-0.11,0.814-0.393,1.234c-0.255,0.437-0.675,0.814-1.24,1.14c-0.62,0.36-1.129,0.493-1.5,0.343
		c-0.398-0.094-0.598-0.37-0.598-0.857v-0.26l-0.254-0.111l-1.666,0.963l-0.282,0.487v0.293c0,0.78,0.177,1.361,0.515,1.721
		c0.343,0.387,0.825,0.531,1.444,0.498c0.625-0.033,1.356-0.327,2.175-0.802c0.819-0.476,1.527-1.013,2.147-1.694
		c0.592-0.636,1.074-1.333,1.411-2.08c0.31-0.731,0.476-1.478,0.476-2.225c0-0.78-0.171-1.3-0.515-1.588
		c-0.343-0.288-0.736-0.421-1.162-0.404c-0.426,0.017-1.018,0.066-1.754,0.166c-0.681,0.133-1.19,0.232-1.5,0.216
		c-0.31,0.016-0.564-0.033-0.736-0.127l-0.227-0.648c0-0.42,0.139-0.83,0.421-1.217c0.254-0.404,0.647-0.764,1.157-1.057
		c0.62-0.36,1.129-0.46,1.5-0.31c0.371,0.144,0.537,0.47,0.542,0.99v0.194h0.282l1.666-0.963l0.255-0.437v-0.194
		c0-0.78-0.171-1.361-0.542-1.737c-0.343-0.387-0.852-0.548-1.472-0.515c-0.62,0.033-1.356,0.294-2.147,0.753"
            />
        </g>
        <g id="html">
            <animateTransform
                restart="always"
                dur="3s"
                values="0,0;0,3;0,0"
                fill="remove"
                type="translate"
                calcMode="linear"
                attributeType="XML"
                attributeName="transform"
                additive="replace"
                accumulate="none"
                repeatCount="indefinite"
            ></animateTransform>
            <path
                fill="#1CB3C8"
                d="M93.021,290.373c3.292-1.898,5.988-0.389,5.999,3.393l0.055,18.752c0.011,3.812-2.667,8.422-5.96,10.32
		l-16.326,9.424c-3.293,1.898-5.966,0.377-5.977-3.431l-0.055-18.753c-0.011-3.78,2.646-8.385,5.938-10.282L93.021,290.373z"
            />
            <path
                fill="#00F9F9"
                d="M94.338,289.792c0.338-0.104,0.67-0.183,0.98-0.216c0.027,0,0.055-0.006,0.088-0.012
		c0.337-0.027,0.664-0.015,0.963,0.051c0.017,0,0.033,0.005,0.05,0.011c0.31,0.065,0.604,0.171,0.863,0.326l-4.942-2.872
		c0,0-0.022-0.012-0.033-0.016c-0.1-0.061-0.199-0.106-0.299-0.15c-0.105-0.044-0.21-0.077-0.315-0.111
		c-0.072-0.021-0.144-0.033-0.216-0.049c-0.017,0-0.033-0.006-0.05-0.011c-0.022,0-0.05-0.011-0.072-0.017
		c-0.116-0.022-0.238-0.033-0.359-0.045c-0.127-0.01-0.26-0.015-0.398,0c-0.044,0-0.089,0.012-0.133,0.012
		c-0.027,0-0.061,0.005-0.088,0.011c-0.077,0.005-0.149,0.011-0.227,0.022c-0.172,0.023-0.349,0.063-0.526,0.106
		c-0.072,0.021-0.155,0.06-0.227,0.077s-0.138,0.044-0.21,0.068c-0.088,0.033-0.177,0.059-0.266,0.098
		c-0.271,0.113-0.553,0.255-0.835,0.415l-16.326,9.424c-0.637,0.364-1.234,0.854-1.81,1.396c-0.056,0.06-0.127,0.084-0.183,0.146
		c-0.078,0.077-0.149,0.16-0.222,0.238c-0.066,0.07-0.133,0.138-0.199,0.209c-0.078,0.084-0.155,0.16-0.227,0.244
		c-0.166,0.188-0.326,0.388-0.481,0.586c-0.055,0.069-0.105,0.145-0.16,0.217c-0.028,0.039-0.056,0.07-0.083,0.109
		c-0.05,0.07-0.105,0.14-0.155,0.209c-0.116,0.166-0.232,0.338-0.343,0.517c-0.105,0.167-0.21,0.332-0.31,0.504
		c-0.005,0.019-0.017,0.026-0.022,0.044c-0.006,0.02-0.017,0.027-0.022,0.045c-0.077,0.141-0.16,0.276-0.232,0.415
		c-0.088,0.166-0.177,0.337-0.254,0.503c-0.072,0.155-0.144,0.313-0.21,0.467c0,0.014-0.011,0.02-0.011,0.026
		c0,0.005-0.006,0.019-0.011,0.021c-0.078,0.181-0.149,0.354-0.216,0.531c-0.072,0.188-0.139,0.375-0.199,0.563
		c-0.033,0.095-0.056,0.188-0.089,0.282c-0.011,0.045-0.027,0.084-0.039,0.121c-0.022,0.063-0.044,0.133-0.061,0.197
		c-0.061,0.229-0.122,0.453-0.166,0.688c-0.027,0.139-0.05,0.275-0.072,0.414c-0.011,0.072-0.028,0.146-0.039,0.218
		c-0.011,0.065-0.028,0.132-0.033,0.198c-0.05,0.394-0.078,0.785-0.078,1.166l0.055,18.756c0,1.892,0.67,3.226,1.744,3.846
		l4.942,2.872c-1.074-0.626-1.738-1.954-1.743-3.847l-0.055-18.754c0-0.441,0.039-0.901,0.11-1.365
		c0.011-0.072,0.022-0.145,0.039-0.211c0.072-0.432,0.172-0.863,0.299-1.301c0.011-0.039,0.022-0.084,0.039-0.123
		c0.144-0.469,0.315-0.939,0.52-1.403c0-0.013,0.006-0.019,0.011-0.027c0.21-0.477,0.443-0.938,0.703-1.39
		c0.006-0.012,0.011-0.021,0.022-0.039c0.254-0.441,0.537-0.862,0.83-1.271c0.028-0.039,0.055-0.068,0.083-0.107
		c0.276-0.371,0.57-0.723,0.875-1.055c0.066-0.068,0.127-0.14,0.193-0.211c0.133-0.139,0.271-0.254,0.41-0.382
		c0.576-0.543,1.173-1.034,1.81-1.396l16.326-9.427c0.376-0.215,0.747-0.377,1.102-0.508c0.072-0.025,0.144-0.052,0.21-0.07
		L94.338,289.792z"
            />
            <path
                fill="#FFFFFF"
                d="M78.859,311.943l-1.046,0.603l-0.149,0.288l0.006,2.927l-0.061,0.111l-2.114,1.217l-0.066-0.032
		l-0.005-2.928l-0.177-0.1l-1.024,0.592l-0.171,0.299l0.022,7.404l0.172,0.099l1.023-0.592l0.172-0.298l-0.005-3.006l0.066-0.082
		l2.108-1.223l0.066,0.01l0.011,3.006l0.149,0.109l1.046-0.604l0.155-0.288l-0.022-7.404L78.859,311.943z"
            />
            <path
                fill="#FFFFFF"
                d="M84.781,308.523l-4.787,2.767l-0.177,0.299l0.005,0.974l0.172,0.104l1.61-0.934l0.066,0.016l0.017,6.184
		l0.177,0.1l1.023-0.592l0.172-0.301l-0.017-6.183l0.066-0.083l1.677-0.973l0.149-0.283v-0.979L84.781,308.523z"
            />
            <path
                fill="#FFFFFF"
                d="M91.356,304.727l-1.046,0.604l-0.194,0.237l-1.406,3.42l-0.044,0.076l-0.044-0.025l-1.4-1.799l-0.199-0.012
		l-1.046,0.604l-0.149,0.288l0.022,7.403l0.149,0.107l1.046-0.604l0.155-0.286l-0.017-4.806l0.022-0.082l0.044,0.021l0.985,1.265
		l0.194,0.01l0.504-0.287l0.171-0.229l0.974-2.391l0.044-0.077l0.044,0.05l0.017,4.805l0.149,0.108l1.046-0.604l0.155-0.283
		l-0.022-7.404L91.356,304.727z"
            />
            <path
                fill="#FFFFFF"
                d="M93.901,303.255l-1.041,0.603l-0.155,0.293l0.022,7.404l0.155,0.111l4.372-2.529l0.177-0.299l-0.005-0.951
		l-0.171-0.1l-3.116,1.798l-0.044-0.049l-0.017-6.181L93.901,303.255z"
            />
        </g>
        <g id="search">
            <animateTransform
                restart="always"
                dur="3s"
                values="0,0;0,10;0,0"
                fill="remove"
                type="translate"
                calcMode="linear"
                attributeType="XML"
                attributeName="transform"
                additive="replace"
                accumulate="none"
                repeatCount="indefinite"
            ></animateTransform>

            <linearGradient
                id="SVGID_13_"
                gradientUnits="userSpaceOnUse"
                x1="382.4893"
                y1="-329.6768"
                x2="412.7064"
                y2="-369.2557"
                gradientTransform="matrix(1 0 0 -1 41.6992 -169.7998)"
            >
                <stop offset="0" style="stop-color: #f3e5ff" />
                <stop offset="1" style="stop-color: #deafff" />
            </linearGradient>
            <path
                fill="url(#SVGID_13_)"
                d="M443.987,147.705c3.974-2.297,7.228-0.471,7.245,4.095l0.063,22.649
		c0.011,4.598-3.221,10.17-7.194,12.461l-19.713,11.378c-3.974,2.296-7.206,0.453-7.217-4.145l-0.063-22.649
		c-0.016-4.566,3.189-10.121,7.167-12.418l19.713-11.377L443.987,147.705L443.987,147.705z"
            />

            <linearGradient
                id="SVGID_14_"
                gradientUnits="userSpaceOnUse"
                x1="389.0859"
                y1="-324.6118"
                x2="375.7789"
                y2="-357.7098"
                gradientTransform="matrix(1 0 0 -1 41.6992 -169.7998)"
            >
                <stop offset="0" style="stop-color: #d58cff" />
                <stop offset="1" style="stop-color: #9948ff" />
            </linearGradient>
            <path
                fill="url(#SVGID_14_)"
                d="M445.576,147.002c0.41-0.133,0.808-0.221,1.185-0.26c0.033,0,0.071-0.006,0.105-0.011
		c0.409-0.033,0.797-0.011,1.162,0.061c0.021,0,0.039,0.011,0.061,0.011c0.376,0.077,0.729,0.21,1.046,0.396l-5.972-3.47
		c0,0-0.027-0.017-0.038-0.022c-0.117-0.067-0.238-0.127-0.366-0.177c-0.121-0.05-0.249-0.094-0.382-0.133
		c-0.083-0.022-0.171-0.039-0.26-0.061c-0.022,0-0.038-0.011-0.063-0.011c-0.022-0.006-0.055-0.017-0.088-0.022
		c-0.144-0.021-0.288-0.044-0.437-0.05c-0.155-0.011-0.316-0.011-0.482,0c-0.05,0-0.104,0.011-0.16,0.017
		c-0.033,0-0.067,0.005-0.104,0.011c-0.089,0.011-0.188,0.011-0.272,0.027c-0.21,0.034-0.42,0.078-0.636,0.139
		c-0.089,0.021-0.183,0.066-0.277,0.094c-0.083,0.028-0.166,0.055-0.254,0.088c-0.106,0.039-0.211,0.067-0.316,0.111
		c-0.332,0.138-0.675,0.304-1.018,0.504l-19.713,11.377c-0.77,0.442-1.495,1.035-2.186,1.693c-0.072,0.066-0.15,0.105-0.227,0.172
		c-0.094,0.094-0.177,0.193-0.271,0.287c-0.083,0.083-0.16,0.166-0.238,0.255c-0.088,0.099-0.188,0.194-0.277,0.293
		c-0.194,0.233-0.392,0.465-0.58,0.708c-0.063,0.083-0.127,0.172-0.189,0.261c-0.034,0.044-0.067,0.088-0.104,0.132
		c-0.062,0.083-0.128,0.172-0.188,0.255c-0.146,0.205-0.282,0.409-0.415,0.62c-0.127,0.199-0.249,0.404-0.371,0.608
		c-0.011,0.017-0.017,0.034-0.028,0.05c-0.011,0.017-0.016,0.033-0.022,0.05c-0.098,0.166-0.188,0.332-0.283,0.504
		c-0.105,0.199-0.21,0.403-0.309,0.608c-0.094,0.183-0.176,0.371-0.26,0.559c0,0.011-0.011,0.022-0.017,0.033
		c0,0.011-0.011,0.017-0.011,0.028c-0.096,0.216-0.187,0.426-0.27,0.642c-0.089,0.227-0.166,0.454-0.24,0.681
		c-0.038,0.116-0.065,0.226-0.104,0.343c-0.017,0.049-0.033,0.099-0.044,0.149c-0.021,0.083-0.05,0.16-0.072,0.243
		c-0.078,0.277-0.144,0.554-0.205,0.825c-0.028,0.166-0.055,0.332-0.083,0.498c-0.017,0.089-0.028,0.177-0.044,0.26
		c-0.011,0.078-0.033,0.161-0.039,0.238c-0.061,0.476-0.094,0.946-0.094,1.411l0.066,22.649c0.006,2.291,0.808,3.896,2.103,4.649
		l5.973,3.469c-1.296-0.752-2.099-2.357-2.104-4.648l-0.067-22.649c0-0.537,0.055-1.09,0.138-1.649
		c0.011-0.083,0.023-0.172,0.044-0.255c0.089-0.52,0.211-1.046,0.361-1.571c0.021-0.05,0.027-0.1,0.046-0.15
		c0.18-0.57,0.385-1.134,0.625-1.693c0-0.011,0.014-0.021,0.02-0.033c0.249-0.57,0.537-1.135,0.847-1.683
		c0.016-0.016,0.021-0.027,0.022-0.044c0.31-0.531,0.646-1.046,1.007-1.538c0.033-0.044,0.063-0.089,0.1-0.133
		c0.332-0.443,0.688-0.863,1.056-1.267c0.073-0.083,0.15-0.166,0.233-0.249c0.161-0.166,0.332-0.305,0.493-0.459
		c0.692-0.653,1.417-1.251,2.188-1.694L443.99,147.7c0.454-0.265,0.896-0.459,1.334-0.614c0.083-0.033,0.171-0.061,0.254-0.089
		v0.006L445.576,147.002L445.576,147.002z"
            />

            <linearGradient
                id="SVGID_15_"
                gradientUnits="userSpaceOnUse"
                x1="400.0225"
                y1="-327.6924"
                x2="384.734"
                y2="-363.6686"
                gradientTransform="matrix(1 0 0 -1 41.6992 -169.7998)"
            >
                <stop offset="0" style="stop-color: #d58cff" />
                <stop offset="1" style="stop-color: #9948ff" />
            </linearGradient>
            <path
                fill="url(#SVGID_15_)"
                d="M437.784,172.18c-1.112,0.642-2.23,0.797-3.082,0.31c-1.705-0.974-1.716-4.139-0.023-7.072
		c0.852-1.467,1.96-2.601,3.078-3.243c1.112-0.642,2.23-0.797,3.082-0.31c1.705,0.974,1.716,4.15,0.022,7.072
		C440.015,170.404,438.902,171.538,437.784,172.18z M437.751,159.995c-1.604,0.924-3.204,2.551-4.416,4.654
		c-2.209,3.818-2.386,7.896-0.581,9.672l-2.308,4.001c-0.161,0.044-0.327,0.116-0.493,0.21c-0.487,0.283-0.979,0.77-1.345,1.412
		l-3.679,6.363c-0.737,1.284-0.731,2.673,0.016,3.088c0.37,0.21,0.854,0.144,1.345-0.133c0.487-0.282,0.974-0.775,1.344-1.416
		l3.675-6.364c0.498-0.863,0.648-1.754,0.487-2.391l2.308-4c1.107,0.287,2.423,0,3.724-0.753c1.594-0.919,3.177-2.529,4.389-4.632
		c2.438-4.211,2.424-8.765-0.028-10.165c-1.223-0.697-2.823-0.47-4.427,0.454"
            />
        </g>
        <path fill="#91ABEC" d="M277.811,146L278,196.426L216.19,233L216,182.574L277.811,146z" />
        <path
            fill="#FFFFFF"
            d="M239.04,188.363c1.748-1.009,3.262-3.492,3.262-5.636c0-2.01-1.398-2.945-3.262-1.869
	c-1.748,1.009-3.263,3.492-3.263,5.636C235.894,188.571,237.292,189.372,239.04,188.363z M253.488,177.743l-9.438,20.862
	l-4.894-5.082L231,211.5l32.043-18.5L253.488,177.743z"
        />
        <path
            fill="#E83636"
            d="M271.197,237.12c0.01,0.443,0.162,0.781,0.424,0.944s0.613,0.138,0.982-0.07
	c0.36-0.218,0.713-0.599,0.971-1.062c0.258-0.464,0.404-0.973,0.41-1.42c0.005-0.229-0.029-0.438-0.104-0.609
	c-0.065-0.17-0.175-0.301-0.31-0.383c-0.125-0.087-0.275-0.126-0.443-0.112c-0.167,0.013-0.347,0.077-0.523,0.188
	c-0.188,0.099-0.365,0.244-0.535,0.425c-0.17,0.182-0.323,0.396-0.451,0.629c-0.135,0.237-0.242,0.49-0.314,0.743
	S271.193,236.893,271.197,237.12z"
        />
        <path
            fill="#536697"
            d="M275.971,224.959c0,2.384-1.195,4.959-3.287,6.167l-4.938,2.851c-0.518,0.299-1.245,0.719-1.643,1.429
	c-0.482,0.838-0.533,1.753-0.533,2.509v4.135l-2.254,1.301v-5.376c-0.024-0.586,0.056-1.231,0.235-1.893
	c0.18-0.663,0.452-1.328,0.803-1.952c0.599-0.955,1.355-1.711,2.133-2.121l5.72-3.3c0.836-0.509,1.373-1.366,1.373-2.353
	c-0.002-0.148-0.015-0.292-0.026-0.43c-0.037-0.382-0.195-0.663-0.445-0.791c-0.25-0.127-0.574-0.092-0.908,0.099l-8.875,5.123
	v-1.689c0-0.578,0.216-1.099,0.73-1.396l8.67-5.005c1.918-1.107,3.133,0.049,3.285,2.175
	C275.959,224.637,275.971,224.795,275.971,224.959z"
        />
        <path
            fill="#536697"
            d="M246.364,237.606v12.656c0,1.996-1.56,4.013-3.035,4.865c-0.682,0.394-1.351,0.546-1.928,0.437
	c-0.637-0.189-1.113-0.702-1.359-1.466c-0.88-2.533-1.194-5.669-2.348-7.911c-0.175-0.344-0.493-0.925-0.964-0.631
	c-0.224,0.179-0.424,0.447-0.56,0.748c-0.135,0.301-0.196,0.614-0.17,0.874v10.758c0.012,0.148-0.003,0.315-0.046,0.488
	c-0.043,0.174-0.113,0.35-0.203,0.514c-0.091,0.164-0.201,0.313-0.322,0.438c-0.121,0.124-0.25,0.22-0.379,0.281l-1.351,0.78
	v-12.629c0-2.054,1.38-3.816,2.896-4.784c0.669-0.431,1.331-0.576,1.873-0.411c0.446,0.206,0.808,0.568,1.063,1.063
	c1.209,2.091,1.567,5.17,2.418,7.646c0.204,0.598,0.551,1.563,1.3,1.13c0.548-0.316,0.825-1.112,0.825-1.717v-10.601
	c0-0.818,0.34-1.53,1.034-1.931l1.267-0.731L246.364,237.606z"
        />
        <path
            fill="#536697"
            d="M224.996,262.798l7.279-4.202v2.668l-7.706,4.449c-2.191,1.266-3.546,0.096-4.181-1.872
	c-0.269-0.917-0.4-1.958-0.388-3.085c0-0.396-0.04-1.4,0-1.818c-0.04-3.547,1-6.542,4.569-8.873l7.706-4.449v2.579l-7.268,4.196
	c-1.826,1.023-2.395,3.94-2.512,5.768c-0.027,0.311-0.04,0.614-0.04,0.913C222.444,260.799,222.969,264.044,224.996,262.798z"
        />
        <path
            fill="#E83636"
            d="M255.976,243.231c0.657-3.047,0.971-6.248,1.957-9.317c0.479-1.49,1.304-4.003,2.531-4.547l2.162-1.248
	c-0.876,1.359-1.602,2.837-2.136,4.346c-1.067,3.115-1.461,6.362-2.093,9.453c-0.365,1.798-0.566,3.253-1.753,4.899
	c-0.492,0.662-1.062,1.191-1.643,1.526c-0.669,0.446-1.336,0.632-1.907,0.532c-1.011-0.305-1.285-1.321-1.57-2.513
	c-0.651-2.459-1.461-4.768-2.425-6.911c-0.372-0.975-0.883-1.799-1.515-2.447l-0.132-0.124l1.877-1.084
	c0.928-0.536,1.238,0.237,1.625,0.903c0.25,0.45,0.472,0.934,0.665,1.448c0.782,1.999,1.443,4.268,2.1,6.414
	c0.19,0.629,0.467,1.642,1.256,1.134C255.764,245.186,255.782,244.09,255.976,243.231z"
        />
    </svg>
</template>

<script>
export default {
    data() {
        return {
            index: 0,
        };
    },
    created() {
        // this.play();
    },

    methods: {
        play() {
            setTimeout(() => {
                if (this.index == 1) {
                    this.index = 0;
                } else {
                    this.index++;
                }

                this.play();
            }, 6000);
        },
    },
};
</script>

<style></style>
