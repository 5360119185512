const list = [
    [
        {
            url:'/arrange/1/keyboard-open.svg',
            title: '前后端分离',
            text: '轻耦合、高可用、高并发'
        },
        {
            url:'/arrange/1/grid-lock.svg',
            title: '内外网隔离',
            text: '纯物理隔离，安全可靠'
        },
        {
            url:'/arrange/1/simcard.svg',
            title: '纯静态发布',
            text: '永不宕机，支持任意主机'
        },
        {
            url:'/arrange/1/driver-2.svg',
            title: '多种数据源',
            text: '支持信创国产化数据库'
        },
        {
            url:'/arrange/1/folder-connection.svg',
            title: '支持多站点',
            text: '统一化管理，节省等保成本'
        },
        {
            url:'/arrange/1/folder-cloud.svg',
            title: '资源库',
            text: '上传数据的统一管理'
        },
    ],
    [
        {
            url:'/arrange/2/people.svg',
            title: '权限模块',
            text: '多角色多用户\n栏目级别权限'
        },
        {
            url:'/arrange/2/document-text.svg',
            title: '内容模块',
            text: 'Word黏贴自动传图\n一键排版，实时预览'
        },
        {
            url:'/arrange/2/task-square.svg',
            title: '审核模块',
            text: '可设置多人按流程审核'
        },
        {
            url:'/arrange/2/code.svg',
            title: '扩展模块',
            text: '支持插件的功能开发'
        },
        {
            url:'/arrange/2/presention-chart.svg',
            title: '统计模块',
            text: '数据图表功能\n轻松完成工作统计'
        },
        {
            url:'/arrange/2/grid-6.svg',
            title: '模板引擎',
            text: '所见即所得，灵活完成风格的制作，内嵌多种单元标签'
        },
    ],
    [
        {
            url:'/arrange/3/search-status.svg',
            title: '缓存模块',
            text: '运行速度提升200%'
        },
        {
            url:'/arrange/3/receive-square.svg',
            title: '消息队列',
            text: '低延迟、高并发、高可靠'
        },
        {
            url:'/arrange/3/notification-bing.svg',
            title: '存储模块',
            text: '云存储、低成本、高扩展'
        },
        {
            url:'/arrange/3/note-text.svg',
            title: '监控模块',
            text: '发布监控、性能监控'
        },
        {
            url:'/arrange/3/monitor-recorder.svg',
            title: '日志模块',
            text: '操作日志、审计日志'
        },
        {
            url:'/arrange/3/cloud-add.svg',
            title: '检索模块',
            text: '基于搜索引擎的全文检索'
        },
    ]
]

export default list