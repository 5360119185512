<template>
    <div class="content-card">
        <div class="content-cardbox">
            <div class="cardbox" v-for="(item, index) in cardList">
                <div
                    class="content-card"
                    :style="active == index ? 'display: none' : 'display: flex'"
                    @mouseover="cardHover($event, index)"
                >
                    <div class="card-title">{{ item.title }}</div>
                    <div>
                        <el-image loading="lazy" :src="require('../assets/' + item.img)" />
                    </div>
                    <div class="card-cell-box">
                        <div class="card-cell" v-for="(e, i) in item.cell">{{ e }}</div>
                    </div>
                </div>
                <div
                    class="content-card-hover"
                    :style="
                        active == index
                            ? oldactive == active
                                ? 'display: flex; animation: none'
                                : 'display: flex;'
                            : 'display: none'
                    "
                    @mouseleave="cardLeave"
                >
                    <div class="hover-card">
                        <div style="padding-bottom: 20px">
                            <el-image loading="lazy" :src="require('../assets/' + item.hoverImg)" />
                        </div>
                        <div class="hover-title">{{ item.title }}</div>
                        <div class="hover-text">{{ item.content }}</div>
                    </div>
                    <div class="hover-card2">
                        <div class="hover-cell-box">
                            <div class="hover-cell" v-for="(e, i) in item.cell">{{ e }}</div>
                        </div>
                        <div class="hover-img">
                            <el-image
                                loading="lazy"
                                style="width: 200px; opacity: 0.15"
                                :src="require('../assets/' + item.hoverImg)"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Card',
};
</script>
<script setup>
import { ref } from 'vue';
const cardList = ref([
    {
        title: '先进的网络架构',
        cell: ['前后端分离', '内外网隔离', '静态发布技术'],
        img: 'network.svg',
        hoverImg: 'network2.svg',
        content:
            '基于前后端分离、内外网隔离的技术架构，与页面静态发布技术相结合，全面提升了网络的安全性、可扩展性和访问速度。',
    },
    {
        title: '领先的存储方案',
        cell: ['Redis多级缓存', 'MinIO分布式存储', 'CDN加速技术'],
        img: 'save.svg',
        hoverImg: 'save2.svg',
        content:
            '使用了动态缓存技术，全面提升系统性能，基于MinIO的的分布式技术，提供了高可用、可扩展、高性能的数据存储服务，可以满足大规模数据存储和管理的需求。',
    },
    {
        title: '可靠的安全方案',
        cell: ['用户多角色', '栏目操作权限', '审计日志', 'SM4加密方案'],
        img: 'safe.svg',
        hoverImg: 'safe2.svg',
        content:
            '基于松耦合的权限设计，用户权限颗粒精确到每个栏目，每次访问均有日志记录，信息发布可设置三级审核流程。',
    },
    {
        title: '可视化模板引擎',
        cell: ['单元标签技术', '模板可视化编辑', '页面可灵活定制'],
        img: 'mode.svg',
        hoverImg: 'mode2.svg',
        content:
            '基于可视化模板技术，通过点击配置单元标签，即可完成页面风格的制作，与程序语言无关，用户只需要会简单的HTML即可自己有设计模板。',
    },
]);
let active = ref(0);
let oldactive = ref(0);

const cardHover = (e, index) => {
    active.value = index;
};
const cardLeave = () => {
    oldactive.value = active.value;
};
</script>

<style lang="less" scoped>
.content-card{
    display: flex;
    justify-content: center;
    cursor: pointer;
}
.content-cardbox {
    display: flex;
    width: 1200px;
    justify-content: center;
    align-items: center;
    padding-bottom: 80px;
    position: relative;
    .cardbox:hover {
        .content-card {
            display: none;
            & + .content-card-hover {
                display: flex;
                justify-content: center;
                animation: show 0.3s;
            }
        }
    }
    .content-card {
        display: inline-flex;
        width: 244px;
        height: 480px;
        padding: 40px 20px 0 20px;
        border: 2px solid #fff;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 30px;
        background: #f2f3f5;
        .card-title {
            pointer-events: none;
            color: #1d2129;
            font-family: 'MiSans VF Light';
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        .card-cell-box {
            pointer-events: none;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
        }
        .card-cell {
            display: flex;
            width: 200px;
            padding: 10px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            background: #fff;
            color: #4e5969;
            text-align: justify;
            font-family: 'MiSans VF Light';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
        }
    }
    .content-card-hover {
        height: 480px;
        display: none;
        border: 2px solid #fff;
        .hover-card {
            display: inline-flex;
            width: 234px;
            padding: 40px 33px 159px 33px;
            flex-direction: column;
            align-items: center;
            background: #fff;
            background: #165dff;
            .hover-title {
                color: #fff;
                font-family: 'MiSans VF Light';
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                padding-bottom: 24px;
            }
            .hover-text {
                width: 168px;
                color: #fff;
                text-align: justify;
                font-family: 'MiSans VF Light';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 150% */
            }
        }
        .hover-card2 {
            display: inline-flex;
            overflow: hidden;
            position: relative;
            width: 240px;
            padding: 40px 20px 0 20px;
            flex-direction: column;
            align-items: center;
            background: #4080ff;
            .hover-cell-box {
                display: inline-flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 20px;
            }
            .hover-cell {
                display: flex;
                width: 200px;
                padding: 10px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border: 1px solid rgba(255, 255, 255, 0.3);
                color: #fff;
                text-align: justify;
                font-family: 'MiSans VF Light';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; /* 137.5% */
            }
            .hover-img {
                position: absolute;
                bottom: -20px;
                right: -20px;
            }
        }
    }
}
@keyframes show {
    0% {
        opacity: 0.1;
        width: 0;
    }
    70% {
        opacity: 1;
    }
    100% {
        width: 100%;
    }
}
@keyframes bgchange {
    0% {
        background: #fff;
    }
    100% {
        background: #165dff;
    }
}
</style>
