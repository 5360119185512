<template>
  <div class="content-bottom">
    <div class="center">
      <div class="bottom-content">
        <div class="bottom-box">
          <div class="box-item">
            <!-- <div class="bottom-cell">
              <el-image loading="lazy" :src="require('../assets/bottom/call-calling.svg')" />
              <div class="cell-text">13834724025(销售热线)</div>
            </div> -->
            <!-- <div class="bottom-cell">
              <el-image loading="lazy" :src="require('../assets/bottom/call-calling.svg')" />
              <div class="cell-text">180 6779 6298(销售热线)</div>
            </div> -->
            <!-- <div class="bottom-cell">
              <el-image loading="lazy" :src="require('../assets/bottom/call-calling.svg')" />
              <div class="cell-text">180 6779 6288(渠道合作)</div>
            </div> -->
          </div>
          <div class="box-item">
            <div class="bottom-cell">
              <el-image loading="lazy" :src="require('../assets/bottom/gaoxin.svg')" />
              <div class="cell-text">国家级高新技术企业</div>
            </div>
            <div class="bottom-cell">
              <el-image loading="lazy" :src="require('../assets/bottom/gaoxin.svg')" />
              <div class="cell-text">山西省高成长科技型企业</div>
            </div>
            <!-- <div class="bottom-cell">
              <el-image loading="lazy" :src="require('../assets/bottom/gaoxin.svg')" />
              <div class="cell-text">高校门户网站群技术服务提供商</div>
            </div> -->
          </div>
          <div class="box-item">
            <!-- <div class="bottom-cell">
              <el-image loading="lazy" :src="require('../assets/bottom/location.svg')" />
              <div class="cell-text">公司地址: 浙江省温州市龙湾区北大科创园3楼325室</div>
            </div> -->
            <div class="bottom-cell">
              <el-image loading="lazy" :src="require('../assets/bottom/global.svg')" />
              <div class="cell-text">
                公司官网:
                <a @click="openNewWindow('http://website.netera.top')">
                  http://website.netera.top
                </a>
              </div>
            </div>
            <div class="bottom-cell">
              <el-image loading="lazy" :src="require('../assets/bottom/briefcase.svg')" />
              <div class="cell-text">
                业务范围:
                <a @click="openNewWindow('http://website.netera.top')">
                  电子政务 数字校园 品牌互动 集团门户 软件定制 商城系统
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- <div style="text-align: center">
          <el-image loading="lazy" :src="require('../assets/bottom/scancode.svg')" />
          <div class="bottom-tips">扫一扫关注我们</div>
        </div> -->
      </div>
    </div>
    <div class="center">
      <div class="bottom-company">
        <div>
          CopyRight © 2023-2024
          <a @click="openNewWindow('http://website.netera.top')">环网时代科技工作室</a>
          版权所有
        </div>
        <div>网站备案号：<a @click="openNewWindow('https://beian.miit.gov.cn/')">晋ICP备2024039876号</a></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Bottom'
}
</script>
<script setup>
const openNewWindow = url => {
  window.open(url)
}
</script>

<style lang="less" scoped>
.content-bottom {
  background: #1d2129;
  padding: 60px 0 30px 0;

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bottom-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1200px;
  }

  .bottom-box {
    display: inline-flex;
    align-items: flex-start;
    gap: 60px;

    .box-item {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .bottom-cell {
      display: flex;
      align-items: center;
      gap: 8px;

      .cell-text {
        color: #c9cdd4;
        font-family: 'MiSans VF Light';
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        a {
          text-decoration: none;
          color: #c9cdd4;
          cursor: pointer;
        }

        a:hover {
          filter: brightness(150%);
        }
      }
    }
  }

  .bottom-tips {
    color: #c9cdd4;
    font-family: 'MiSans VF Light';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .bottom-company {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1200px;
    color: #c9cdd4;
    font-family: 'Alibaba PuHuiTi 2.0';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 21px */
    opacity: 0.7;
    padding-top: 20px;

    // border-top: 1px solid #33373e;
    // margin-top: 50px;
    a {
      text-decoration: none;
      color: #c9cdd4;
      cursor: pointer;
    }

    a:hover {
      filter: brightness(150%);
    }
  }
}
</style>
