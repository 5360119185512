<template>
  <div>
    <div class="center">
      <div class="home-header">
        <div class="header-box">
          <img src="../assets/logo.png" />
          <div>环网时代</div>
        </div>
        <div class="subtitle"></div>
      </div>
    </div>
    <div class="home-content">
      <div ref="content" class="content-bg" @mouseleave="bgMouseLeave" @mousemove="bgMouseMove">
        <!-- <div class="content-bg"> -->
        <div class="bg-center">
          <div class="content-box">
            <div class="content-title">太原市环网时代科技工作室</div>
            <div class="content-subtitle">
              <p style="text-indent: 2em;">{{ text }}</p>
              <p style="text-indent: 2em;padding-top: 10px">{{ text2 }}</p>
              <p style="text-indent: 2em;padding-top: 10px">{{ text3 }}</p>
            </div>
          </div>
          <div ref="element" class="content-illustration">
            <!-- <illustrantion></illustrantion> -->
            <img :src="require('../../public/illustration.svg')" />
          </div>
        </div>
        <div class="content-figure">
          <img src="../assets/Line.svg" />
        </div>
      </div>
      <div class="content-us" v-if="false">我们服务的客户</div>
      <div class="content-tabsbg" v-if="false">
        <div class="content-tabs">
          <el-tabs class="tab-font" stretch v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="集团/上市公司" name="first">
              <div class="tab-bg">
                <div class="tab-box">
                  <div class="tab-padding" v-for="(item, index) in list[0]" :key="index">
                    <div class="tab-item">
                      <el-image loading="lazy" :src="require('../assets' + item.url)" :style="`height: ${item.height ? item.height : ''}`" />
                    </div>
                  </div>
                </div>
                <button class="content-more" @click="openNewWindow('https://www.cnvp.com.cn/wz/')">
                  查看更多
                </button>
              </div>
            </el-tab-pane>
            <el-tab-pane label="品牌形象" name="second">
              <div class="tab-bg">
                <div class="tab-box">
                  <div class="tab-padding" v-for="(item, index) in list[1]" :key="index">
                    <div class="tab-item">
                      <el-image loading="lazy" :src="require('../assets' + item.url)" :style="`height: ${item.height ? item.height : ''}`" />
                    </div>
                  </div>
                </div>
                <button class="content-more" @click="openNewWindow('https://www.cnvp.com.cn/wz/')">
                  查看更多
                </button>
              </div>
            </el-tab-pane>
            <el-tab-pane label="电子政务" name="third">
              <div class="tab-bg">
                <div class="tab-box">
                  <div class="tab-padding" v-for="(item, index) in list[2]" :key="index">
                    <div class="tab-item">
                      <el-image loading="lazy" :src="require('../assets' + item.url)" :style="`height: ${item.height ? item.height : ''}`" />
                    </div>
                  </div>
                </div>
                <button class="content-more" @click="openNewWindow('https://www.cnvp.com.cn/wz/')">
                  查看更多
                </button>
              </div>
            </el-tab-pane>
            <el-tab-pane label="数字校园" name="fourth">
              <div class="tab-bg">
                <div class="tab-box">
                  <div class="tab-padding" v-for="(item, index) in list[3]" :key="index">
                    <div class="tab-item">
                      <el-image loading="lazy" :src="require('../assets' + item.url)" :style="`height: ${item.height ? item.height : ''}`" />
                    </div>
                  </div>
                </div>
                <button class="content-more" @click="openNewWindow('https://www.cnvp.com.cn/wz/')">
                  查看更多
                </button>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <div class="content-us">
        <div>安全稳定的内容管理系统技术提供商</div>
        <div class="us-subtitle">
          基于内外网隔离技术，底层采用先进的Java/Node技术，页面采用vue3+TS+vite
        </div>
      </div>
      <card></card>
      <div style="background-color: #2c3b57">
        <div class="content-arrange">
          <div>安全可靠的软件部署方案</div>
          <div class="arrange-subtitle">
            <div>高并发、高稳定</div>
            <div style="padding-left: 16px">Redis缓存技术、MinIO存储技术</div>
            <div style="padding-left: 16px">多种数据源（Mysql等）</div>
          </div>
        </div>
        <div class="center">
          <div class="arrange-box">
            <div class="arrange-item" v-for="(e, i) in itemlist">
              <div class="arrange-border" :style="`border: 1px solid ${e.color}`"></div>
              <div class="item-header" :style="`background: ${e.color}`">
                <div class="item-title">{{ e.title }}</div>
                <div class="item-subtitle">
                  {{ e.subtitle }}
                </div>
              </div>
              <div class="item-list">
                <div class="list-item" v-for="(item, index) in arrange[i]">
                  <div class="list-img">
                    <span :style="`-webkit-mask: url(${require('../assets' +
                                                item.url)}) no-repeat; mask: url(${require('../assets' +
                                                item.url)}) no-repeat;`" class="lazy-img2"></span>
                    <!-- <el-image
                                        class="lazy-img"
                                        loading="lazy"
                                        :src="require('../assets' + item.url)"
                                    /> -->
                  </div>
                  <div class="list-title">{{ item.title }}</div>
                  <div class="list-text">{{ item.text }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="background-color: #f2f3f5; padding-bottom: 80px">
        <div class="content-us">
          <div>核心功能模块</div>
          <div class="us-subtitle">
            多端数据同步维护，多站点统一管理，信息审核后自动发布
          </div>
        </div>
        <div class="content-core">
          <div class="core-box">
            <div class="core-item" v-for="(item, index) in core">
              <div>
                <el-image loading="lazy" :src="require('../assets' + item.url)" />
              </div>
              <div class="core-title">{{ item.title }}</div>
              <div class="core-text">{{ item.text }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-data" ref="target">
        <div class="data-box">
          <div class="data-item" v-for="(item, index) in dataText" :key="index">
            <div class="data-number">
              <el-statistic value-style="
                                color: #fff;
                                text-align: center;
                                font-family: 'MiSans VF Light';
                                font-size: 60px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;" :value="outputValue[index]" group-separator="">
                <template #suffix>
                  <span class="data-icon">+</span>
                </template>
              </el-statistic>
              <div class="data-text">{{ item }}</div>
            </div>
          </div>
        </div>
        <div class="data-bg">
          <el-image loading="lazy" :src="require('../assets/core/diwen.svg')" />
        </div>
      </div>
      <bottom></bottom>
      <el-backtop :bottom="100">
        <div style="
                        height: 100%;
                        width: 100%;
                        padding: 10px;
                        background-color: var(--el-bg-color-overlay);
                        box-shadow: var(--el-box-shadow-lighter);
                        display: flex;
                        justify-content: center;
                        border-radius: 50%;
                        color: #1989fa;
                        transform: scale(1.2);
                    ">
          <el-image style="width: 18px" :src="require('../assets/Frame.svg')" />
        </div>
      </el-backtop>
    </div>
  </div>
</template>

<script>
export default {
  components: { Bottom, Card, Illustrantion },
  name: 'HelloWorld',
  data() {
    return {}
  }
}
</script>
<script setup>
import Bottom from './Bottom.vue'
import Card from './Card.vue'
import list from '../data/logo.js'
import arrange from '../data/arrange.js'
import core from '../data/core.js'
import { ref } from 'vue'
import { useTransition, useIntersectionObserver } from '@vueuse/core'
import Illustrantion from './Illustrantion.vue'

const text = ref(
  '一家技术驱动的科技企业，专注于提供顶尖的软件开发、网页制作、计算机软件技术开发及全方位的互联网信息服务。我们的技术团队精通多种编程语言和开发框架，擅长运用最新的网页设计技术和用户体验理念，为客户打造高效、稳定的软件系统和美观实用的网络平台。'
)
const text2 = ref(
  '以技术实力为核心竞争力，不断突破技术界限，研发出性能卓越、安全可靠的软硬件产品。我们的技术咨询、技术推广和技术服务团队以客户需求为导向，提供专业的技术支持和服务，帮助客户解决实际问题，提升技术水平和业务效率。'
)
const text3 = ref(
  '坚持以技术驱动创新，以质量赢得信誉。选择我们，您将获得专业、高效、可靠的科技服务，让我们携手并肩，共同开创科技新时代。'
)
const activeName = ref('first')

const handleClick = (tab, event) => {
  // console.log(tab, event);
}
const itemlist = [
  {
    color: '#3c7eff',
    title: '整体架构',
    subtitle: 'SpringBoot+Redis+RabbitMQ+Vue3+TS+Vite+Pinan'
  },
  { color: '#27c346', title: '整体架构', subtitle: '自由搭配、从容部署' },
  { color: '#ff9626', title: '组件', subtitle: '不可缺少的系统底层运行环境' }
]

const target = ref(null)
const element = ref(null)
const content = ref(null)
const targetIsVisible = ref(false)
const source = ref([0, 0, 0, 0, 0])
const outputValue = useTransition(source, {
  duration: 1000
})
const dataText = ref([
  // '19年数字化平台建设经验',
  // '100+政府单位的认可',
  // '200+上市公司的选择',
  '1000+品牌客户的选择',
  '5000+网站客户的选择'
])

const { stop } = useIntersectionObserver(
  target,
  ([{ isIntersecting }], observerElement) => {
    targetIsVisible.value = isIntersecting
    if (isIntersecting) {
      source.value = [1000, 5000]
    }
  }
)
const multiple = 70
const bgMouseLeave = () => {
  setTimeout(() => {
    element.value.style.transform = 'rotateX(0) rotateY(0)'
  }, 100)
}
const bgMouseMove = e => {
  window.requestAnimationFrame(function() {
    transformElement(e.clientX, e.clientY)
  })
}
const openNewWindow = url => {
  return
  window.open(url)
}
const transformElement = (x, y) => {
  let box = content.value.getBoundingClientRect()
  let calcX = -(y - box.y - box.height / 2) / multiple
  let calcY = (x - box.x - box.width / 2) / multiple
  element.value.style.transform =
    'rotateX(' + calcX + 'deg) ' + 'rotateY(' + calcY + 'deg)'
}
</script>

<style lang="less" scoped>
.center {
  display: flex;
  justify-content: center;
}
.home-header {
  display: flex;
  background-color: #fff;
  height: 80px;
  width: 1200px;
  justify-content: space-between;
  align-items: center;
  .header-box {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    height: 100%;
    cursor: pointer;
    & > img {
      height: 60%;
    }
    & > div {
      font-size: 16px;
      font-weight: bold;
    }
  }
  .title {
    color: #231815;
    font-family: 'DingTalk JinBuTi';
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -1.5px;
  }
  .subtitle {
    color: #1d2129;
    font-family: 'MiSans VF Light';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.home-content {
  .content-bg {
    position: relative;
    overflow: hidden;
    isolation: isolate;
    height: 640px;

    background: linear-gradient(136deg, #0046dc 19.36%, #1673ff 75.26%);
    display: flex;
    align-items: center;
    justify-content: center;
    .content-figure {
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .bg-center {
    display: flex;
    width: 1200px;
    align-items: center;
    justify-content: space-between;
  }
  .content-box {
    width: 564px;
  }
  .content-illustration {
    width: calc(100% - 594px);
    transition: all 0.1s ease;
    z-index: 1;
    > img {
      width: 100%;
    }
  }
  .content-more {
    margin: 60px auto 88px auto;
    width: 160px;
    padding: 10px 40px;
    display: inline-block;
    vertical-align: middle;
    font-size: 15px;
    color: #165dff;
    background: #f2f3f5;
    border: 1px solid #165dff;
    font-family: 'MiSans VF Light';
    font-weight: 400;
    position: relative;
    transform: perspective(1px) translateZ(0);
    transition: color 0.2s ease-in-out;
    cursor: pointer;
  }
  .content-more:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: scaleX(0);
    background: #165dff;
    transform-origin: 0 50%;
    transition-property: transform;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
  }
  .content-more:hover {
    color: #fff;
    &::before {
      transform: scaleX(1);
    }
  }
  .tab-bg {
    text-align: center;
    width: 1200px;
    padding-top: 40px;
    .tab-box {
      display: flex;
      // justify-content: center;
      gap: 30px 20px;
      flex: 1;
      flex-wrap: wrap;
    }
    .tab-font {
      color: #4e5969;
      font-family: 'MiSans VF';
      font-size: 20px;
    }
    .tab-item {
      display: flex;
      width: 224px;
      height: 90px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #fff;
      box-shadow: 0px 0px 20px 0px rgba(3, 66, 126, 0.05);
    }
  }
  .content-title {
    color: #fff;
    padding-bottom: 26px;
    font-family: 'MiSans VF Light';
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .content-title::selection {
    background-color: #fff;
    color: #0046dc;
  }
  .content-subtitle {
    color: #fff;

    font-family: 'MiSans VF';
    font-size: 16px;
    font-style: normal;
    font-weight: 345;
    line-height: 170%;
    p::selection {
      background-color: #fff;
      color: #0046dc;
    }
  }
  .content-us {
    display: flex;
    padding-top: 60px;
    padding-bottom: 40px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    color: #1d2129;
    font-family: 'MiSans VF Light';
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
  }
  .us-subtitle {
    color: #666;

    font-family: 'MiSans VF';
    font-size: 16px;
    font-style: normal;
    font-weight: 345;
    line-height: normal;
  }
  .content-tabsbg {
    display: flex;
    justify-content: center;
    background: #f2f3f5;
    position: relative;
  }
  .content-tabsbg::before {
    content: '';
    position: absolute;
    height: 50px;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
  }
  .content-tabs {
    position: relative;
    width: 1200px;
  }

  .content-arrange {
    display: flex;
    padding-top: 60px;
    padding-bottom: 40px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;
    color: #fff;
    font-family: 'MiSans VF';
    font-size: 32px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    .arrange-subtitle {
      display: flex;
      color: rgba(255, 255, 255, 0.7);
      font-family: 'MiSans VF';
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  }
  .arrange-box {
    display: flex;
    // padding: 0px 350px 90px 350px;
    width: 1200px;
    padding-bottom: 90px;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
  }
  .arrange-item {
    width: 380px;
    position: relative;
    .arrange-border {
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
    }
    .item-header {
      display: flex;
      height: 90px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4px;
      .item-title {
        color: #fff;
        font-family: 'MiSans VF Light';
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .item-subtitle {
        color: rgba(255, 255, 255, 0.8);
        font-family: 'MiSans VF';
        font-size: 14px;
        font-style: normal;
        font-weight: 330;
        line-height: normal;
      }
    }
  }
  .item-list {
    display: flex;
    flex-wrap: wrap;
    .list-item {
      display: flex;
      width: 190px;
      height: 150px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #fff;
      z-index: 1;
      .list-title {
        transition: all 0.2s;
        color: #1d2129;
        text-align: center;
        font-family: 'MiSans VF';
        font-size: 18px;
        font-style: normal;
        font-weight: 550;
        padding-top: 12px;
        padding-bottom: 4px;
        line-height: normal;
      }
      .list-text {
        width: 156px;
        white-space: pre-wrap;
        color: #4e5969;
        text-align: center;
        font-family: 'MiSans VF';
        font-size: 13px;
        font-style: normal;
        font-weight: 346;
        line-height: normal;
      }
      .list-img {
        transform: translateZ(0);
      }
    }
    .list-item:hover {
      .list-img {
        animation-name: hvr-icon-hang;
        animation-duration: 0.3s, 1.5s;
        animation-delay: 0s, 0.3s;
        animation-timing-function: ease-out;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        animation-direction: normal;
      }
    }
    .list-item:nth-child(2n + 1) {
      border-right: 1px solid #e5e6eb;
      border-bottom: 1px solid #e5e6eb;
    }
    .list-item:nth-child(2n) {
      border-bottom: 1px solid #e5e6eb;
    }
  }
  .lazy-img2 {
    display: inline-block;
    width: 32px;
    height: 32px;
    background-color: #86909c;
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
  }
  .arrange-item:nth-child(1) {
    .list-item:hover {
      .lazy-img {
        filter: invert(50%) sepia(35%) saturate(7005%) hue-rotate(206deg)
          brightness(100%) contrast(102%);
      }
      .lazy-img2 {
        background-color: #3c7eff;
      }
      .list-title {
        color: #3c7eff;
      }
    }
  }
  .arrange-item:nth-child(2) {
    .list-item:hover {
      .lazy-img {
        filter: invert(51%) sepia(96%) saturate(406%) hue-rotate(81deg)
          brightness(100%) contrast(90%);
      }
      .lazy-img2 {
        background-color: #27c346;
      }
      .list-title {
        color: #27c346;
      }
    }
  }
  .arrange-item:nth-child(3) {
    .list-item:hover {
      .lazy-img {
        filter: invert(52%) sepia(91%) saturate(387%) hue-rotate(348deg)
          brightness(102%) contrast(110%);
      }
      .lazy-img2 {
        background-color: #ff9626;
      }
      .list-title {
        color: #ff9626;
      }
    }
  }
  .content-core {
    display: flex;
    flex-direction: column;
    align-items: center;
    .core-box {
      display: flex;
      width: 1200px;
      align-items: flex-start;
      align-content: flex-start;
      gap: 30px 20px;
      flex-wrap: wrap;
      white-space: pre-wrap;
      .core-item {
        display: flex;
        width: 224px;
        height: 180px;
        padding: 10px;
        background: #fff;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        flex-shrink: 0;
        transition: transform 0.3s;
        .core-title {
          color: #1d2129;
          text-align: center;
          font-family: 'MiSans VF';
          font-size: 18px;
          font-style: normal;
          font-weight: 550;
          padding-top: 12px;
          padding-bottom: 4px;
          line-height: normal;
        }
        .core-text {
          color: #4e5969;
          font-family: 'MiSans VF';
          font-size: 14px;
          font-style: normal;
          font-weight: 346;
          line-height: normal;
        }
        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          height: 0px;
          width: 0px;
          transition: width 0.3s ease;
          background: #165dff;
        }
        &:hover {
          &::before {
            width: 100%;
            height: 2px;
          }
          .core-title {
            color: #165dff;
          }
        }
      }
    }
  }
  .content-data {
    display: flex;
    height: 220px;
    padding: 40px 0;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-shrink: 0;
    background: #0046dc;
    .data-bg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      pointer-events: none;
    }
    .data-box {
      display: flex;
      width: 1200px;
      gap: 60px;
      flex-shrink: 0;
    }
    .data-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 22px;
      flex: 1 0 0;
      align-self: stretch;
      .data-number {
        color: #fff;
        text-align: center;
        font-family: 'MiSans VF';
        font-size: 60px;
        font-style: normal;
        font-weight: 380;
        line-height: normal;
      }
      .data-icon {
        color: #fff;
        font-family: 'MiSans VF';
        font-size: 36px;
        font-style: normal;
        font-weight: 380;
        line-height: normal;
      }
      .data-text {
        padding-top: 8px;
        color: #fff;
        opacity: 0.7;
        font-family: 'MiSans VF';
        font-size: 16px;
        font-style: normal;
        font-weight: 345;
        line-height: normal;
      }
    }
  }
}

@keyframes hvr-icon-hang {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(6px);
  }
  100% {
    transform: translateY(0px);
  }
}

::v-deep .el-tabs__item {
  color: #4e5969;
  font-family: 'MiSans VF Light';
  font-size: 20px;
  font-weight: 400;
  height: 50px;
  &:hover,
  &.is-active {
    color: #165dff;
  }
}
::v-deep .el-tabs__active-bar {
  height: 2px;
  background-color: #165dff;
}
::v-deep .el-tabs__nav-wrap::after {
  height: 0;
}
</style>
