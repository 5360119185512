const list = [
    {
        url: '/core/monitor.svg',
        title: '站点管理',
        text: '站点统一管理，支持用户切\n换站点，支持一键置灰功能',
    },
    {
        url: '/core/direct.svg',
        title: '内容管理',
        text: 'Word黏贴图片自动上传，\n强大的一键排版功能',
    },
    {
        url: '/core/element-equal.svg',
        title: '栏目管理',
        text: '支持导入导出，栏目克隆，\n以及配置多级审核权限',
    },
    {
        url: '/core/task-square.svg',
        title: '信息审核',
        text: '可设置多级多人审核，\n支持审核员修改内容',
    },
    {
        url: '/core/clipboard-tick.svg',
        title: '静态发布',
        text: '以HTML静态页面发布，安全\n高效，提升网站访问速度',
    },
    {
        url: '/core/grid-6.svg',
        title: '模板引擎',
        text: '模板文件在线编辑，所见即所得\n点击鼠标即可完成风格制作',
    },
    {
        url: '/core/mobile-programming.svg',
        title: '信息报送',
        text: '跨站点数据报送，支持上下\n级、同级之间的信息报送',
    },
    {
        url: '/core/stickynote.svg',
        title: '日志引擎',
        text: '实时记录操作日志，多维度\n多时间段查询日志',
    },
    {
        url: '/core/folder-add.svg',
        title: '资源管理',
        text: '分布式存储技术，统一管理\n上传文件，支持无限扩展',
    },
    {
        url: '/core/code.svg',
        title: '扩展模块',
        text: '灵活定制扩展模块，插件式\n部署安装应用模块',
    },
];

export default list;
