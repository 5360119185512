<template>
    <Home ref="content" />
</template>

<script>
import Home from './components/Home.vue';

const debounce = (fn, delay) => {
    let timer = null;
    return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    };
};

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
    }
};
export default {
    name: 'App',
    components: {
        Home,
    },
};
</script>
<script setup>
import { onMounted, ref } from 'vue';
const content = ref(null);
const scaleva = ref();
// const bodyScale = () => {
//     const devicewidth = document.documentElement.clientWidth; // 获取当前分辨率下的可是区域宽度
//     const box = content.value.$el.parentElement;
//     // console.log(box)
//     const scale = devicewidth / 1920; // 分母——设计稿的尺寸
//     if(devicewidth <= 1200){
//         scaleva.value = 1200 / 1920;
//     } else {
//         scaleva.value = scale;
//     }
// };
onMounted(() => {
    // bodyScale();
    // window.onresize = () => {
    //     return (() => {
    //         bodyScale();
    //     })();
    // };
});
</script>

<style>
* {
    margin: 0;
    padding: 0;
}
div {
    box-sizing: border-box;
}
@media screen and (max-width: 1200px) {
    #app {
        width: 1200px;
    }
}
</style>
